import React, { useEffect, useState } from "react";
import { styles } from "../../../stylesheets/simulateurs.css";
import "../../../stylesheets/simulateursBis.css";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import { setDMTGResultsObject } from "../../../actions/dmtg_results.action";
import { useDispatch } from "react-redux";
import Successions from "../../../components/dmtg/successions";
import { ToolTipPerso } from "../../../components/selects/tooltip_perso";
import ButtonYesno from "../../../components/buttons/button.yesno";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";
import { Typography } from "@mui/material";
import TextfieldAbattement from "../../../components/textfields/textfield.abattement";

function Simulateur_Successions(props, isParent) {
    const dispatch = useDispatch();

    //MONTANT A
    const [montant_brut, setBrut] = useState(0);
    //MONTANT B
    const [total_rapportable, setTotalRapportable] = useState(0);
    //MONTANT C
    const [montant_passif, setPassif] = useState(0);
    //MONTANT D
    const [montant_net, setNet] = useState(0);
    //MONTANT E
    const [montant_succession, setSuccession] = useState(0);

    const [donateurs, setDonateurs] = useState(0);
    const [cas_particulier, setParticulier] = useState(0);
    const [donataires, setDonataires] = useState(1);
    const [allDonatairesList, setAllDonatairesList] = useState([{}]);
    const [results, setResults] = useState([{}]);

    // Function permettant d'avoir la main sur la modification du nombre de components demandé et d'y ajouter une limite
    // pour éviter la génération abusive.
    const handleChangeWithRestriction = async (setter, value) => {
        // Limite à adapter --v
        if (value < 10) setter(value);
        else setter(9);
    };

    useEffect(() => {
        const newSuccession = (montant_brut || 0) - (montant_passif || 0) + (total_rapportable || 0);
        setSuccession(newSuccession);
        handleChange(setSuccession, newSuccession);
    }, [montant_brut, montant_passif, total_rapportable]);

    const handleChange = async (setter, value) => {
        if (setter === setBrut) {
            setBrut(parseInt(value));
            setNet((parseInt(value) || 0) - (montant_passif || 0));
            setSuccession(parseInt(value) - (montant_passif || 0) + (total_rapportable || 0));
        } else if (setter === setTotalRapportable) {
            setTotalRapportable(parseInt(value));
            setSuccession((montant_brut || 0) - (montant_passif || 0) + (parseInt(value) || 0));
        } else if (setter === setPassif) {
            setPassif(parseInt(value));
            setNet((montant_brut || 0) - (parseInt(value) || 0));
            setSuccession((montant_brut || 0) - (parseInt(value) || 0) + (total_rapportable || 0));
        } else setter(value);
    };

    const handleChangeRemarque = async (value) => {
        localStorage.setItem("remarque", value);
    };

    const texteAvecSautsDeLigne =
        " - Victime de guerre ou d'acte de terrorisme ;\n - Militaire décédé en opération extérieure ou au cours d'une opération de sécurité intérieure ou décédé suite à des blessures reçues durant de telles opérations ;\n - Sapeur-pompier, policier, gendarme ou agent des douanes, cité à l'ordre de la Nation, décédé en service ou suite à des blessures reçues en service.";

    const lignes = texteAvecSautsDeLigne.split("\n");

    const texteActifBrut =
        "Pour le calcul de l’émolument, l’assiette a pour base l’actif brut total, en ce compris s’il y a communauté, participation ou société d’acquêts, les biens qui en dépendent (C. com., art. A. 444-63, Tableau 5, n° 8).";

    const lignesTexteActifBrut = texteActifBrut.split("\n");

    const texteDonationRapport =
        "S’il y a des donations antérieures, seules les donations rapportables au sens civil sont à inclure dans l’assiette de l’émolument proportionnel pour leur valeur rapportable au jour du décès, quelle que soit leur ancienneté.";

    const lignesTexteDonationRapport = texteDonationRapport.split("\n");

    const textePassif = "Indiquer le montant du passif au sens fiscal";

    const lignesTextePassif = textePassif.split("\n");

    // Fonction call depuis le component enfant qui set la key du state "results" modifiée.
    function FunctionSetResults(key, value) {
        results[key] = value;
        setInRedux(results);
    }

    function FunctionSetAllDonatairesList(key, value) {
        allDonatairesList[key] = value;
        //console.log("iciii")
        //console.log(value)
    }

    function setInRedux(results) {
        let object = { results: results };
        Object.assign(object["results"], results);
        //console.log(object)
        dispatch(setDMTGResultsObject(object));
    }

    //props?.isParent === true
    return (
        <div style={styles.container}>
            <div className={props.callSinceSimu ? "cardDMTG" : "cardSimulateur"}>
                {props.callSinceSimu ? (
                    <></>
                ) : (
                    <>
                        <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id} />

                        <TextfieldRemarques handleChange={handleChangeRemarque} />

                        {/*MONTANT A*/}
                        <div className="cardRow">
                            <div style={styles.cardElemVerticalyCenter}>
                                Actif brut de succession :
                                <ToolTipPerso
                                    title={
                                        <Typography style={{ fontSize: 14 }}>
                                            {lignesTexteActifBrut.map((ligne, index) => (
                                                <React.Fragment key={index}>
                                                    {index > 0 && <br />}
                                                    {ligne}
                                                </React.Fragment>
                                            ))}
                                        </Typography>
                                    }
                                />
                            </div>
                            <TextfieldNumber
                                handleChange={handleChange}
                                type={"number"}
                                placeholder={"Indiquer le montant"}
                                setter={setBrut}
                            />
                        </div>
                        {/*MONTANT B*/}
                        <div className="cardRow">
                            <div style={styles.cardElemVerticalyCenter}>
                                Montant total des donations rapportables, qu'elles aient plus ou moins de 15 ans :
                                <ToolTipPerso
                                    title={
                                        <Typography style={{ fontSize: 14 }}>
                                            {lignesTexteDonationRapport.map((ligne, index) => (
                                                <React.Fragment key={index}>
                                                    {index > 0 && <br />}
                                                    {ligne}
                                                </React.Fragment>
                                            ))}
                                        </Typography>
                                    }
                                />
                            </div>
                            <TextfieldNumber
                                handleChange={handleChange}
                                type={"number"}
                                placeholder={"Indiquer le montant"}
                                setter={setTotalRapportable}
                            />
                        </div>

                        {/*MONTANT C*/}
                        <div className="cardRow">
                            <div style={styles.cardElemVerticalyCenter}>
                                Passif de succession :
                                <ToolTipPerso
                                    title={
                                        <Typography style={{ fontSize: 14 }}>
                                            {lignesTextePassif.map((ligne, index) => (
                                                <React.Fragment key={index}>
                                                    {index > 0 && <br />}
                                                    {ligne}
                                                </React.Fragment>
                                            ))}
                                        </Typography>
                                    }
                                />
                            </div>
                            <TextfieldNumber
                                handleChange={handleChange}
                                type={"number"}
                                placeholder={"Indiquer le montant"}
                                setter={setPassif}
                            />
                        </div>

                        <div className="cardRow">
                            <div style={styles.cardElemVerticalyCenter}>Actif net de succession</div>
                            <TextfieldAbattement
                                type={"number"}
                                placeholder={montant_net.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " €"}
                            />
                        </div>
                    </>
                )}
                <div className="cardRow">
                    <div style={styles.cardElemVerticalyCenter}>
                        Le défunt était-il dans un cas particulier ?
                        <ToolTipPerso
                            title={
                                <Typography style={{ fontSize: 14 }}>
                                    {lignes.map((ligne, index) => (
                                        <React.Fragment key={index}>
                                            {index > 0 && <br />}
                                            {ligne}
                                        </React.Fragment>
                                    ))}
                                </Typography>
                            }
                        />
                    </div>
                    <ButtonYesno handleChange={handleChange} setter={setParticulier} defaultValue={0} />
                </div>
                {parseInt(cas_particulier) === 1 ? (
                    <div>
                        <div style={styles.messageInABottle}>Le ou les bénéficaires sont exonérés de droits</div>
                    </div>
                ) : (
                    <>
                        <div className="cardRow">
                            <div style={styles.cardElemVerticalyCenter}>Combien d'héritiers sont concernés ?</div>
                            <TextfieldNumber
                                handleChange={handleChangeWithRestriction}
                                type={"number"}
                                defaultValue={1}
                                placeholder={"Nombre d'héritiers"}
                                setter={setDonataires}
                            />
                        </div>

                        <div className="cardDonateurs">
                            <Successions
                                handleChange={handleChange}
                                resultKey={0}
                                functionSetResults={FunctionSetResults}
                                FunctionSetAllDonatairesList={FunctionSetAllDonatairesList}
                                donataires={donataires}
                                simulateurid={props.simulateur.id}
                                // Check if it's called by another simulateur
                                total_rapportable={props.callSinceSimu ? props.total_rapportable : total_rapportable}
                                total_succession={props.callSinceSimu ? props.total_succession : montant_succession}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default Simulateur_Successions;
