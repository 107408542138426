import {createBrowserHistory} from 'history';
import {Router, useLocation} from "react-router-dom";
import Routes from "./Routes";
import React, {useState} from "react";
import Colors from "./useclass/colors";
import buttonScrollCss from "./stylesheets/button.scroll.css"
import ScrollButton from "./components/buttons/button.scroll";

const history = createBrowserHistory();

function App() {
    const [open, setOpen] = useState(false);
    const handleChange = async (setter, value) => {
        setter(value);
    };
    const styles = {
        messageLogo: {
            position: "fixed",
            right: 15,
            bottom: 90,
            cursor: "pointer"
        }
    };


    return (
        <>

            <Router history={history}>
                <Routes/>
            </Router>

            <ScrollButton />

        </>
    );
}

export default App;
