import * as React from "react";
import Colors from "../../useclass/colors";
import { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import { API_BASE_URL, PARTNER_JWT, useForceUpdate } from "../../config";
import { useSnackbar } from "notistack";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useDispatch, useSelector } from "react-redux";
import "../../stylesheets/simulateur.main.css";
import LinkPDF from "../../utils/simulateurs/link.PDF";
import roundDecimal from "../../utils/roundDecimal";
import "./modal.css";
import close from "../../assets/images/close.png";
import "../../assets/fonts/Roboto/Roboto-Regular.ttf";
import useIsMountedRef from "../../utils/mountedRef";
import Simulateur_A2_16 from "./simulateur.A2.16/simulateur.A2.16";
import Simulateur_A2_17 from "./simulateur.A2.17/simulateur.A2.17";
import Simulateur_A2_18 from "./simulateur.A2.18/simulateur.A2.18";
import Simulateur_A1_11 from "./simulateur.A1.11/simulateur.A1.11";
import Simulateur_A1_8 from "./simulateur.A1.8/simulateur.A1.8";
import Simulateur_A1_12PRO from "./simulateur.A1.12PRO/simulateur.A1.12PRO";
import Simulateur_A2_15 from "./simulateur.A2.15/simulateur.A2.15";
import Simulateur_A1_1 from "./simulateur.A1.1/simulateur.A1.1";
import Simulateur_A1_2 from "./simulateur.A1.2/simulateur.A1.2";
import Simulateur_A1_3 from "./simulateur.A1.3/simulateur.A1.3";
import Simulateur_A1_3bis from "./simulateur.A1.3bis/simulateur.A1.3bis";
import Simulateur_A2_1 from "./simulateur.A2.1/simulateur.A2.1";
import Simulateur_A1_5 from "./simulateur.A1.5/simulateur.A1.5";
import Simulateur_A1_7 from "./simulateur.A1.7/simulateur.A1.7";
import Simulateur_A1_9 from "./simulateur.A1.9/simulateur.A1.9";
import Simulateur_A1_10 from "./simulateur.A1.10/simulateur.A1.10";
import Simulateur_A1_12 from "./simulateur.A1.12/simulateur.A1.12";
import Simulateur_A1_12VEFA from "./simulateur.A1.12VEFA/simulateur.A1.12VEFA";
import Simulateur_A1_14 from "./simulateur.A1.14/simulateur.A1.14";
import Simulateur_A1_15 from "./simulateur.A1.15/simulateur.A1.15";
import Simulateur_A1_16bis from "./simulateur.A1.16bis/simulateur.A1.16bis";
import Simulateur_A1_16 from "./simulateur.A1.16/simulateur.A1.16";
import Simulateur_A1_17 from "./simulateur.A1.17/simulateur.A1.17";
import Simulateur_A1_20 from "./simulateur.A1.20/simulateur.A1.20";
import Simulateur_A1_20bis from "./simulateur.A1.20bis/simulateur.A1.20bis";
import Simulateur_A1_21 from "./simulateur.A1.21/simulateur.A1.21";
import Simulateur_A1_24 from "./simulateur.A1.24/simulateur.A1.24";
import Simulateur_A1_24bis from "./simulateur.A1.24bis/simulateur.A1.24bis";
import Simulateur_A1_25 from "./simulateur.A1.25/simulateur.A1.25";
import Simulateur_A1_25bis from "./simulateur.A1.25bis/simulateur.A1.25bis";
import Simulateur_A1_26 from "./simulateur.A1.26/simulateur.A1.26";
import Simulateur_A1_27 from "./simulateur.A1.27/simulateur.A1.27";
import Simulateur_A1_28 from "./simulateur.A1.28/simulateur.A1.28";
import Simulateur_A1_29 from "./simulateur.A1.29/simulateur.A1.29";
import Simulateur_A1_30 from "./simulateur.A1.30/simulateur.A1.30";
import Simulateur_A2_14 from "./simulateur.A2.14/simulateur.A2.14";
import Simulateur_A2_19 from "./simulateur.A2.19/simulateur.A2.19";
import Simulateur_A2_20 from "./simulateur.A2.20/simulateur.A2.20";
import Simulateur_ESD from "./simulateur.ESD/simulateur.ESD";
import Simulateur_ESDM from "./simulateur.ESDM/simulateur.ESDM";
import Simulateur_ESI from "./simulateur.ESI/simulateur.ESI";
import Simulateur_ESIM from "./simulateur.ESIM/simulateur.ESIM";
import Simulateur_ASF1 from "./simulateur.ASF1/simulateur.ASF1";
import Simulateur_ASF2 from "./simulateur.ASF2/simulateur.ASF2";
import Simulateur_ASF3 from "./simulateur.ASF3/simulateur.ASF3";
import Simulateur_ASF5 from "./simulateur.ASF5/simulateur.ASF5";
import Simulateur_ASF6 from "./simulateur.ASF6/simulateur.ASF6";
import Simulateur_ASF7 from "./simulateur.ASF7/simulateur.ASF7";
import Simulateur_ASF8 from "./simulateur.ASF8/simulateur.ASF8";
import Simulateur_ASF9 from "./simulateur.ASF9/simulateur.ASF9";
import Simulateur_ASF10 from "./simulateur.ASF10/simulateur.ASF10";
import Simulateur_ASF12 from "./simulateur.ASF12/simulateur.ASF12";
import Simulateur_ASF12bis from "./simulateur.ASF12bis/simulateur.ASF12bis";
import Simulateur_ASF13 from "./simulateur.ASF13/simulateur.ASF13";
import Simulateur_ASF13bis from "./simulateur.ASF13bis/simulateur.ASF13bis";
import Simulateur_ASF14 from "./simulateur.ASF14/simulateur.ASF14";
import Simulateur_ASimmo1 from "./simulateur.ASimmo1/simulateur.ASimmo1";
import Simulateur_ASimmo3 from "./simulateur.ASimmo3/simulateur.ASimmo3";
import Simulateur_ASimmo4 from "./simulateur.ASimmo4/simulateur.ASimmo4";
import Simulateur_ASimmo5 from "./simulateur.ASimmo5/simulateur.ASimmo5";
import Simulateur_ASimmo6 from "./simulateur.ASimmo6/simulateur.ASimmo6";
import Simulateur_A1_4 from "./simulateur.A1.4/simulateur.A1.4";
import Simulateur_A2_8 from "./simulateur.A2.8/simulateur.A2.8";
import Simulateur_A2_2 from "./simulateur.A2.2/simulateur.A2.2";
import Simulateur_A2_3 from "./simulateur.A2.3/simulateur.A2.3";
import Simulateur_A2_4 from "./simulateur.A2.4/simulateur.A2.4";
import Simulateur_A2_5 from "./simulateur.A2.5/simulateur.A2.5";
import Simulateur_A2_6 from "./simulateur.A2.6/simulateur.A2.6";
import Simulateur_A2_7 from "./simulateur.A2.7/simulateur.A2.7";
import Simulateur_A1_5bis from "./simulateur.A1.5bis/simulateur.A1.5bis";
import Simulateur_EDD from "./simulateur.EDD/simulateur.EDD";
import Simulateur_MC_EDD from "./simulateur.MC.EDD/simulateur.MC.EDD";
import Simulateur_34 from "./simulateur.34/simulateur.34";
import Simulateur_35 from "./simulateur.34/simulateur.34";
import Simulateur_36 from "./simulateur.36/simulateur.36";
import Simulateur_37 from "./simulateur.37/simulateur.37";
import Simulateur_38 from "./simulateur.38/simulateur.38";
import Simulateur_39 from "./simulateur.39/simulateur.39";
import Simulateur_40 from "./simulateur.40/simulateur.40";
import Simulateur_41 from "./simulateur.41/simulateur.41";
import Simulateur_48 from "./simulateur.48/simulateur.48";
import Simulateur_49 from "./simulateur.49/simulateur.49";
import Simulateur_50 from "./simulateur.50/simulateur.50";
import Simulateur_51 from "./simulateur.51/simulateur.51";
import Simulateur_52 from "./simulateur.52/simulateur.52";
import Simulateur_53 from "./simulateur.53/simulateur.53";
import Simulateur_54 from "./simulateur.54/simulateur.54";
import Simulateur_57 from "./simulateur.57/simulateur.57";
import Simulateur_76 from "./simulateur.76/simulateur.76";
import Simulateur_77 from "./simulateur.77/simulateur.77";
import Simulateur_A1_21bis from "./simulateur.A1.21bis/simulateur.A1.21bis";

ChartJS.register(ArcElement, Tooltip, Legend);

function SimulateurMain(props) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const isMountedRef = useIsMountedRef();
  const [parentSimulateur, setParentSimulateur] = useState({});
  const [departements, setDepartements] = useState("");
  const [createPDF, setCreatePDF] = useState(false);
  var data = useSelector((state) => state.simulateur);
  const listOfSimuWithDMTG = [48, 49, 50, 51, 52, 57];
  const [calculateDMTGState, setCalculateDMTGState] = useState(false);
  const [readyPDF, setReadyPDF] = useState(false);
  const multiComponentInputSimu = [34, 35, 81, 82];

  //var selectorDataStored = useSelector((state) => state.simulateur);
  //const [currentMontant, setCurrentMontant] = useState(0)
  //const [arrayContent, setArrayContent] = useState([])
  const [show, setShow] = useState(false);
  const [showEmol, setShowEmol] = useState(false);

  const [simulateurResponse, setSimulateurResponse] = useState([]);
  const [allEmoluments, setAllEmoluments] = useState([]);
  const [baseSize, setBaseSize] = useState(0);
  const [remarque, setRemarque] = useState("");
  let currUser = JSON.parse(localStorage.getItem("efluser"));
  let requestSimu = JSON.parse(localStorage.getItem("requestSimu"));

  const [simulateurObject, setSimulateurObject] = useState({
    data: {
      simulateurid: parentSimulateur.id,
      bureauid: parseInt(currUser.id),
      departementid: null,
      inputs: null,
      DMTG: null,
      dependances: [],
    },
    results: {
      output: [],
      custom: [],
    },
  });

  useEffect(() => {
    localStorage.setItem("remarque", remarque);
  }, [remarque]);

  useEffect(() => {
    simulateurObject.data.departementid = data.departementid;
    localStorage.removeItem("tempo");
  }, [data]);

  useEffect(() => {
    simulateurObject["data"]["dependances"] = data.dependances;
  }, [data]);

  useEffect(() => {
    const items = { ...localStorage };
    Object.keys(items).map((key, value) => {
      if (isNumeric(key.toString())) {
        localStorage.removeItem(key);
      }
    });
    localStorage.removeItem("outputAffinage");
    localStorage.removeItem("usageId");
    localStorage.removeItem("nameSimu");
    localStorage.removeItem("persoEmol");
  }, [isMountedRef]);

  const showHideClassName = show ? "modal display-block" : "modal display-none";

  const chartRef = useRef(null);

  var forceUpdate = useForceUpdate();

  const getSimulateur = useCallback(() => {
    axios.defaults.withCredentials = true;
    var config = {
      method: "get",
      url: `${API_BASE_URL}/simulateurs/${props.match.params.simulateur}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `${PARTNER_JWT}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response.status === 200 && response.data != null) {
          if (isMountedRef.current) setParentSimulateur(response.data);
        } else
          enqueueSnackbar("Une erreur est survenue", {
            variant: "error",
          });
      })
      .catch((err) => {
        enqueueSnackbar("Une erreur est survenue", {
          variant: "error",
        });
      });
  }, [isMountedRef]);

  // const setParentSimu = useCallback(() => {
  //     var config = {
  //         method: 'get',
  //         url: `${API_BASE_URL}/simulateurs/${props.match.params.simulateur}`,
  //         headers: {
  //             'Content-Type': 'application/json',
  //             'Authorization': `${PARTNER_JWT}`,
  //         },
  //     };
  //     axios(config)
  //         .then((response) => {
  //             if (response.status === 200 && response.data != null) {
  //                 if (isMountedRef.current) {
  //                     setParentSimulateur(response.data)
  //                 }
  //             } else {
  //                 enqueueSnackbar('Une erreur est survenue', {
  //                     variant: 'error'
  //                 });
  //                 //    window.location = "/app"
  //             }
  //         })
  //         .catch((err) => {
  //             enqueueSnackbar('Une erreur est survenue', {
  //                 variant: 'error'
  //             });
  //             //  window.location = "/app"
  //         });
  // }, [isMountedRef])

  const styles = {
    container: {
      width: "100%",
      color: Colors.textBlack,
      minHeight: "75vh",
      backgroundColor: Colors.ultraLightGrey,
      fontFamily: "Regular",
    },
    ctnButton: {
      width: "90%",
      marginLeft: "auto",
      marginRight: "auto",
      paddingBottom: 30,
      paddingTop: 30,
      marginBottom: 200,
    },
    ctnButtonValide: {
      marginLeft: "auto",
      marginRight: "auto",
      paddingBottom: 30,
      paddingTop: 30,
      textAlign: "center",
      maxWidth: 250,
    },
    buttonCalculate: {
      display: "block",
      margin: "auto",
      width: "30%",
      minWidth: "230px",
      height: 35,
      borderRadius: 5,
      border: "none",
      backgroundColor: "#49708C",
      color: Colors.white,
      fontWeight: "bold",
      cursor: "pointer",
      boxShadow: "2px 2px 1px rgba(0,0,0, 0.2)",
      hover: "#6399BF",
    },
    ctnTable: {
      width: "97%",
      marginTop: 10,
      marginBottom: 30,
      backgroundColor: Colors.white,
      marginLeft: "auto",
      marginRight: "auto",
      borderRadius: 4,
      minWidth: 400,
    },
    ctnTableData: {
      width: "100%",
      textAlign: "left",
      display: "flex",
      flexDirection: "row",
      height: "100%",
      overflowY: "scroll",
    },
    ctnTableDataElemLeft: {
      width: "100%",
      border: "grey solid 1px",
      borderCollapse: "collapse",
      borderRadiusTopLeft: 3,
      borderRadiusBottomLeft: 3,
      borderRadiusBottomRight: 3,
      height: 35,
      marginBottom: 5,
    },
    ctnTableDataElemRight: {
      width: "100%",
      borderRight: "grey solid 1px",
      borderTop: "grey solid 1px",
      borderBottom: "grey solid 1px",
      borderCollapse: "collapse",
      borderRadius: 3,
      borderRadiusBottomLeft: 0,
      borderRadiusTopLeft: 0,
      height: 35,
    },
    ctnTableDataTxt: {
      lineHeight: 0.5,
      marginLeft: 10,
      fontSize: 16,
    },
    table: {
      width: "40%",
      borderCollapse: "collapse",
      marginRight: 5,
      border: "1px solid rgb(238, 236, 236)",
      margin: "auto",
    },
    tr: {
      borderCollapse: "collapse",
      borderRadius: 5,
      fontWeight: "bold",
      textAlign: "center",
    },
    td: {
      borderCollapse: "collapse",
      borderRadius: 5,
      paddingLeft: 10,
      height: 40,
      fontWeight: "bold",
      textAlign: "left",
      borderRight: "1px solid rgb(212, 212, 212)",
    },
    td2: {
      borderCollapse: "collapse",
      borderRadius: 5,
      paddingRight: 10,
      height: 40,
      fontWeight: "bold",
      textAlign: "right",
      minWidth: 85,
    },
    th: {
      borderCollapse: "collapse",
      borderRadius: 5,
      paddingLeft: 5,
      height: 45,
      textAlign: "center",
      fontStyle: "italic",
    },

    table2: {
      width: "100%",
      borderCollapse: "collapse",
      marginRight: 5,
      border: "1px solid rgb(238, 236, 236)",
    },
    thead: {
      backgroundColor: "rgb(216, 211, 201)",
    },
    td1form: {
      backgroundColor: "white",
      height: 45,
    },
    td1formTitle: {
      backgroundColor: "white",
      height: 45,
      textAlign: "left",
      paddingLeft: 7,
    },
    td1formArt: {
      backgroundColor: "white",
      height: 45,
      fontSize: 14,
      fontWeight: "lighter",
    },
    td2form: {
      backgroundColor: "rgb(245, 242, 240)",
      height: 45,
    },
    td2formTitle: {
      backgroundColor: "rgb(245, 242, 240)",
      height: 45,
      textAlign: "left",
      paddingLeft: 7,
    },
    td2formArt: {
      backgroundColor: "rgb(245, 242, 240)",
      height: 45,
      fontSize: 14,
      fontWeight: "lighter",
    },
  };

  useEffect(() => {
    getSimulateur();
  }, [getSimulateur]);

  useEffect(() => {
    localStorage.setItem("nameSimu", parentSimulateur.name);
  }, [parentSimulateur]);

  let handleData;

  function dependanceTreatment(dependance, i) {
    return new Promise((resolve) => {
      try {
        var config = {
          method: "post",
          url: `${API_BASE_URL}/calcul`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `${PARTNER_JWT}`,
          },
          data: dependance,
        };
        axios(config)
          .then((response) => {
            if (response.status === 200 && response.data != null) {
              handleData.dependances[i] = {
                output: response.data,
                inputs: dependance.inputs,
                simulateurid: dependance.simulateurid,
                departementid: dependance.departementid,
              };
              simulateurObject["data"]["dependances"][i] =
                handleData.dependances[i];
              resolve();
            } else {
              enqueueSnackbar(
                "Le simulateur " + parentSimulateur.name + " est mal renseigné",
                {
                  variant: "error",
                }
              );
              Promise.reject("fail dependence");
            }
          })
          .catch((err) => {
            enqueueSnackbar(
              "Tous les champs n'ont pas été correctement renseignés.",
              {
                variant: "error",
              }
            );
            Promise.reject("fail dependence");
          });
      } catch (e) {
        return null;
      }
    });
  }

  async function check(data) {
    localStorage.setItem("content", JSON.stringify(data));
    handleData = JSON.parse(localStorage.getItem("content"));

    if (data?.dependances?.length > 0 || data?.dependances !== undefined) {
      if (multiComponentInputSimu.includes(data.simulateurid)) {
        for (let i = 0; i < handleData.dependances.length; i++) {
          simulateurObject["data"]["dependances"][i] =
            handleData.dependances[i];
        }
      } else {
        for (let i = 0; i < handleData.dependances.length; i++) {
          let handle = handleData.dependances[i];
          simulateurObject["data"]["dependances"][i] =
            handleData.dependances[i];
          await dependanceTreatment(handle, i);
        }
      }
      return simulateurObject["data"];
    } else return data;
  }

  const getDepart = useCallback(() => {
    var config = {
      method: "get",
      url: `${API_BASE_URL}/departements`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `${PARTNER_JWT}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response.status === 200 && response.data != null) {
          if (isMountedRef.current) {
            setDepartements(response.data);
          }
        } else {
          enqueueSnackbar("Une erreur est survenue", {
            variant: "error",
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar("Une erreur est survenue", {
          variant: "error",
        });
      });
  }, [isMountedRef]);

  useEffect(() => {
    getDepart();
  }, [getDepart]);

  function calculate() {
    simulateurObject["data"]["inputs"] = data.inputs;
    simulateurObject["data"]["departementid"] = data.departementid;
    simulateurObject["data"]["simulateurid"] = data.simulateurid;
    simulateurObject["data"]["DMTG"] = data.DMTG;

    if (
      simulateurObject["data"]["departementid"] === undefined ||
      isNaN(simulateurObject["data"]["departementid"]) ||
      simulateurObject["data"]["departementid"] == 0
    ) {
      enqueueSnackbar(
        "Vous devez renseigner tous les champs afin de procéder au calcul",
        {
          variant: "error",
        }
      );
      return;
    }
    check(simulateurObject["data"]).then((r) => {
      localStorage.setItem("requestSimu", JSON.stringify(r));

      //simulateurObject["data"]["inputs"] = r

      var config = {
        method: "post",
        url: `${API_BASE_URL}/calcul`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${PARTNER_JWT}`,
        },
        data: r,
      };
      axios(config)
        .then((response) => {
          if (response.status === 200 && response.data != null) {
            localStorage.setItem("usageId", response.data.usage_id);
            response.data.saveEmol = response.data.Emol_total;

            setSimulateurResponse(response.data);

            //   simulateurObject["results"]["output"] = (response.data)

            var allData = [];
            for (let i = 1; i <= 10; i++) {
              const key = `emoluments_actes_${i}`;
              if (response.data[key] !== undefined) {
                allData.push({
                  key: key,
                  name: `Emol Acte servitude ${i}`,
                  value:
                    roundDecimal(parseFloat(response.data[key]), 2).toString() +
                    " €",
                });
              }
            }
            for (let i = 1; i <= 10; i++) {
              const key = `base_emol_${i}`;
              if (response.data[key] !== undefined) {
                allData.push({
                  key: key,
                  name: `Base Emol servitude ${i}`,
                  value:
                    roundDecimal(parseFloat(response.data[key]), 2).toString() +
                    " €",
                });
              }
            }
            for (let i = 1; i <= 10; i++) {
              const key = `csi_publication_${i}`;
              if (response.data[key] !== undefined) {
                allData.push({
                  key: key,
                  name: `CSI publication servitude ${i}`,
                  value:
                    roundDecimal(parseFloat(response.data[key]), 2).toString() +
                    " €",
                });
              }
            }
            for (let i = 1; i <= 10; i++) {
              const key = `base_csi_publication_${i}`;
              if (response.data[key] !== undefined) {
                allData.push({
                  key: key,
                  name: `Base CSI publication SPF ${i}`,
                  value:
                    roundDecimal(parseFloat(response.data[key]), 2).toString() +
                    " €",
                });
              }
            }
            for (let i = 1; i <= 10; i++) {
              const key = `droit_mutation_${i}`;
              if (response.data[key] !== undefined) {
                allData.push({
                  key: key,
                  name: `DMTO servitude ${i}`,
                  value:
                    roundDecimal(parseFloat(response.data[key]), 2).toString() +
                    " €",
                });
              }
            }
            for (let i = 1; i <= 10; i++) {
              const key = `base_tresor_${i}`;
              if (response.data[key] !== undefined) {
                allData.push({
                  key: key,
                  name: `Base trésor servitude ${i}`,
                  value:
                    roundDecimal(parseFloat(response.data[key]), 2).toString() +
                    " €",
                });
              }
            }
            if (response.data.Provision_total !== undefined) {
              allData.push({
                key: "Provision_total",
                name: "Provision",
                value:
                  roundDecimal(
                    parseFloat(response.data.Provision_total),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.prix_cession !== undefined) {
              allData.push({
                key: "prix_cession",
                name: "Pris de cession",
                value:
                  roundDecimal(
                    parseFloat(response.data.prix_cession),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.Provision_Pourcentage_total !== undefined) {
              allData.push({
                key: "Provision_Pourcentage_total",
                name: "Pourcentage de la provision",
                value:
                  roundDecimal(
                    parseFloat(response.data.Provision_Pourcentage_total),
                    2
                  ).toString() + " %",
              });
            }
            if (response.data.Emol_total !== undefined) {
              allData.push({
                key: "Emol_total",
                name: "Emoluments HT",
                value:
                  roundDecimal(
                    parseFloat(response.data.Emol_total),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.emoluments_formalites_total !== undefined) {
              allData.push({
                key: "Emol_Form_Total",
                name: "Dont total des émoluments de formalités",
                value:
                  roundDecimal(
                    parseFloat(response.data.emoluments_formalites_total),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.emoluments_formalites !== undefined) {
              allData.push({
                key: "Emol_Form",
                name: "Dont émoluments de formalités",
                value:
                  roundDecimal(
                    parseFloat(response.data.emoluments_formalites),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.emoluments_majoration_total !== undefined) {
              if (response.data.emoluments_majoration_total !== 0) {
                allData.push({
                  key: "Major",
                  name: "Dont majoration tarif DOM",
                  value:
                    roundDecimal(
                      parseFloat(response.data.emoluments_majoration_total),
                      2
                    ).toString() + " €",
                });
              }
            }
            if (response.data.TVA_total !== undefined) {
              allData.push({
                key: "TVA_total",
                name: "TVA sur émoluments",
                value:
                  roundDecimal(
                    parseFloat(response.data.TVA_total),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.Tresor_total !== undefined) {
              allData.push({
                key: "Tresor_total",
                name: "Trésor",
                value:
                  roundDecimal(
                    parseFloat(response.data.Tresor_total),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.Tresor !== undefined) {
              allData.push({
                key: "Tresor",
                name: "Trésor vente",
                value:
                  roundDecimal(parseFloat(response.data.Tresor), 2).toString() +
                  " €",
              });
            }
            if (response.data.DMTG !== undefined) {
              allData.push({
                key: "DMTG",
                name: "Dont DMTG",
                value:
                  roundDecimal(parseFloat(response.data.DMTG), 2).toString() +
                  " €",
              });
            }
            if (response.data.Debours_total !== undefined) {
              allData.push({
                key: "Debours_total",
                name: "Débours",
                value:
                  roundDecimal(
                    parseFloat(response.data.Debours_total),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.Debours !== undefined) {
              allData.push({
                key: "Debours",
                name: "Débours",
                value:
                  roundDecimal(
                    parseFloat(response.data.Debours),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.Simu !== undefined) {
              allData.push({
                key: "Simu",
                name: "Simulateur",
                value: roundDecimal(
                  parseFloat(response.data.Simu),
                  2
                ).toString(),
              });
            }
            if (response.data.taux_tva !== undefined) {
              allData.push({
                key: "taux_tva",
                name: "Taux TVA",
                value:
                  roundDecimal(
                    parseFloat(response.data.taux_tva) * 100,
                    2
                  ).toString() + " %",
              });
            }
            if (response.data.emoluments_actes_total !== undefined) {
              allData.push({
                key: "emoluments_actes_total",
                name: "Emoluments d'actes",
                value:
                  roundDecimal(
                    parseFloat(response.data.emoluments_actes_total),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.emoluments_actes !== undefined) {
              allData.push({
                key: "emoluments_actes",
                name: "Emoluments d'actes",
                value:
                  roundDecimal(
                    parseFloat(response.data.emoluments_actes),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.emol_promesse !== undefined) {
              allData.push({
                key: "emol_promesse",
                name: "Emoluments promesse",
                value:
                  roundDecimal(
                    parseFloat(response.data.emol_promesse),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.debours_urbanisme !== undefined) {
              allData.push({
                key: "debours_urbanisme",
                name: "Autres debours et/ou urbanisme",
                value:
                  roundDecimal(
                    parseFloat(response.data.debours_urbanisme),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.base_tresor !== undefined) {
              allData.push({
                key: "base_tresor",
                name: "Base de calcul du trésor",
                value:
                  roundDecimal(
                    parseFloat(response.data.base_tresor),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.base_debours !== undefined) {
              allData.push({
                key: "base_debours",
                name: "Base de débours",
                value:
                  roundDecimal(
                    parseFloat(response.data.base_debours),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.base_csi_publication !== undefined) {
              allData.push({
                key: "base_csi_publication",
                name: "Base CSI de publication",
                value:
                  roundDecimal(
                    parseFloat(response.data.base_csi_publication),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.csi_publication !== undefined) {
              allData.push({
                key: "csi_publication",
                name: "CSI de publication",
                value:
                  roundDecimal(
                    parseFloat(response.data.csi_publication),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.base_droit_mutation !== undefined) {
              allData.push({
                key: "base_droit_mutation",
                name: "Base Droits de mutation",
                value:
                  roundDecimal(
                    parseFloat(response.data.base_droit_mutation),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.droit_mutation !== undefined) {
              allData.push({
                key: "droit_mutation",
                name: "Droits de mutation",
                value:
                  roundDecimal(
                    parseFloat(response.data.droit_mutation),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.zrr_mutation !== undefined) {
              allData.push({
                key: "zrr_mutation",
                name: "Droits de mutation ZRR",
                value:
                  roundDecimal(
                    parseFloat(response.data.zrr_mutation),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.taxe_ile_de_france !== undefined) {
              allData.push({
                key: "taxe_ile_de_france",
                name: "Taxe additionnelle Île-de-France",
                value:
                  roundDecimal(
                    parseFloat(response.data.taxe_ile_de_france),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.droit_etat_promesse !== undefined) {
              allData.push({
                key: "droit_etat_promesse",
                name: "Droit état promesse",
                value:
                  roundDecimal(
                    parseFloat(response.data.droit_etat_promesse),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.droit_etat !== undefined) {
              allData.push({
                key: "droit_etat",
                name: "Droit état",
                value:
                  roundDecimal(
                    parseFloat(response.data.droit_etat),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.droit_etat_acte_separe !== undefined) {
              allData.push({
                key: "droit_etat_acte_separe",
                name: "Droit état acte séparé",
                value:
                  roundDecimal(
                    parseFloat(response.data.droit_etat_acte_separe),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.base_csi_inscription !== undefined) {
              allData.push({
                key: "base_csi_inscription",
                name: "Base CSI d'inscription",
                value:
                  roundDecimal(
                    parseFloat(response.data.base_csi_inscription),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.csi_inscription !== undefined) {
              allData.push({
                key: "csi_inscription",
                name: "CSI d'inscription",
                value:
                  roundDecimal(
                    parseFloat(response.data.csi_inscription),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.base_tpf !== undefined) {
              allData.push({
                key: "base_tpf",
                name: "Base taxe de publicité foncière",
                value:
                  roundDecimal(
                    parseFloat(response.data.base_tpf),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.tpf !== undefined) {
              allData.push({
                key: "tpf",
                name: "Taxe de publicité foncière",
                value:
                  roundDecimal(parseFloat(response.data.tpf), 2).toString() +
                  " €",
              });
            }
            if (response.data.debours_pouvoir !== undefined) {
              allData.push({
                key: "debours_pouvoir",
                name: "Débours pouvoir",
                value:
                  roundDecimal(
                    parseFloat(response.data.debours_pouvoir),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.droit_etat_simplifie !== undefined) {
              allData.push({
                key: "droit_etat_simplifie",
                name: "Droit état simplifié",
                value:
                  roundDecimal(
                    parseFloat(response.data.droit_etat_simplifie),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.base_droit_partage !== undefined) {
              allData.push({
                key: "base_droit_partage",
                name: "Base droit partage",
                value:
                  roundDecimal(
                    parseFloat(response.data.base_droit_partage),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.droit_partage !== undefined) {
              allData.push({
                key: "droit_partage",
                name: "Droit partage",
                value:
                  roundDecimal(
                    parseFloat(response.data.droit_partage),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.base_emol !== undefined) {
              allData.push({
                key: "base_emol",
                name: "Base de calcul des émoluments d'actes",
                value:
                  roundDecimal(
                    parseFloat(response.data.base_emol),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.base_emol_1 !== undefined) {
              allData.push({
                key: "base_emol_1",
                name: "Base de calcul des émoluments d'actes",
                value:
                  roundDecimal(
                    parseFloat(response.data.base_emol_1),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.base_emol_2 !== undefined) {
              allData.push({
                key: "base_emol_2",
                name: "Base de calcul des émoluments d'actes",
                value:
                  roundDecimal(
                    parseFloat(response.data.base_emol_2),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.base_emol_3 !== undefined) {
              allData.push({
                key: "base_emol_3",
                name: "Base de calcul des émoluments d'actes",
                value:
                  roundDecimal(
                    parseFloat(response.data.base_emol_3),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.emoluments_actes_1 !== undefined) {
              allData.push({
                key: "emoluments_actes_1",
                name: "Emoluments d'actes",
                value:
                  roundDecimal(
                    parseFloat(response.data.emoluments_actes_1),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.emoluments_actes_2 !== undefined) {
              allData.push({
                key: "emoluments_actes_2",
                name: "Emoluments d'actes",
                value:
                  roundDecimal(
                    parseFloat(response.data.emoluments_actes_2),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.emoluments_actes_3 !== undefined) {
              allData.push({
                key: "emoluments_actes_3",
                name: "Emoluments d'actes",
                value:
                  roundDecimal(
                    parseFloat(response.data.emoluments_actes_3),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.rapport_total !== undefined) {
              allData.push({
                key: "rapport_total",
                name: "Rapport total",
                value:
                  roundDecimal(
                    parseFloat(response.data.rapport_total),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.rapport_especes !== undefined) {
              allData.push({
                key: "rapport_especes",
                name: "Rapport espèces",
                value:
                  roundDecimal(
                    parseFloat(response.data.rapport_especes),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.droit_partage_rapport !== undefined) {
              allData.push({
                key: "droit_partage_rapport",
                name: "Droit partage rapport",
                value:
                  roundDecimal(
                    parseFloat(response.data.droit_partage_rapport),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.droit_partage_rapport !== undefined) {
              allData.push({
                key: "base_droit_partage_rapport",
                name: "Base droit partage rapport",
                value:
                  roundDecimal(
                    parseFloat(response.data.base_droit_partage_rapport),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.csi_publication_rapport !== undefined) {
              allData.push({
                key: "csi_publication_rapport",
                name: "CSI de publication rapport",
                value:
                  roundDecimal(
                    parseFloat(response.data.csi_publication_rapport),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.base_csi_publication_rapport !== undefined) {
              allData.push({
                key: "base_csi_publication_rapport",
                name: "Base CSI de publication rapport",
                value:
                  roundDecimal(
                    parseFloat(response.data.base_csi_publication_rapport),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.taux_usufruit_1 !== undefined) {
              allData.push({
                key: "taux_usufruit_1",
                name: "Taux usufruit donateur 1",
                value:
                  roundDecimal(
                    parseFloat(response.data.taux_usufruit_1),
                    2
                  ).toString() + " %",
              });
            }
            if (response.data.taux_usufruit_2 !== undefined) {
              allData.push({
                key: "taux_usufruit_2",
                name: "Taux usufruit donateur 2",
                value:
                  roundDecimal(
                    parseFloat(response.data.taux_usufruit_2),
                    2
                  ).toString() + " %",
              });
            }
            if (response.data.taux_nue_propriete_1 !== undefined) {
              allData.push({
                key: "taux_nue_propriete_1",
                name: "Taux nue-propriété donateur 1",
                value:
                  roundDecimal(
                    parseFloat(response.data.taux_nue_propriete_1),
                    2
                  ).toString() + " %",
              });
            }
            if (response.data.taux_nue_propriete_2 !== undefined) {
              allData.push({
                key: "taux_nue_propriete_2",
                name: "Taux nue-propriété donateur 2",
                value:
                  roundDecimal(
                    parseFloat(response.data.taux_nue_propriete_2),
                    2
                  ).toString() + " %",
              });
            }
            if (response.data.valeur_bien_1 !== undefined) {
              allData.push({
                key: "valeur_bien_1",
                name: "Valeur du bien donateur 1",
                value:
                  roundDecimal(
                    parseFloat(response.data.valeur_bien_1),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.valeur_bien_2 !== undefined) {
              allData.push({
                key: "valeur_bien_2",
                name: "Valeur du bien donateur 2",
                value:
                  roundDecimal(
                    parseFloat(response.data.valeur_bien_2),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.Usufruit_1 !== undefined) {
              allData.push({
                key: "Usufruit_1",
                name: "Usufruit donateur 1",
                value:
                  roundDecimal(
                    parseFloat(response.data.Usufruit_1),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.Usufruit_2 !== undefined) {
              allData.push({
                key: "Usufruit_2",
                name: "Usufruit donateur 2",
                value:
                  roundDecimal(
                    parseFloat(response.data.Usufruit_2),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.Nue_propriete_1 !== undefined) {
              allData.push({
                key: "Nue_propriete_1",
                name: "Nue-propriété donateur 1",
                value:
                  roundDecimal(
                    parseFloat(response.data.Nue_propriete_1),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.Nue_propriete_2 !== undefined) {
              allData.push({
                key: "Nue_propriete_2",
                name: "Nue-propriété donateur 2",
                value:
                  roundDecimal(
                    parseFloat(response.data.Nue_propriete_2),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.base_tresor_1 !== undefined) {
              allData.push({
                key: "base_tresor_1",
                name: "Valeur de la nue-propriété donnée donateur 1",
                value:
                  roundDecimal(
                    parseFloat(response.data.base_tresor_1),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.base_tresor_2 !== undefined) {
              allData.push({
                key: "base_tresor_2",
                name: "Valeur de la nue-propriété donnée donateur 2",
                value:
                  roundDecimal(
                    parseFloat(response.data.base_tresor_2),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.droit_donation_1 !== undefined) {
              allData.push({
                key: "droit_donation_1",
                name: "Droits donation donateur 1",
                value:
                  roundDecimal(
                    parseFloat(response.data.droit_donation_1),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.droit_donation_2 !== undefined) {
              allData.push({
                key: "droit_donation_2",
                name: "Droits donation donateur 2",
                value:
                  roundDecimal(
                    parseFloat(response.data.droit_donation_2),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.csi_publication_1 !== undefined) {
              allData.push({
                key: "csi_publication_1",
                name: "CSI publication donateur 1",
                value:
                  roundDecimal(
                    parseFloat(response.data.csi_publication_1),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.csi_publication_2 !== undefined) {
              allData.push({
                key: "csi_publication_2",
                name: "CSI publication donateur 2",
                value:
                  roundDecimal(
                    parseFloat(response.data.csi_publication_2),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.usufruit_retenu !== undefined) {
              allData.push({
                key: "usufruit_retenu",
                name: "Usufruit retenu",
                value:
                  roundDecimal(
                    parseFloat(response.data.usufruit_retenu),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.csi_publication_usufruit !== undefined) {
              allData.push({
                key: "csi_publication_usufruit",
                name: "CSI de publication usufruit",
                value:
                  roundDecimal(
                    parseFloat(response.data.csi_publication_usufruit),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.spf !== undefined) {
              allData.push({
                key: "spf",
                name: "Service de publicité foncière",
                value:
                  roundDecimal(parseFloat(response.data.spf), 2).toString() +
                  " €",
              });
            }
            if (response.data.comedec_1 !== undefined) {
              allData.push({
                key: "comedec_1",
                name: "Total des comedec à 0.56 €",
                value:
                  roundDecimal(
                    parseFloat(response.data.comedec_1),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.comedec_2 !== undefined) {
              allData.push({
                key: "comedec_2",
                name: "Total des comedec à 3.56 €",
                value:
                  roundDecimal(
                    parseFloat(response.data.comedec_2),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.debours_adsn !== undefined) {
              allData.push({
                key: "debours_adsn",
                name: "Debours ADSN",
                value:
                  roundDecimal(
                    parseFloat(response.data.debours_adsn),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.taxe_hypothecaire !== undefined) {
              allData.push({
                key: "taxe_hypothecaire",
                name: "Taxe hypothécaire",
                value:
                  roundDecimal(
                    parseFloat(response.data.taxe_hypothecaire),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.droit_requisition_publication !== undefined) {
              allData.push({
                key: "droit_requisition_publication",
                name: "Droit réquisition publication",
                value:
                  roundDecimal(
                    parseFloat(response.data.droit_requisition_publication),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.reglement_etablissement !== undefined) {
              allData.push({
                key: "reglement_etablissement",
                name: "Règlement établissement",
                value:
                  roundDecimal(
                    parseFloat(response.data.reglement_etablissement),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.reglement_etablissement_lot !== undefined) {
              allData.push({
                key: "reglement_etablissement_lot",
                name: "Règlement établissement lot",
                value:
                  roundDecimal(
                    parseFloat(response.data.reglement_etablissement_lot),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.csi_publication_lot_1 !== undefined) {
              allData.push({
                key: "csi_publication_lot_1",
                name: "CSI publication lot 1",
                value:
                  roundDecimal(
                    parseFloat(response.data.csi_publication_lot_1),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.csi_publication_lot_2 !== undefined) {
              allData.push({
                key: "csi_publication_lot_2",
                name: "CSI publication lot 2",
                value:
                  roundDecimal(
                    parseFloat(response.data.csi_publication_lot_2),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.droit_echange !== undefined) {
              allData.push({
                key: "droit_echange",
                name: "Droit échange",
                value:
                  roundDecimal(
                    parseFloat(response.data.droit_echange),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.Honoraires !== undefined) {
              allData.push({
                key: "Honoraires",
                name: "Honoraires",
                value:
                  roundDecimal(
                    parseFloat(response.data.Honoraires),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.tva_brevet !== undefined) {
              allData.push({
                key: "tva_brevet",
                name: "TVA brevet",
                value:
                  roundDecimal(
                    parseFloat(response.data.tva_brevet),
                    2
                  ).toString() + " €",
              });
            }
            if (response.data.emoluments_actes_total !== undefined) {
              allData.push({
                key: "emoluments_actes_total",
                name: "Total des émoluments d'actes",
                value:
                  roundDecimal(
                    parseFloat(response.data.emoluments_actes_total),
                    2
                  ).toString() + " €",
              });
            }

            simulateurObject["results"]["output"] = allData;
            //setArrayContent(allData)
            setCreatePDF(true);
            //const base64Image = chartRef?.current?.chartInstance?.toBase64Image();

            showModal();
          } else {
            enqueueSnackbar("Une erreur est survenue", {
              variant: "error",
            });
          }
        })
        .catch((err) => {
          enqueueSnackbar(
            "Tous les champs n'ont pas été correctement renseignés.",
            {
              variant: "error",
            }
          );
        });
    });
  }

  function isNumeric(str) {
    if (typeof str != "string") return false;
    return !isNaN(str) && !isNaN(parseFloat(str));
  }

  function showModal() {
    setShow(true);
  }

  function hideModal() {
    setShow(false);
    setReadyPDF(false);
    setShowEmol(false);
    setCalculateDMTGState(false);
  }

  let [objValues, setObjValues] = useState({});

  useEffect(() => {
    if (simulateurObject["results"]["custom"].length > 0)
      objValues = simulateurObject["results"]["custom"];
    else objValues = simulateurObject["results"]["output"];
  }, [
    simulateurObject["results"]["output"],
    simulateurObject["results"]["custom"],
  ]);

  function tablePrint() {
    if (simulateurObject["results"]["custom"]?.length > 0) {
      for (let i = 0; i < simulateurObject["results"]["custom"]?.length; i++) {
        objValues[simulateurObject["results"]["custom"][`${i}`]["key"]] =
          simulateurObject["results"]["custom"][`${i}`].value.replace("€", "");
      }
    } else
      for (let i = 0; i < simulateurObject["results"]["output"]?.length; i++) {
        objValues[simulateurObject["results"]["output"][`${i}`]["key"]] =
          simulateurObject["results"]["output"][`${i}`].value.replace("€", "");
      }

    const dataPieChart = {
      labels: ["Emoluments", "TVA", "Trésor", "Débours"],
      datasets: [
        {
          data: [
            Math.round(objValues.Emol_total * 100) / 100,
            Math.round(objValues.TVA_total * 100) / 100,
            Math.round(objValues.Tresor_total * 100) / 100,
            Math.round(objValues.Debours_total * 100) / 100,
          ],
          backgroundColor: [
            "rgba(182, 169, 153, 0.9)",
            "rgba(93,134,141,0.9)",
            "rgba(120, 173, 181, 0.9)",
            "rgba(226, 164, 143, 0.9)",
          ],
          borderColor: [
            "rgb(160,148,134)",
            "rgb(76,110,116)",
            "rgb(101,146,153)",
            "rgb(197,143,124)",
          ],
          borderWidth: 1.8,
        },
      ],
    };

    const options: ChartOptions = {
      tooltips: {
        callbacks: {
          label: function (tooltipItem) {
            return "$" + Number(tooltipItem.yLabel) + " and so worth it !";
          },
        },
      },
      title: {
        display: true,
        text: "Ice Cream Truck",
        position: "bottom",
      },
      animation: {
        onComplete: function () {
          localStorage.setItem("chart", chartRef?.current?.toBase64Image());
        },
      },
    };

    let elem = null;
    if (simulateurObject["results"]["custom"].length > 0)
      elem = simulateurObject["results"]["custom"];
    else elem = simulateurObject["results"]["output"];

    return (
      <div className={showHideClassName}>
        <div className="modal">
          <div
            className="modal-main"
            style={{
              height: showEmol === true ? "90%" : "80%",
            }}
          >
            <>
              {elem !== undefined ? (
                <div style={styles.ctnTable}>
                  <div style={styles.ctnTableData}>
                    <div style={{ flex: 6 }}>
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: 20,
                          fontWeight: "bold",
                        }}
                      >
                        Résultat de votre provision sur frais
                      </p>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          justifyContent: "space-around",
                        }}
                      >
                        <div
                          style={{
                            width: showEmol === true ? "100%" : "40%",
                            height: showEmol === true ? "100%" : "40%",
                          }}
                        >
                          <table
                            style={{
                              ...styles.table,
                              width: showEmol === true ? "100%" : "100%",
                            }}
                          >
                            <tbody>
                              {elem ? (
                                elem.map((value, index) => {
                                  var currentClass = {
                                    backgroundColor: "white",
                                  };
                                  if (value.key === "Provision_total") {
                                    currentClass = { backgroundColor: "white" };
                                    return (
                                      <>
                                        <tr style={currentClass}>
                                          <td
                                            style={{
                                              ...styles.td,
                                              paddingLeft:
                                                value.key === "Major" ||
                                                value.key === "Emol_Form_Total"
                                                  ? 25
                                                  : 10,
                                              fontStyle:
                                                value.key === "Major" ||
                                                value.key === "Emol_Form_Total"
                                                  ? "italic"
                                                  : "",
                                            }}
                                          >
                                            {value.name}
                                          </td>
                                          <td style={styles.td2}>
                                            {value.value}
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  }
                                  if (
                                    value.key === "Provision_Pourcentage_total"
                                  ) {
                                    currentClass = { backgroundColor: "white" };
                                    return (
                                      <>
                                        <tr style={currentClass}>
                                          <td
                                            style={{
                                              ...styles.td,
                                              paddingLeft:
                                                value.key === "Major" ||
                                                value.key === "Emol_Form_Total"
                                                  ? 25
                                                  : 10,
                                              fontStyle:
                                                value.key === "Major" ||
                                                value.key === "Emol_Form_Total"
                                                  ? "italic"
                                                  : "",
                                            }}
                                          >
                                            {value.name}
                                          </td>
                                          <td style={styles.td2}>
                                            {value.value}
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  }
                                  if (
                                    value.key === "Emol_total" ||
                                    value.key === "Major"
                                  ) {
                                    currentClass = {
                                      backgroundColor: "rgba(164,148,128,0.8)",
                                    };
                                    return (
                                      <>
                                        <tr style={currentClass}>
                                          <td
                                            style={{
                                              ...styles.td,
                                              paddingLeft:
                                                value.key === "Major" ||
                                                value.key === "Emol_Form_Total"
                                                  ? 25
                                                  : 10,
                                              fontStyle:
                                                value.key === "Major" ||
                                                value.key === "Emol_Form_Total"
                                                  ? "italic"
                                                  : "",
                                            }}
                                          >
                                            {value.name}
                                          </td>
                                          <td style={styles.td2}>
                                            {value.value}
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  }
                                  if (
                                    value.key === "TVA_total" ||
                                    value.key === "Tresor_total"
                                  ) {
                                    currentClass = {
                                      backgroundColor: "rgba(71,143,154,0.73)",
                                    };
                                    return (
                                      <>
                                        <tr style={currentClass}>
                                          <td
                                            style={{
                                              ...styles.td,
                                              paddingLeft:
                                                value.key === "Major" ||
                                                value.key === "Emol_Form_Total"
                                                  ? 25
                                                  : 10,
                                              fontStyle:
                                                value.key === "Major" ||
                                                value.key === "Emol_Form_Total"
                                                  ? "italic"
                                                  : "",
                                            }}
                                          >
                                            {value.name}
                                          </td>
                                          <td style={styles.td2}>
                                            {value.value}
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  }
                                  if (value.key === "Debours_total") {
                                    currentClass = {
                                      backgroundColor: "rgba(214,127,98,0.71)",
                                    };
                                    return (
                                      <>
                                        <tr style={currentClass}>
                                          <td
                                            style={{
                                              ...styles.td,
                                              paddingLeft:
                                                value.key === "Major" ||
                                                value.key === "Emol_Form_Total"
                                                  ? 25
                                                  : 10,
                                              fontStyle:
                                                value.key === "Major" ||
                                                value.key === "Emol_Form_Total"
                                                  ? "italic"
                                                  : "",
                                            }}
                                          >
                                            {value.name}
                                          </td>
                                          <td style={styles.td2}>
                                            {value.value}
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  }
                                  if (value.key === "Emol_Form_Total") {
                                    currentClass = {
                                      backgroundColor: "rgba(164,148,128,0.8)",
                                      borderBottom:
                                        "1px solid rgb(238, 236, 236)",
                                    };
                                    if (value.value === "-1 €") {
                                      value.value = "écrêtés";
                                    }
                                    return (
                                      <>
                                        <tr style={currentClass}>
                                          <td
                                            style={{
                                              ...styles.td,
                                              paddingLeft:
                                                value.key === "Major" ||
                                                value.key === "Emol_Form_Total"
                                                  ? 25
                                                  : 10,
                                              fontStyle:
                                                value.key === "Major" ||
                                                value.key === "Emol_Form_Total"
                                                  ? "italic"
                                                  : "",
                                            }}
                                          >
                                            {value.name}
                                          </td>
                                          <td style={styles.td2}>
                                            {value.value}
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  }

                                  if (value.key === "DMTG") {
                                    currentClass = {
                                      backgroundColor: "rgba(71,143,154,0.73)",
                                      borderBottom:
                                        "1px solid rgb(238, 236, 236)",
                                    };
                                    return (
                                      <>
                                        <tr style={currentClass}>
                                          <td
                                            style={{
                                              ...styles.td,
                                              paddingLeft:
                                                value.key === "Major" ||
                                                value.key === "DMTG"
                                                  ? 25
                                                  : 10,
                                              fontStyle:
                                                value.key === "Major" ||
                                                value.key === "DMTG"
                                                  ? "italic"
                                                  : "",
                                            }}
                                          >
                                            {value.name}
                                          </td>
                                          <td style={styles.td2}>
                                            {value.value}
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  }

                                  if (elem[index + 1] !== undefined) {
                                    if (elem[index + 1].key === "Major") {
                                      currentClass["borderBottom"] = "";
                                    }
                                  }
                                  if (
                                    value.key === "Major" ||
                                    value.key === "Tresor_total"
                                  ) {
                                    currentClass["borderBottom"] =
                                      "1px solid rgb(238, 236, 236)";
                                    return (
                                      <>
                                        <tr style={currentClass}>
                                          <td
                                            style={{
                                              ...styles.td,
                                              paddingLeft:
                                                value.key === "Major" ||
                                                value.key === "Emol_Form_Total"
                                                  ? 25
                                                  : 10,
                                              fontStyle:
                                                value.key === "Major" ||
                                                value.key === "Emol_Form_Total"
                                                  ? "italic"
                                                  : "",
                                            }}
                                          >
                                            {value.name}
                                          </td>
                                          <td style={styles.td2}>
                                            {value.value}
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  }

                                  if (
                                    simulateurObject["results"]["custom"]
                                      .length > 0
                                  )
                                    simulateurObject["results"]["custom"] =
                                      elem;
                                  else
                                    simulateurObject["results"]["output"] =
                                      elem;
                                })
                              ) : (
                                <></>
                              )}
                            </tbody>
                          </table>
                        </div>

                        <div
                          style={{
                            //width: 300,
                            //height: 300
                            width: "20em",
                            height: "20em",
                          }}
                        >
                          <Pie
                            data={dataPieChart}
                            id={"myChart"}
                            ref={chartRef}
                            options={options}
                          />
                        </div>
                      </div>

                      {buttonPrint()}
                    </div>

                    <img
                      src={`${close}`}
                      style={{
                        position: "absolute",
                        fontWeight: "bold",
                        border: "solid 1px",
                        borderRadius: 5,
                        borderColor: "black",
                        right: 20,
                        height: 20,
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        setCreatePDF(false);
                        setShowEmol(false);
                        setReadyPDF(false);
                        setCalculateDMTGState(false);
                        hideModal();
                      }}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </>
          </div>
        </div>
      </div>
    );
  }

  function buttonPrint() {
    //console.log(JSON.stringify(simulateurObject["results"]["output"]))
    return (
      <div style={{ ...styles.ctnButtonValide }}>
        {createPDF === true ? (
          <LinkPDF
            simulateurObject={simulateurObject}
            showEmol={showEmol}
            ready={readyPDF}
            setReadyMain={setReadyPDF}
          />
        ) : (
          <></>
        )}
      </div>
    );
  }

  function printCalculateResult() {
    if (parserSimulator().props.children !== "Simulateur non disponible") {
      return (
        <>
          <div style={styles.ctnButton}>
            <button
              style={styles.buttonCalculate}
              onClick={(e) => {
                if (
                  listOfSimuWithDMTG.includes(
                    parseInt(props.match.params.simulateur)
                  ) &&
                  data.inputs["DMTG"] === 1
                ) {
                  //console.log("CALCULATEDMTG")
                  calculateDMTG();
                } else calculate();
              }}
            >
              Calculer la provision sur frais
            </button>
          </div>
          {tablePrint()}
        </>
      );
    } else return <></>;
  }

  useEffect(() => {
    if (data?.inputs?.result === 1) {
      calculate();
    }
  }, [data]);

  async function calculateDMTG() {
    setCalculateDMTGState(true);
    //console.log("THISISMYDATA")
    //console.log(JSON.stringify(data))
  }

  function parserSimulator() {
    switch (props.match.params.simulateur) {
      case "1": {
        return (
          <>
            <Simulateur_A1_1
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "2": {
        return (
          <>
            <Simulateur_A1_2
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "3": {
        return (
          <>
            <Simulateur_A1_3
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "4": {
        return (
          <>
            <Simulateur_A1_3bis
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "5": {
        return (
          <>
            <Simulateur_A2_1
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "6": {
        return (
          <>
            <Simulateur_A2_8
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "7": {
        return (
          <>
            <Simulateur_A1_4
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      // OK
      case "8": {
        return (
          <>
            <Simulateur_A1_5
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "9": {
        return (
          <>
            <Simulateur_A1_5bis
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "10": {
        return (
          <>
            <Simulateur_EDD
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "11": {
        return (
          <>
            <Simulateur_MC_EDD
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "12": {
        return (
          <>
            <Simulateur_A2_2
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "13": {
        return (
          <>
            <Simulateur_A2_3
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "14": {
        return (
          <>
            <Simulateur_A2_4
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "15": {
        return (
          <>
            <Simulateur_A2_5
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "16": {
        return (
          <>
            <Simulateur_A2_6
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "17": {
        return (
          <>
            <Simulateur_A2_7
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      // OK
      case "18": {
        return (
          <>
            <Simulateur_ESD
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      // OK
      case "19": {
        return (
          <>
            <Simulateur_ESI
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      // OK
      case "20": {
        return (
          <>
            <Simulateur_ESIM
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      // OK
      case "21": {
        return (
          <>
            <Simulateur_ESDM
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      // OK
      case "22": {
        return (
          <>
            <Simulateur_A1_24
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      // OK
      case "23": {
        return (
          <>
            <Simulateur_A1_25
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      // OK
      case "24": {
        return (
          <>
            <Simulateur_A1_24bis
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      // OK
      case "25": {
        return (
          <>
            <Simulateur_A1_25bis
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      // OK
      case "26": {
        return (
          <>
            <Simulateur_A1_26
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      // OK
      case "27": {
        return (
          <>
            <Simulateur_A1_27
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      // OK
      case "28": {
        return (
          <>
            <Simulateur_A1_28
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      // OK
      case "29": {
        return (
          <>
            <Simulateur_A1_29
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      // OK
      case "30": {
        return (
          <>
            <Simulateur_A1_30
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      // OK
      case "31": {
        return (
          <>
            <Simulateur_A1_7
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "34": {
        return (
          <>
            <Simulateur_34
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "35": {
        return (
          <>
            <Simulateur_35
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "36": {
        return (
          <>
            <Simulateur_36
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "37": {
        return (
          <>
            <Simulateur_37
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "38": {
        return (
          <>
            <Simulateur_38
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "39": {
        return (
          <>
            <Simulateur_39
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "40": {
        return (
          <>
            <Simulateur_40
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "41": {
        return (
          <>
            <Simulateur_41
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "43": {
        return (
          <>
            <Simulateur_ASimmo1
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "44": {
        return (
          <>
            <Simulateur_ASimmo3
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "45": {
        return (
          <>
            <Simulateur_ASimmo4
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "46": {
        return (
          <>
            <Simulateur_ASimmo5
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "47": {
        return (
          <>
            <Simulateur_ASimmo6
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "48": {
        return (
          <>
            <Simulateur_48
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
              calculateDMTGState={calculateDMTGState}
            />
          </>
        );
      }
      case "49": {
        return (
          <>
            <Simulateur_49
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
              calculateDMTGState={calculateDMTGState}
            />
          </>
        );
      }
      case "50": {
        return (
          <>
            <Simulateur_50
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
              calculateDMTGState={calculateDMTGState}
            />
          </>
        );
      }
      case "51": {
        return (
          <>
            <Simulateur_51
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
              calculateDMTGState={calculateDMTGState}
            />
          </>
        );
      }
      case "52": {
        return (
          <>
            <Simulateur_52
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
              calculateDMTGState={calculateDMTGState}
            />
          </>
        );
      }
      case "53": {
        return (
          <>
            <Simulateur_53
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "54": {
        return (
          <>
            <Simulateur_54
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      // OK
      case "57": {
        return (
          <>
            <Simulateur_57
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
              calculateDMTGState={calculateDMTGState}
            />
          </>
        );
      }
      case "58": {
        return (
          <>
            <Simulateur_A1_20
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      // OK
      case "59": {
        return (
          <>
            <Simulateur_A1_20bis
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      // OK
      case "60": {
        return (
          <>
            <Simulateur_A1_21
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "61": {
        return (
          <>
            <Simulateur_A1_21bis
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "62": {
        return (
          <>
            <Simulateur_ASF1
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "63": {
        return (
          <>
            <Simulateur_ASF2
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "64": {
        return (
          <>
            <Simulateur_ASF3
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "65": {
        return (
          <>
            <Simulateur_ASF5
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "66": {
        return (
          <>
            <Simulateur_ASF6
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "67": {
        return (
          <>
            <Simulateur_ASF7
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "68": {
        return (
          <>
            <Simulateur_ASF8
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "69": {
        return (
          <>
            <Simulateur_ASF9
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "70": {
        return (
          <>
            <Simulateur_ASF10
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "71": {
        return (
          <>
            <Simulateur_ASF12
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "72": {
        return (
          <>
            <Simulateur_ASF12bis
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "73": {
        return (
          <>
            <Simulateur_ASF13
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "74": {
        return (
          <>
            <Simulateur_ASF13bis
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "75": {
        return (
          <>
            <Simulateur_ASF14
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "76": {
        return (
          <>
            <Simulateur_76
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      case "77": {
        return (
          <>
            <Simulateur_77
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }

      // OK
      case "84": {
        return (
          <>
            <Simulateur_A1_12
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      // OK
      case "85": {
        return (
          <>
            <Simulateur_A1_12VEFA
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      // OK
      case "86": {
        return (
          <>
            <Simulateur_A1_8
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      // OK
      case "87": {
        return (
          <>
            <Simulateur_A1_9
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      // OK
      case "88": {
        return (
          <>
            <Simulateur_A2_14
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      // OK
      case "89": {
        return (
          <>
            <Simulateur_A2_15
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      // OK
      case "90": {
        return (
          <>
            <Simulateur_A2_16
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      // OK
      case "91": {
        return (
          <>
            <Simulateur_A2_17
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      // OK
      case "92": {
        return (
          <>
            <Simulateur_A1_12PRO
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      // OK
      case "93": {
        return (
          <>
            <Simulateur_A1_10
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      // OK
      case "94": {
        return (
          <>
            <Simulateur_A1_11
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      // OK
      case "95": {
        return (
          <>
            <Simulateur_A2_20
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      // OK
      case "96": {
        return (
          <>
            <Simulateur_A2_18
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      // OK
      case "97": {
        return (
          <>
            <Simulateur_A2_19
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      // OK
      case "98": {
        return (
          <>
            <Simulateur_A1_14
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      // OK
      case "99": {
        return (
          <>
            <Simulateur_A1_15
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      // OK
      case "100": {
        return (
          <>
            <Simulateur_A1_16
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      // OK
      case "101": {
        return (
          <>
            <Simulateur_A1_16bis
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      //
      case "102": {
        return (
          <>
            <Simulateur_A1_17
              simulateur={parentSimulateur}
              isParent={true}
              departements={departements}
            />
          </>
        );
      }
      default: {
        return <h2>Simulateur non disponible</h2>;
      }
    }
  }

  // SWITCH WITH ID SIMULATEUR
  return (
    <div style={styles.container}>
      {parserSimulator()}
      {printCalculateResult()}
    </div>
  );
}

export default SimulateurMain;
