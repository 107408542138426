import React, {useEffect, useState} from "react";
import {setSimulateurObject} from "../../../actions/simulateur.action";
import {useDispatch} from 'react-redux';
import {styles} from "../../../stylesheets/simulateurs.css";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import ButtonText from "../../../components/buttons/button.text";
import SelectDepartement from "../../../components/selects/select.departement";
import {ToolTipPerso} from "../../../components/selects/tooltip_perso";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";

//Prêts aux particuliers garantis par une hypothèque légale de prêteur de deniers et une hypothèque conventionnelle dans l'acte de vente
// id simu 84

function Simulateur_A1_12(props, simulateur, isParent, departements) {
    const dispatch = useDispatch();

    let currUser = JSON.parse(localStorage.getItem("efluser"));
    let currUserDep = 0
    if (currUser.societe_zip.startsWith("97")){
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 3);
        currUserDep = parseInt(trimedZip)
    } else {
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 2);
        currUserDep = parseInt(trimedZip)
    }

    const currentIdSimu = 84
    const [departement, setDepartement] = useState(0)
    const [pretRealise, setPretRealise] = useState(0)
    const [montantPret, setMontantPret] = useState(0)
    const [montantPretHp, setMontantPretHp] = useState(0)
    const [montantPretHc, setMontantPretHc] = useState(0)

    const handleChange = async (setter, value) => {
        setter(value);
        setInRedux(setter, value)
    };

    const handleChangeRemarque = async (value) => {
        localStorage.setItem("remarque", value)
    }

    function setInRedux(setter, value) {
        let object = {
            "simulateurid": currentIdSimu,
            "departementid": 0,
            "bureauid": parseInt(currUser.id),
            "inputs": {}
        };

        if (setter === setPretRealise) {
            Object.assign(object['inputs'], {'pret_realise': parseInt(value)})
            Object.assign(object['inputs'], {'montant_pret': parseInt(montantPret)})
            Object.assign(object['inputs'], {'montant_pret_hp': parseInt(montantPretHp)})
            Object.assign(object['inputs'], {'montant_pret_hc': parseInt(montantPretHc)})
            object.departementid = parseInt(departement)
        }

        if (setter === setMontantPretHp) {
            Object.assign(object['inputs'], {'pret_realise': parseInt(pretRealise)})
            Object.assign(object['inputs'], {'montant_pret': parseInt(montantPret)})
            Object.assign(object['inputs'], {'montant_pret_hp': parseInt(value)})
            Object.assign(object['inputs'], {'montant_pret_hc': parseInt(montantPretHc)})
            object.departementid = parseInt(departement)
        }

        if (setter === setMontantPretHc) {
            Object.assign(object['inputs'], {'pret_realise': parseInt(pretRealise)})
            Object.assign(object['inputs'], {'montant_pret': parseInt(montantPret)})
            Object.assign(object['inputs'], {'montant_pret_hp': parseInt(montantPretHp)})
            Object.assign(object['inputs'], {'montant_pret_hc': parseInt(value)})
            object.departementid = parseInt(departement)
        }

        if (setter === setMontantPret) {
            Object.assign(object['inputs'], {'pret_realise': parseInt(pretRealise)})
            Object.assign(object['inputs'], {'montant_pret': parseInt(value)})
            Object.assign(object['inputs'], {'montant_pret_hp': parseInt(montantPretHp)})
            Object.assign(object['inputs'], {'montant_pret_hc': parseInt(montantPretHc)})
            object.departementid = parseInt(departement)
        }

        if (setter === setDepartement) {
            Object.assign(object['inputs'], {'pret_realise': parseInt(pretRealise)})
            Object.assign(object['inputs'], {'montant_pret': parseInt(montantPret)})
            Object.assign(object['inputs'], {'montant_pret_hp': parseInt(montantPretHp)})
            Object.assign(object['inputs'], {'montant_pret_hc': parseInt(montantPretHc)})
            object.departementid = parseInt(value)
        }

        //props.contentDep(object);
        if (props.isParent) {
            dispatch(setSimulateurObject(object));
        } else {
            object.departementid = parseInt(props.departementId)
            props.dependances(object)
        }
    }

    return (
        <>
            {props?.isParent === true && props.departements !== "" ?
                <div style={styles.container}>
                    <div style={styles.card}>

                        <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id}/>

                        <TextfieldRemarques handleChange={handleChangeRemarque}/>

                        {props?.isParent === true && props.departements !== "" ?
                            <div style={styles.cardRow}>
                                <div style={styles.cardElemVerticalyCenter}>
                                    Département :
                                </div>

                                <SelectDepartement items={props.departements} nameField={"name"} defaultValue={currUserDep} optionValue={"id"}
                                      handleChange={handleChange} setter={setDepartement}/>
                            </div>
                         :
                            <></>
                        }

                        <div style={styles.cardRow}>
                            <div style={styles.cardElemVerticalyCenter}>
                                Le prêt hypothécaire est réalisé :
                                <ToolTipPerso title={"Pour rappel : par « acte séparé » signifie que la convention est la disposition principale de l'acte (un prêt) et « dans le même acte » correspond à la situation où des conventions juridiques distinctes et indépendantes y sont contenues (par exemple une vente et un prêt)."}/>
                            </div>
                            <ButtonText handleChange={handleChange} setter={setPretRealise} defaultValue={0}/>
                        </div>

                        <div style={styles.cardRow}>
                            <div style={styles.cardElemVerticalyCenter}>
                                Indiquer le montant total du prêt ou capitaux empruntés :
                                <ToolTipPerso title={"Les accessoires sont compris et évalués au bordereau à concurrence de 20% du capital emprunté."}/>
                            </div>
                            <TextfieldNumber
                                handleChange={handleChange}
                                type={"number"}
                                placeholder={"Indiquer le montant"}
                                setter={setMontantPret}
                            />
                        </div>

                        <div style={styles.cardRow}>
                            <div style={styles.cardElemVerticalyCenter}>
                                Indiquer le montant du prêt garanti par une hypothèque légale de prêteur de deniers :
                            </div>
                            <TextfieldNumber
                                handleChange={handleChange}
                                type={"number"}
                                placeholder={"Indiquer le montant"}
                                setter={setMontantPretHp}
                            />
                        </div>

                        <div style={styles.cardRow}>
                            <div style={styles.cardElemVerticalyCenter}>
                                Indiquer le montant du prêt garanti par l'HC :
                            </div>
                            <TextfieldNumber
                                handleChange={handleChange}
                                type={"number"}
                                placeholder={"Indiquer le montant"}
                                setter={setMontantPretHc}
                            />
                        </div>


                    </div>
                </div>
                :
                <div style={styles.cardRowChild}>
                    <div style={styles.cardRow}>
                        <div style={styles.cardElemVerticalyCenter}>
                            Le prêt hypothécaire est réalisé :
                            <ToolTipPerso title={"Pour rappel : par « acte séparé » signifie que la convention est la disposition principale de l'acte (un prêt) et « dans le même acte » correspond à la situation où des conventions juridiques distinctes et indépendantes y sont contenues (par exemple une vente et un prêt)."}/>
                        </div>
                        <ButtonText handleChange={handleChange} setter={setPretRealise} defaultValue={0}/>
                    </div>

                    <div style={styles.cardRow}>
                        <div style={styles.cardElemVerticalyCenter}>
                            Indiquer le montant total du prêt ou capitaux empruntés :
                            <ToolTipPerso title={"Les accessoires sont compris et évalués au bordereau à concurrence de 20% du capital emprunté."}/>
                        </div>
                        <TextfieldNumber
                            handleChange={handleChange}
                            type={"number"}
                            placeholder={"Indiquer le montant"}
                            setter={setMontantPret}
                        />
                    </div>

                    <div style={styles.cardRow}>
                        <div style={styles.cardElemVerticalyCenter}>
                            Indiquer le montant du prêt garanti par une hypothèque légale de prêteur de deniers :
                        </div>
                        <TextfieldNumber
                            handleChange={handleChange}
                            type={"number"}
                            placeholder={"Indiquer le montant"}
                            setter={setMontantPretHp}
                        />
                    </div>

                    <div style={styles.cardRow}>
                        <div style={styles.cardElemVerticalyCenter}>
                            Indiquer le montant du prêt garanti par l'HC :
                        </div>
                        <TextfieldNumber
                            handleChange={handleChange}
                            type={"number"}
                            placeholder={"Indiquer le montant"}
                            setter={setMontantPretHc}
                        />
                    </div>
                </div>

            }

        </>
    )
}

export default Simulateur_A1_12;
