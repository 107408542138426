/* eslint-disable react/no-array-index-key */
import React, {
  //   lazy,
  // Suspense,
  Fragment,
  useState,
} from "react";
import { Redirect, Route, useLocation } from "react-router-dom";

import AllRoutes from "./routes/index.routes";
import Home from "./views/Home";
import Login from "./views/connect/Login";
import Header from "./layouts/header/header";
import Footer from "./layouts/footer/footer";
import Breadcrumb from "./layouts/breadcrumb/breadcrumb";
import Categories from "./views/categories/Categories";
import SimulateurMain from "./views/simulateurs/SimulateurMain";
import DmtgMain from "./views/dmtg/DmtgMain";
import OthersMain from "./views/others/OthersMain";
import Profile from "./views/user/Profile";
import Faq from "./views/informations/faq";
import Fiches from "./views/informations/fiches";
import FichesImmo from "./views/informations/fiches_immo";
import FichesFamille from "./views/informations/fiches_famille";
import FichesGenerales from "./views/informations/fiches_generales";
import Rappels from "./views/informations/rappels";
import jwt_decode from "jwt-decode";
import { API_BASE_URL, PARTNER_JWT } from "./config";
import axios from "axios";
import { useSnackbar } from "notistack";

const routesConfig = [
  {
    exact: true,
    path: "/",
    component: () => <Redirect to="/" />,
  },
  {
    exact: true,
    path: "/login",
    component: () => <Redirect to="/login" />,
  },
  {
    exact: true,
    path: "/categorie",
    component: () => <Redirect to="/" />,
  },
  {
    exact: true,
    path: "/categorie/:url_name/simulateur",
    component: () => <Redirect to="/categorie/:url_name" />,
  },
  {
    exact: true,
    path: "/categorie/:url_name#:id",
    component: () => <Redirect to="/categorie/:url_name" />,
  },
  {
    exact: true,
    path: "/simulateur",
    component: () => <Redirect to="/" />,
  },
  {
    exact: true,
    path: "/moncompte",
    component: () => <Redirect to="/moncompte" />,
  },
  {
    exact: true,
    path: "/fiches/fiches_immo",
    component: () => <Redirect to="/fiches/fiches_immo" />,
  },
  {
    exact: true,
    path: "/fiches/fiches_famille",
    component: () => <Redirect to="/fiches/fiches_famille" />,
  },
  {
    exact: true,
    path: "/fiches/fiches_generales",
    component: () => <Redirect to="/fiches/fiches_generales" />,
  },
  // {
  //     exact: true,
  //     path: '/404',
  //     component: lazy(() => import('src/views/pages/Error404View'))
  // },
  AllRoutes,
  {
    path: "/",
    routes: [
      {
        exact: true,
        path: "/",
        layoutHeader: Header,
        layoutBreadCrumb: Breadcrumb,
        component: () => (userInStorage ? <Redirect to="/" /> : <Redirect to="/login" />),
      },
      {
        exact: true,
        path: "/login/:id/:dep",
        layout: Header,
        layoutBreadCrumb: Breadcrumb,
        component: Home,
      },
      {
        exact: true,
        path: "/moncompte",
        layoutHeader: Header,
        component: () => (userInStorage ? Profile : <Redirect to="/moncompte" />),
      },
      {
        exact: true,
        path: "/categorie/:url_name",
        layoutHeader: Header,
        layoutBreadCrumb: Breadcrumb,
        component: () => (userInStorage ? Categories : <Redirect to="/login" />),
      },
      {
        exact: true,
        path: "/categorie/:url_name/simulateur/:simulateur",
        layoutHeader: Header,
        layoutBreadCrumb: Breadcrumb,
        component: () => (userInStorage ? SimulateurMain : <Redirect to="/login" />),
      },
      {
        exact: true,
        path: "/categorie/:url_name/dmtg/:simulateur",
        layoutHeader: Header,
        layoutBreadCrumb: Breadcrumb,
        component: () => (userInStorage ? DmtgMain : <Redirect to="/login" />),
      },
      {
        exact: true,
        path: "/categorie/:url_name/others/:simulateur",
        layoutHeader: Header,
        layoutBreadCrumb: Breadcrumb,
        component: () => (userInStorage ? OthersMain : <Redirect to="/login" />),
      },
      {
        exact: true,
        path: "/faq",
        layoutHeader: Header,
        layoutBreadCrumb: Breadcrumb,
        component: () => (userInStorage ? Faq : <Redirect to="/login" />),
      },
      {
        exact: true,
        path: "/fiches",
        layoutHeader: Header,
        layoutBreadCrumb: Breadcrumb,
        component: () => (userInStorage ? Fiches : <Redirect to="/login" />),
      },
      {
        exact: true,
        path: "/fiches/fiches_immo",
        layoutHeader: Header,
        layoutBreadCrumb: Breadcrumb,
        component: () => (userInStorage ? FichesImmo : <Redirect to="/login" />),
      },
      {
        exact: true,
        path: "/fiches/fiches_famille",
        layoutHeader: Header,
        layoutBreadCrumb: Breadcrumb,
        component: () => (userInStorage ? FichesFamille : <Redirect to="/login" />),
      },
      {
        exact: true,
        path: "/fiches/fiches_generales",
        layoutHeader: Header,
        layoutBreadCrumb: Breadcrumb,
        component: () => (userInStorage ? FichesGenerales : <Redirect to="/login" />),
      },
      {
        exact: true,
        path: "/rappels",
        layoutHeader: Header,
        layoutBreadCrumb: Breadcrumb,
        component: () => (userInStorage ? Rappels : <Redirect to="/login" />),
      },
      {
        exact: true,
        path: "/sso",
        layoutHeader: Header,
        layoutBreadCrumb: Breadcrumb,
        component: () => Rappels,
      },
      // {
      //     component: () => <Redirect to="/404"/>
      // }
    ],
  },
  {
    path: "*",
    // layout: Topmenu,
    routes: [
      {
        exact: true,
        path: "/",
        component: Home,
        layoutInfoFooter: Footer,
      },
      {
        exact: true,
        path: "/login/:id/:dep",
        component: Login,
        layoutFooter: Footer,
      },
      {
        exact: true,
        path: "/categorie/:url_name",
        component: Categories,
        layoutInfoFooter: Footer,
        layoutFooter: Footer,
      },
      {
        exact: true,
        path: "/moncompte",
        component: Profile,
        layoutFooter: Footer,
      },
      {
        exact: true,
        path: "/categorie/:url_name/simulateur/:simulateur",
        component: SimulateurMain,
        layoutInfoFooter: Footer,
        layoutFooter: Footer,
      },
      {
        exact: true,
        path: "/categorie/:url_name/dmtg/:simulateur",
        component: DmtgMain,
        layoutInfoFooter: Footer,
        layoutFooter: Footer,
      },
      {
        exact: true,
        path: "/categorie/:url_name/others/:simulateur",
        component: OthersMain,
        layoutInfoFooter: Footer,
        layoutFooter: Footer,
      },
      {
        exact: true,
        path: "/faq",
        component: Faq,
        layoutInfoFooter: Footer,
        layoutFooter: Footer,
      },
      {
        exact: true,
        path: "/fiches",
        component: Fiches,
        layoutInfoFooter: Footer,
        layoutFooter: Footer,
      },
      {
        exact: true,
        path: "/fiches/fiches_immo",
        component: FichesImmo,
        layoutInfoFooter: Footer,
        layoutFooter: Footer,
      },
      {
        exact: true,
        path: "/fiches/fiches_famille",
        component: FichesFamille,
        layoutInfoFooter: Footer,
        layoutFooter: Footer,
      },
      {
        exact: true,
        path: "/fiches/fiches_generales",
        component: FichesGenerales,
        layoutInfoFooter: Footer,
        layoutFooter: Footer,
      },

      {
        exact: true,
        path: "/rappels",
        component: Rappels,
        layoutInfoFooter: Footer,
        layoutFooter: Footer,
      },

      // {
      //     component: () => <Redirect to="/404" />
      // }
    ],
  },
];

var userInStorage = localStorage.getItem("efluser");

const renderRoutes = (routes) =>
  routes ? (
    //<Suspense
    // fallback={<LoadingScreen/>}
    //>
    <Route>
      {routes.map((route, i) => {
        const Layout = route.layout || Fragment;
        const LayoutFooter = route.layoutFooter || Fragment;
        const LayoutHeader = route.layoutHeader || Fragment;
        const LayoutInfoFooter = route.layoutInfoFooter || Fragment;
        const LayoutBreadCrumb = route.layoutBreadCrumb || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <>
                <Layout />
                <LayoutHeader />
                <LayoutBreadCrumb />
                <>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</>

                <LayoutInfoFooter />
                <LayoutFooter />
              </>
            )}
          />
        );
      })}
    </Route>
  ) : //</Suspense>
  null;

function RenderRoutes() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const params = new URLSearchParams(useLocation().search);
  const token = params.get("token");
  const simulator = params.get("simulateur_id");
  if (token !== "" && token !== null) {
    var decoded = jwt_decode(token);

    localStorage.setItem("efluser", JSON.stringify(decoded));
    userInStorage = decoded;
    if (simulator !== null && simulator !== "") {
      window.location = simulator;
      return renderRoutes(routesConfig);
    }
    window.location = "/";
  }

  return renderRoutes(routesConfig);
}

export default RenderRoutes;
