import React, {useEffect, useState} from "react";
import {setSimulateurObject} from "../../../actions/simulateur.action";
import {useDispatch} from 'react-redux';
import {styles} from "../../../stylesheets/simulateurs.css";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import ButtonDroitpartage from "../../../components/buttons/button.droitpartage";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import SelectDepartement from "../../../components/selects/select.departement";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";

// Partage de biens indivis, régime de FAVEUR - Droit de partage à 2.50% (en cas de partage réalisé après une succession ou à 1,10% (en cas d'indivision de partenaires PACSES avant ou pendant le PACS, en cas d'indivision entre époux séparés de biens, en cas de partage réalisé après divorce)
// ID 60

function Simulateur_A1_21(props, simulateur, isParent, departements) {

    let currUser = JSON.parse(localStorage.getItem("efluser"));
    let currUserDep = 0
    if (currUser.societe_zip.startsWith("97")){
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 3);
        currUserDep = parseInt(trimedZip)
    } else {
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 2);
        currUserDep = parseInt(trimedZip)
    }

    const dispatch = useDispatch();
    const [departement, setDepartement] = useState(0)
    const [droit_partage_reduit, setDroitPartage] = useState(0)
    const [masse_brute, setMasseBrute] = useState(0)
    const [actif_net, setActifNet] = useState(0)
    const [valeur_cumulee, setValeurCumulee] = useState(0)

    const handleChange = async (setter, value) => {
        if (setter === setDepartement) {
            setDepartement(parseInt(value))
        } else setter(value);
        setInRedux(setter, value)
    };

    const handleChangeRemarque = async (value) => {
        localStorage.setItem("remarque", value)
    }

    function setInRedux(setter, value) {
        if (props.isParent) {

            let object = {
                "simulateurid": props.simulateur.id,
                "departementid": 0,
                "bureauid": parseInt(currUser.id),
                "inputs": {}
            };

            if (setter === setDepartement) {
                Object.assign(object['inputs'], {'droit_partage_reduit': parseInt(droit_partage_reduit)})
                Object.assign(object['inputs'], {'masse_brute': parseInt(masse_brute)})
                Object.assign(object['inputs'], {'actif_net': parseInt(actif_net)})
                Object.assign(object['inputs'], {'valeur_cumulee': parseInt(valeur_cumulee)})
                object.departementid = parseInt(value)
            }

            if (setter === setDroitPartage) {
                Object.assign(object['inputs'], {'droit_partage_reduit': parseInt(value)})
                Object.assign(object['inputs'], {'masse_brute': parseInt(masse_brute)})
                Object.assign(object['inputs'], {'actif_net': parseInt(actif_net)})
                Object.assign(object['inputs'], {'valeur_cumulee': parseInt(valeur_cumulee)})
                object.departementid = parseInt(value)
            }

            if (setter === setMasseBrute) {
                Object.assign(object['inputs'], {'droit_partage_reduit': parseInt(droit_partage_reduit)})
                Object.assign(object['inputs'], {'masse_brute': parseInt(value)})
                Object.assign(object['inputs'], {'actif_net': parseInt(actif_net)})
                Object.assign(object['inputs'], {'valeur_cumulee': parseInt(valeur_cumulee)})
                object.departementid = parseInt(departement)
            }

            if (setter === setActifNet) {
                Object.assign(object['inputs'], {'droit_partage_reduit': parseInt(droit_partage_reduit)})
                Object.assign(object['inputs'], {'masse_brute': parseInt(masse_brute)})
                Object.assign(object['inputs'], {'actif_net': parseInt(value)})
                Object.assign(object['inputs'], {'valeur_cumulee': parseInt(valeur_cumulee)})
                object.departementid = parseInt(departement)
            }

            if (setter === setValeurCumulee) {
                Object.assign(object['inputs'], {'droit_partage_reduit': parseInt(droit_partage_reduit)})
                Object.assign(object['inputs'], {'masse_brute': parseInt(masse_brute)})
                Object.assign(object['inputs'], {'actif_net': parseInt(actif_net)})
                Object.assign(object['inputs'], {'valeur_cumulee': parseInt(value)})
                object.departementid = parseInt(departement)
            }

            dispatch(setSimulateurObject(object));

        } else {

            // process child simu

        }
    }


    let arrayValues = [
        {
            "value": 0,
            "name": "Droit de partage simple (2,50%)"
        },
        {
            "value": 1,
            "name": "Droit de partage réduit (1,10%)"
        }
    ]
    return (
        <div style={styles.container}>
            <div style={styles.card}>
                <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id}/>
               
                <TextfieldRemarques handleChange={handleChangeRemarque}/>

                {props?.isParent === true && props.departements !== "" ?
                    <div style={styles.cardRow}>
                        <div style={styles.cardElemVerticalyCenter}>
                            Département :
                        </div>

                        <SelectDepartement items={props.departements} nameField={"name"} defaultValue={currUserDep} optionValue={"id"}
                                  handleChange={handleChange} setter={setDepartement}/>
                    </div>
                    :
                    <></>
                }

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Quel est le droit de partage concerné ?
                    </div>
                    <ButtonDroitpartage handleChange={handleChange} setter={setDroitPartage} defaultValue={0}/>
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Quelle est la masse brute partagée (immeubles et meubles) ou Actif Brut ?
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Actif brut"}
                        setter={setMasseBrute}
                    />
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Quel est l’Actif Net partagé ?
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Actif net"}
                        setter={setActifNet}
                    />
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Quelle est la valeur cumulée des biens immobiliers partagés ?
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Valeur"}
                        setter={setValeurCumulee}
                    />
                </div>
            </div>
        </div>
    )
}

export default Simulateur_A1_21;
