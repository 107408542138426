import React, { useEffect, useState } from "react";
import { setSimulateurObject } from "../../../actions/simulateur.action";
import { useDispatch } from 'react-redux';
import { styles } from "../../../stylesheets/simulateurs.css";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import SelectDepartement from "../../../components/selects/select.departement";
import ButtonYesno from "../../../components/buttons/button.yesno";
import SelectCommon from "../../../components/selects/select.common";
import DmtgMain from "../../dmtg/DmtgMain";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";

// Donation de parts de société non cotée
// Id simulateur 57, A2-11bis
// simulateurs associés : réserve d'usufruit et dmtg immo


function Simulateur_57(props, simulateur, isParent, departements) {
    const dispatch = useDispatch();

    let currUser = JSON.parse(localStorage.getItem("efluser"));
    let currUserDep = 0
    if (currUser.societe_zip.startsWith("97")) {
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 3);
        currUserDep = parseInt(trimedZip)
    } else {
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 2);
        currUserDep = parseInt(trimedZip)
    }

    const [simulateurForDMTG, setSimulateurForDMTG] = useState({
        id: 109,
        name: "DMTG - Donation de biens immobiliers 1 à 9 personnes"
    })

    const [reserve_usufruit, setUsufruit] = useState(0)
    const [age_usufruitier_1, setAgeUsufruit1] = useState(0)
    const [age_usufruitier_2, setAgeUsufruit2] = useState(0)
    const [departement, setDepartement] = useState(0)
    const [nombre_donateurs, setDonateur] = useState(1)
    const [montant_donateur_1, setDonateur1] = useState(0)
    const [montant_donateur_2, setDonateur2] = useState(0)
    const [reversion_usufruit, setReversUsufruit] = useState(0)
    const [DMTG, setDMTG] = useState(0)
    const [responseDMTG, setResponseDMTG] = useState(null)

    const handleChange = async (setter, value) => {
        if (setter === setDepartement) {
            setDepartement(parseInt(value))
        } else setter(value);
        setInRedux(setter, value)
    };

    const handleChangeRemarque = async (value) => {
        localStorage.setItem("remarque", value)
    }

    function setInRedux(setter, value) {
        if (props.isParent) {

            let object = {
                "simulateurid": props.simulateur.id,
                "departementid": 0,
                "bureauid": parseInt(currUser.id),
                "inputs": {},
                "DMTG": {}
            };

            if (setter === setUsufruit) {
                Object.assign(object['inputs'], { 'reserve_usufruit': parseInt(value) })
                Object.assign(object['inputs'], { 'age_usufruitier_1': parseInt(age_usufruitier_1) })
                Object.assign(object['inputs'], { 'age_usufruitier_2': parseInt(age_usufruitier_2) })
                Object.assign(object['inputs'], { 'nombre_donateurs': parseInt(nombre_donateurs) })
                Object.assign(object['inputs'], { 'montant_donateur_1': parseInt(montant_donateur_1) })
                Object.assign(object['inputs'], { 'montant_donateur_2': parseInt(montant_donateur_2) })
                Object.assign(object['inputs'], { 'reversion_usufruit': parseInt(reversion_usufruit) })
                Object.assign(object['inputs'], { 'DMTG': parseInt(DMTG) })
                object.departementid = parseInt(departement)
                object.DMTG = responseDMTG

            }

            if (setter === setAgeUsufruit1) {
                Object.assign(object['inputs'], { 'reserve_usufruit': parseInt(reserve_usufruit) })
                Object.assign(object['inputs'], { 'age_usufruitier_1': parseInt(value) })
                Object.assign(object['inputs'], { 'age_usufruitier_2': parseInt(age_usufruitier_2) })
                Object.assign(object['inputs'], { 'nombre_donateurs': parseInt(nombre_donateurs) })
                Object.assign(object['inputs'], { 'montant_donateur_1': parseInt(montant_donateur_1) })
                Object.assign(object['inputs'], { 'montant_donateur_2': parseInt(montant_donateur_2) })
                Object.assign(object['inputs'], { 'reversion_usufruit': parseInt(reversion_usufruit) })
                Object.assign(object['inputs'], { 'DMTG': parseInt(DMTG) })
                object.departementid = parseInt(departement)
                object.DMTG = responseDMTG

            }

            if (setter === setAgeUsufruit2) {
                Object.assign(object['inputs'], { 'reserve_usufruit': parseInt(reserve_usufruit) })
                Object.assign(object['inputs'], { 'age_usufruitier_1': parseInt(age_usufruitier_1) })
                Object.assign(object['inputs'], { 'age_usufruitier_2': parseInt(value) })
                Object.assign(object['inputs'], { 'nombre_donateurs': parseInt(nombre_donateurs) })
                Object.assign(object['inputs'], { 'montant_donateur_1': parseInt(montant_donateur_1) })
                Object.assign(object['inputs'], { 'montant_donateur_2': parseInt(montant_donateur_2) })
                Object.assign(object['inputs'], { 'reversion_usufruit': parseInt(reversion_usufruit) })
                Object.assign(object['inputs'], { 'DMTG': parseInt(DMTG) })
                object.departementid = parseInt(value)
                object.DMTG = responseDMTG

            }

            if (setter === setDonateur) {
                Object.assign(object['inputs'], { 'reserve_usufruit': parseInt(reserve_usufruit) })
                Object.assign(object['inputs'], { 'age_usufruitier_1': parseInt(age_usufruitier_1) })
                Object.assign(object['inputs'], { 'age_usufruitier_2': parseInt(age_usufruitier_2) })
                Object.assign(object['inputs'], { 'nombre_donateurs': parseInt(value) })
                Object.assign(object['inputs'], { 'montant_donateur_1': parseInt(montant_donateur_1) })
                Object.assign(object['inputs'], { 'montant_donateur_2': parseInt(montant_donateur_2) })
                Object.assign(object['inputs'], { 'reversion_usufruit': parseInt(reversion_usufruit) })
                Object.assign(object['inputs'], { 'DMTG': parseInt(DMTG) })
                object.departementid = parseInt(departement)
                object.DMTG = responseDMTG

            }

            if (setter === setDonateur1) {
                Object.assign(object['inputs'], { 'reserve_usufruit': parseInt(reserve_usufruit) })
                Object.assign(object['inputs'], { 'age_usufruitier_1': parseInt(age_usufruitier_1) })
                Object.assign(object['inputs'], { 'age_usufruitier_2': parseInt(age_usufruitier_2) })
                Object.assign(object['inputs'], { 'nombre_donateurs': parseInt(nombre_donateurs) })
                Object.assign(object['inputs'], { 'montant_donateur_1': parseInt(value) })
                Object.assign(object['inputs'], { 'montant_donateur_2': parseInt(montant_donateur_2) })
                Object.assign(object['inputs'], { 'reversion_usufruit': parseInt(reversion_usufruit) })
                Object.assign(object['inputs'], { 'DMTG': parseInt(DMTG) })
                object.departementid = parseInt(departement)
                object.DMTG = responseDMTG

            }

            if (setter === setDonateur2) {
                Object.assign(object['inputs'], { 'reserve_usufruit': parseInt(reserve_usufruit) })
                Object.assign(object['inputs'], { 'age_usufruitier_1': parseInt(age_usufruitier_1) })
                Object.assign(object['inputs'], { 'age_usufruitier_2': parseInt(age_usufruitier_2) })
                Object.assign(object['inputs'], { 'nombre_donateurs': parseInt(nombre_donateurs) })
                Object.assign(object['inputs'], { 'montant_donateur_1': parseInt(montant_donateur_1) })
                Object.assign(object['inputs'], { 'montant_donateur_2': parseInt(value) })
                Object.assign(object['inputs'], { 'reversion_usufruit': parseInt(reversion_usufruit) })
                Object.assign(object['inputs'], { 'DMTG': parseInt(DMTG) })
                object.departementid = parseInt(departement)
                object.DMTG = responseDMTG

            }

            if (setter === setReversUsufruit) {
                Object.assign(object['inputs'], { 'reserve_usufruit': parseInt(reserve_usufruit) })
                Object.assign(object['inputs'], { 'age_usufruitier_1': parseInt(age_usufruitier_1) })
                Object.assign(object['inputs'], { 'age_usufruitier_2': parseInt(age_usufruitier_2) })
                Object.assign(object['inputs'], { 'nombre_donateurs': parseInt(nombre_donateurs) })
                Object.assign(object['inputs'], { 'montant_donateur_1': parseInt(montant_donateur_1) })
                Object.assign(object['inputs'], { 'montant_donateur_2': parseInt(montant_donateur_2) })
                Object.assign(object['inputs'], { 'reversion_usufruit': parseInt(value) })
                Object.assign(object['inputs'], { 'DMTG': parseInt(DMTG) })
                object.departementid = parseInt(departement)
                object.DMTG = responseDMTG

            }

            if (setter === setDMTG) {
                if (value === 0) {
                    setResponseDMTG([])
                    Object.assign(object['inputs'], { 'reserve_usufruit': parseInt(reserve_usufruit) })
                    Object.assign(object['inputs'], { 'age_usufruitier_1': parseInt(age_usufruitier_1) })
                    Object.assign(object['inputs'], { 'age_usufruitier_2': parseInt(age_usufruitier_2) })
                    Object.assign(object['inputs'], { 'nombre_donateurs': parseInt(nombre_donateurs) })
                    Object.assign(object['inputs'], { 'montant_donateur_1': parseInt(montant_donateur_1) })
                    Object.assign(object['inputs'], { 'montant_donateur_2': parseInt(montant_donateur_2) })
                    Object.assign(object['inputs'], { 'reversion_usufruit': parseInt(reversion_usufruit) })
                    Object.assign(object['inputs'], { 'DMTG': parseInt(value) })
                    object.DMTG = []
                    object.departementid = parseInt(departement)
                } else {
                    Object.assign(object['inputs'], { 'reserve_usufruit': parseInt(reserve_usufruit) })
                    Object.assign(object['inputs'], { 'age_usufruitier_1': parseInt(age_usufruitier_1) })
                    Object.assign(object['inputs'], { 'age_usufruitier_2': parseInt(age_usufruitier_2) })
                    Object.assign(object['inputs'], { 'nombre_donateurs': parseInt(nombre_donateurs) })
                    Object.assign(object['inputs'], { 'montant_donateur_1': parseInt(montant_donateur_1) })
                    Object.assign(object['inputs'], { 'montant_donateur_2': parseInt(montant_donateur_2) })
                    Object.assign(object['inputs'], { 'reversion_usufruit': parseInt(reversion_usufruit) })
                    Object.assign(object['inputs'], { 'DMTG': parseInt(value) })
                    object.DMTG = responseDMTG
                    object.departementid = parseInt(departement)
                }
            }

            if (setter === setResponseDMTG) {
                Object.assign(object['inputs'], { 'reserve_usufruit': parseInt(reserve_usufruit) })
                Object.assign(object['inputs'], { 'age_usufruitier_1': parseInt(age_usufruitier_1) })
                Object.assign(object['inputs'], { 'age_usufruitier_2': parseInt(age_usufruitier_2) })
                Object.assign(object['inputs'], { 'nombre_donateurs': parseInt(nombre_donateurs) })
                Object.assign(object['inputs'], { 'montant_donateur_1': parseInt(montant_donateur_1) })
                Object.assign(object['inputs'], { 'montant_donateur_2': parseInt(montant_donateur_2) })
                Object.assign(object['inputs'], { 'reversion_usufruit': parseInt(reversion_usufruit) })
                Object.assign(object['inputs'], { 'DMTG': parseInt(DMTG) })
                Object.assign(object['inputs'], {'result': 1})
                object.DMTG = value
                object.departementid = parseInt(departement)
            }

            dispatch(setSimulateurObject(object));

        } else {

            // process child simu

        }
    }

    const nbDonateurs = [
        {
            id: 1,
            name: "Un donateur"
        },
        {
            id: 2,
            name: "Deux donateurs"
        }]

    return (
        <div style={styles.container}>
            <div style={styles.card}>
                <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id} />

                <TextfieldRemarques handleChange={handleChangeRemarque} />

                {props?.isParent === true && props.departements !== "" ?
                    <div style={styles.cardRow}>
                        <div style={styles.cardElemVerticalyCenter}>
                            Département :
                        </div>

                        <SelectDepartement items={props.departements} nameField={"name"} defaultValue={currUserDep} optionValue={"id"}
                            handleChange={handleChange} setter={setDepartement} />
                    </div>
                    :
                    <></>
                }

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Combien y a-t-il de donateurs ?
                    </div>
                    <SelectCommon items={nbDonateurs} defaultValue={1} nameField={"name"} optionValue={"id"}
                        handleChange={handleChange} setter={setDonateur} />
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Est-ce une donation avec réserve d'usufruit ?
                    </div>
                    <div>
                        <ButtonYesno handleChange={handleChange} setter={setUsufruit} fieldUndefined={false}
                            defaultValue={0} />
                    </div>
                </div>

                {parseInt(reserve_usufruit) === 1 ?
                    <div style={styles.cardRowChild}>

                        {

                            <div style={styles.cardRow}>
                                {
                                    nombre_donateurs == 2 ? <div style={styles.cardElemVerticalyCenter}>
                                        Indiquer l'age du donateur 1 :
                                    </div>
                                        : <div style={styles.cardElemVerticalyCenter}>
                                            Indiquer l'age du donateur :
                                        </div>
                                }
                                <TextfieldNumber
                                    handleChange={handleChange}
                                    type={"number"}
                                    placeholder={"Indiquer l'age"}
                                    setter={setAgeUsufruit1}
                                />
                            </div>

                        }

                        {
                            parseInt(nombre_donateurs) === 2 ?
                                <div style={styles.cardRow}>
                                    <div style={styles.cardElemVerticalyCenter}>
                                        Indiquer l'age du donateur 2 :
                                    </div>
                                    <TextfieldNumber
                                        handleChange={handleChange}
                                        type={"number"}
                                        placeholder={"Indiquer l'age"}
                                        setter={setAgeUsufruit2}
                                    />
                                </div>
                                :
                                <></>
                        }

                    </div>
                    :
                    <></>}



                <div style={styles.cardRow}>
                    {
                        nombre_donateurs == 2 ? <div style={styles.cardElemVerticalyCenter}>
                            Quelle est la somme donnée en pleine propriété par le donateur 1 ?
                        </div>
                            : <div style={styles.cardElemVerticalyCenter}>
                                Quelle est la somme donnée en pleine propriété ?
                            </div>
                    }
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer le montant"}
                        setter={setDonateur1}
                    />
                </div>

                {
                    parseInt(nombre_donateurs) === 2 ?
                        <div style={styles.cardRow}>
                            <div style={styles.cardElemVerticalyCenter}>
                                Quelle est la somme donnée en pleine propriété par le donateur 2 ?
                            </div>
                            <TextfieldNumber
                                handleChange={handleChange}
                                type={"number"}
                                placeholder={"Indiquer le montant"}
                                setter={setDonateur2}
                            />
                        </div>
                        :
                        <></>
                }

                {
                    parseInt(reserve_usufruit) === 1 ?
                        <div style={styles.cardRow}>
                            <div style={styles.cardElemVerticalyCenter}>
                                Y’a-t-il une clause de réversion d’usufruit ?
                            </div>
                            <div>
                                <ButtonYesno handleChange={handleChange} setter={setReversUsufruit} fieldUndefined={false}
                                    defaultValue={0} />
                            </div>
                        </div>
                        :
                        <></>
                }

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Souhaitez-vous faire le calcul des DMTG ?
                    </div>
                    <div>
                        <ButtonYesno handleChange={handleChange} setter={setDMTG} fieldUndefined={false}
                            defaultValue={0} />
                    </div>
                </div>

                {
                    DMTG ?
                        <DmtgMain
                            callSinceSimu={true}
                            simulateur={simulateurForDMTG}
                            setter={setResponseDMTG}
                            donateurs={parseInt(nombre_donateurs)}
                            handleChange={handleChange}
                            calculateDMTGState={props.calculateDMTGState}
                        />
                        :
                        <></>
                }

            </div>

        </div>
    )
}

export default Simulateur_57;
