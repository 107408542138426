import React, {useEffect, useState} from "react";
import {setSimulateurObject} from "../../../actions/simulateur.action";
import {useDispatch} from 'react-redux';
import {styles} from "../../../stylesheets/simulateurs.css";
import ButtonTextCautio from "../../../components/buttons/button.text.cautio";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import SelectDepartement from "../../../components/selects/select.departement";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";

// Cautionnement pour prêts sans garantie hypothécaire (caution personnelle)
// id simu 89

function Simulateur_A2_15(props, simulateur, isParent, departementId, ...dependances) {
    const dispatch = useDispatch();

    let currUser = JSON.parse(localStorage.getItem("efluser"));
    let currUserDep = 0
    if (currUser.societe_zip.startsWith("97")){
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 3);
        currUserDep = parseInt(trimedZip)
    } else {
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 2);
        currUserDep = parseInt(trimedZip)
    }

    const currentIdSimu = 89
    const [departement, setDepartement] = useState(0)
    const [cautionnementRealise, setCautionnementRealise] = useState(0)
    const [montantCautionnement, setMontantCautionnement] = useState(0)

    const handleChange = async (setter, value) => {
        if (setter === setDepartement) {
            setDepartement(parseInt(value))
        } else setter(value);

        setInRedux(setter, value)
    };

    const handleChangeRemarque = async (value) => {
        localStorage.setItem("remarque", value)
    }

    function setInRedux(setter, value) {
        let object = {
            "simulateurid": currentIdSimu,
            "departementid": 0,
            "bureauid": parseInt(currUser.id),
            "inputs": {}
        };

        if (setter === setCautionnementRealise) {
            Object.assign(object['inputs'], {'cautionnement_realise': parseInt(value)})
            Object.assign(object['inputs'], {'montant_cautionnement': parseInt(montantCautionnement)})
            object.departementid = parseInt(departement)
        }

        if (setter === setMontantCautionnement) {
            Object.assign(object['inputs'], {'montant_cautionnement': parseInt(value)})
            Object.assign(object['inputs'], {'cautionnement_realise': parseInt(cautionnementRealise)})
            object.departementid = parseInt(departement)
        }

        if (setter === setDepartement) {
            Object.assign(object['inputs'], {'montant_cautionnement': parseInt(montantCautionnement)})
            Object.assign(object['inputs'], {'cautionnement_realise': parseInt(cautionnementRealise)})
            object.departementid = parseInt(value)
        }

        if (props.isParent) {
            dispatch(setSimulateurObject(object));
        } else {
            object.departementid = parseInt(props.departementId)
            props.dependances(object)
        }
    }

    return (
        <>
            {props?.isParent === true && props.departements !== "" ?
                <div style={styles.container}>
                    <div style={styles.card}>

                        <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id}/>

                        <TextfieldRemarques handleChange={handleChangeRemarque}/>

                        {props?.isParent === true && props.departements !== "" ?
                            <div style={styles.cardRow}>
                                <div style={styles.cardElemVerticalyCenter}>
                                    Département :
                                </div>

                                <SelectDepartement items={props.departements} nameField={"name"} defaultValue={currUserDep} optionValue={"id"}
                                      handleChange={handleChange} setter={setDepartement}/>
                            </div>
                         :
                            <></>
                        }

                        <div style={styles.cardRow}>
                            <div style={styles.cardElemVerticalyCenter}>
                            L'acte de cautionnement est réalisé :
                            </div>
                            <ButtonTextCautio handleChange={handleChange} setter={setCautionnementRealise} defaultValue={0}/>
                        </div>

                        <div style={styles.cardRow}>
                            <div style={styles.cardElemVerticalyCenter}>
                            Indiquer le montant du cautionnement :
                            </div>
                            <TextfieldNumber
                                handleChange={handleChange}
                                type={"number"}
                                placeholder={"Indiquer le montant"}
                                setter={setMontantCautionnement}
                            />
                        </div>

                    </div>

                </div>
                :
                <div style={styles.cardRowChild}>
                    <div style={styles.cardRow}>
                        <div style={styles.cardElemVerticalyCenter}>
                            L'acte de cautionnement est réalisé :
                        </div>
                        <ButtonTextCautio handleChange={handleChange} setter={setCautionnementRealise} defaultValue={0}/>
                    </div>

                    <div style={styles.cardRow}>
                        <div style={styles.cardElemVerticalyCenter}>
                            Indiquer le montant du cautionnement :
                        </div>
                        <TextfieldNumber
                            handleChange={handleChange}
                            type={"number"}
                            placeholder={"Indiquer le montant"}
                            setter={setMontantCautionnement}
                        />
                    </div>

                </div>
            }
        </>
    )
}

export default Simulateur_A2_15;
