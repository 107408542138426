import React, {createRef, useEffect, useRef, useState} from 'react';
import Colors from "../../useclass/colors";
import useIsMountedRef from "../../utils/mountedRef";
import axios from "axios";
import {API_BASE_URL, PARTNER_JWT} from "../../config";
import {useSnackbar} from "notistack";
import Categoriesblock from "../../components/categoriesblock/categoriesblock";

function Categories(props) {
    const styles = {
        container: {
            padding: 0,
            margin: 0,
            width: "100vw",
            minHeight: "auto"
        },
        card: {
            backgroundColor: Colors.white,
            boxShadow: "2px 2px 1px rgba(0,0,0, 0.2)",
            borderRadius: 5,
            width: "90%",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 50,
            paddingBottom: 50
        },
        listOfBubble: {
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            justifyContent: "center",
            paddingTop: 30,
            marginBottom: 20
        },
        bubble: {
            backgroundColor: Colors.primaryBlue,
            color: "white",
            borderRadius: 20,
            paddingLeft: 15,
            paddingRight: 15,
            paddingTop: 5,
            paddingBottom: 5,
            marginLeft: 15,
            marginRight: 15,
            marginTop: 5,
            marginBottom: 5,
            cursor: "pointer"
        },
        messageLogo: {
            position: "fixed",
            right: 15,
            bottom: 90,
        }
    };

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const [open, setOpen] = useState(false);
    const [categories, setCategories] = useState({subcategories: []});
    const isMountedRef = useIsMountedRef();
    const myRefs = useRef([]);

    useEffect(() => {

        var config = {
            method: 'get',
            url:
                `${API_BASE_URL}/categories/${props.match.params.url_name}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${PARTNER_JWT}`,
            },
        };
        axios(config)
            .then((response) => {
                if (response.status === 200 && response.data != null) {
                    if (isMountedRef.current) {
                        handleChange(setCategories, response.data)
                    }
                } else {
                    enqueueSnackbar('Une erreur est survenue', {
                        variant: 'error'
                    });
                }

            });
    }, [isMountedRef])

    const handleChange = async (setter, value) => {
        await setter(value);
    };

    let handleScrollTo = (index) => {
        // Incase the ref supplied isn't ref.current
        const el = document.getElementById(index.toString())
        //console.log("el")
        //console.log(el)
        // Scroll the element into view
        el.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        })
    }

    for ( var i = 0 ; i < categories.subcategories.length ; i++) {
        if (categories.subcategories[i].id == 7 || categories.subcategories[i].id == 18) {
            categories.subcategories.splice(i, 1);
        }
    }

    return (
        <div style={styles.container}>
            <div style={styles.card}>
                <div style={styles.listOfBubble}>
                    {categories.subcategories.map((item, i) => (
                        <div style={styles.bubble}
                             onClick={() => {
                                 handleScrollTo(i)
                             }}>
                            {item.shortname}
                        </div>

                    ))}
                </div>

                {
                    categories.subcategories.map((value, i) => (
                        <div id={i} key={i}>
                            <Categoriesblock
                                title={value.name}
                                body={value.simulateur}
                            />
                        </div>
                        )
                    )
                }
            </div>
        </div>
    );
};

export default Categories;

