import React, {useEffect, useState} from "react";
import { setSimulateurObject } from "../../../actions/simulateur.action";
import { useDispatch } from 'react-redux';
import { styles } from "../../../stylesheets/simulateurs.css";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import SelectDepartement from "../../../components/selects/select.departement";
import ButtonYesno from "../../../components/buttons/button.yesno";
import SelectCommon from "../../../components/selects/select.common";
import DmtgMain from "../../dmtg/DmtgMain";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";
import { ToolTipPerso } from "../../../components/selects/tooltip_perso";

// Donation partage de bien immobilier ou mixte, conjonctive ou cumulative, en pleine propriété ou avec réserve d'usufruit, avec ou sans donations rapportées
// Id simulateur 49, A2-9bis
// simulateurs associés : réserve d'usufruit et dmtg donation

function Simulateur_49(props, simulateur, isParent, departements) {
    const dispatch = useDispatch();

    const currentIdSimu = 49
    let currUser = JSON.parse(localStorage.getItem("efluser"));
    let currUserDep = 0
    if (currUser.societe_zip.startsWith("97")) {
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 3);
        currUserDep = parseInt(trimedZip)
    } else {
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 2);
        currUserDep = parseInt(trimedZip)
    }

    const [simulateurForDMTG, setSimulateurForDMTG] = useState({
        id: 56,
        name: "DMTG - Donation 1 à 9 personnes"
    })

    const [reserve_usufruit, setUsufruit] = useState(0)
    const [age_usufruitier_1, setAgeUsufruit1] = useState(0)
    const [age_usufruitier_2, setAgeUsufruit2] = useState(0)
    const [departement, setDepartement] = useState(0)
    const [nombre_donateurs, setDonateur] = useState(1)
    const [montant_donateur_1, setDonateur1] = useState(0)
    const [montant_donateur_2, setDonateur2] = useState(0)
    const [donation_especes, setEspeces] = useState(0)
    const [montant_especes_1, setEspeces1] = useState(0)
    const [montant_especes_2, setEspeces2] = useState(0)
    const [rapports, setRapports] = useState(0)
    const [montant_rapport_1, setRapports1] = useState(0)
    const [montant_rapport_2, setRapports2] = useState(0)
    const [montant_rapport_especes_1, setRapportsEspeces1] = useState(0)
    const [montant_rapport_especes_2, setRapportsEspeces2] = useState(0)
    const [reversion_usufruit, setReversUsufruit] = useState(0)
    const [DMTG, setDMTG] = useState(0)
    const [responseDMTG, setResponseDMTG] = useState(null)

    const handleChange = async (setter, value) => {
        if (setter === setDepartement) {
            setDepartement(parseInt(value))
        } else setter(value);
        setInRedux(setter, value)
    };

    const handleChangeRemarque = async (value) => {
        localStorage.setItem("remarque", value)
    }

    function setInRedux(setter, value) {
        if (props.isParent) {

            let object = {
                "simulateurid": props.simulateur.id,
                "departementid": 0,
                "bureauid": parseInt(currUser.id),
                "inputs": {},
                "DMTG": {}
            };


            if (setter === setUsufruit) {
                Object.assign(object['inputs'], { 'reserve_usufruit': parseInt(value) })
                Object.assign(object['inputs'], { 'age_usufruitier_1': parseInt(age_usufruitier_1) })
                Object.assign(object['inputs'], { 'age_usufruitier_2': parseInt(age_usufruitier_2) })
                Object.assign(object['inputs'], { 'nombre_donateurs': parseInt(nombre_donateurs) })
                Object.assign(object['inputs'], { 'montant_donateur_1': parseInt(montant_donateur_1) })
                Object.assign(object['inputs'], { 'montant_donateur_2': parseInt(montant_donateur_2) })
                Object.assign(object['inputs'], { 'donation_especes': parseInt(donation_especes) })
                Object.assign(object['inputs'], { 'montant_especes_1': parseInt(montant_especes_1) })
                Object.assign(object['inputs'], { 'montant_especes_2': parseInt(montant_especes_2) })
                Object.assign(object['inputs'], { 'rapports': parseInt(rapports) })
                Object.assign(object['inputs'], { 'montant_rapport_1': parseInt(montant_rapport_1) })
                Object.assign(object['inputs'], { 'montant_rapport_2': parseInt(montant_rapport_2) })
                Object.assign(object['inputs'], { 'montant_rapport_especes_1': parseInt(montant_rapport_especes_1) })
                Object.assign(object['inputs'], { 'montant_rapport_especes_2': parseInt(montant_rapport_especes_2) })
                Object.assign(object['inputs'], { 'reversion_usufruit': parseInt(reversion_usufruit) })
                Object.assign(object['inputs'], { 'DMTG': parseInt(DMTG) })
                object.departementid = parseInt(departement)
                object.DMTG = responseDMTG
            }

            if (setter === setAgeUsufruit1) {
                Object.assign(object['inputs'], { 'reserve_usufruit': parseInt(reserve_usufruit) })
                Object.assign(object['inputs'], { 'age_usufruitier_1': parseInt(value) })
                Object.assign(object['inputs'], { 'age_usufruitier_2': parseInt(age_usufruitier_2) })
                Object.assign(object['inputs'], { 'nombre_donateurs': parseInt(nombre_donateurs) })
                Object.assign(object['inputs'], { 'montant_donateur_1': parseInt(montant_donateur_1) })
                Object.assign(object['inputs'], { 'montant_donateur_2': parseInt(montant_donateur_2) })
                Object.assign(object['inputs'], { 'donation_especes': parseInt(donation_especes) })
                Object.assign(object['inputs'], { 'montant_especes_1': parseInt(montant_especes_1) })
                Object.assign(object['inputs'], { 'montant_especes_2': parseInt(montant_especes_2) })
                Object.assign(object['inputs'], { 'rapports': parseInt(rapports) })
                Object.assign(object['inputs'], { 'montant_rapport_1': parseInt(montant_rapport_1) })
                Object.assign(object['inputs'], { 'montant_rapport_2': parseInt(montant_rapport_2) })
                Object.assign(object['inputs'], { 'montant_rapport_especes_1': parseInt(montant_rapport_especes_1) })
                Object.assign(object['inputs'], { 'montant_rapport_especes_2': parseInt(montant_rapport_especes_2) })
                Object.assign(object['inputs'], { 'reversion_usufruit': parseInt(reversion_usufruit) })
                Object.assign(object['inputs'], { 'DMTG': parseInt(DMTG) })
                object.departementid = parseInt(departement)
                object.DMTG = responseDMTG
            }

            if (setter === setAgeUsufruit2) {
                Object.assign(object['inputs'], { 'reserve_usufruit': parseInt(reserve_usufruit) })
                Object.assign(object['inputs'], { 'age_usufruitier_1': parseInt(age_usufruitier_1) })
                Object.assign(object['inputs'], { 'age_usufruitier_2': parseInt(value) })
                Object.assign(object['inputs'], { 'nombre_donateurs': parseInt(nombre_donateurs) })
                Object.assign(object['inputs'], { 'montant_donateur_1': parseInt(montant_donateur_1) })
                Object.assign(object['inputs'], { 'montant_donateur_2': parseInt(montant_donateur_2) })
                Object.assign(object['inputs'], { 'donation_especes': parseInt(donation_especes) })
                Object.assign(object['inputs'], { 'montant_especes_1': parseInt(montant_especes_1) })
                Object.assign(object['inputs'], { 'montant_especes_2': parseInt(montant_especes_2) })
                Object.assign(object['inputs'], { 'rapports': parseInt(rapports) })
                Object.assign(object['inputs'], { 'montant_rapport_1': parseInt(montant_rapport_1) })
                Object.assign(object['inputs'], { 'montant_rapport_2': parseInt(montant_rapport_2) })
                Object.assign(object['inputs'], { 'montant_rapport_especes_1': parseInt(montant_rapport_especes_1) })
                Object.assign(object['inputs'], { 'montant_rapport_especes_2': parseInt(montant_rapport_especes_2) })
                Object.assign(object['inputs'], { 'reversion_usufruit': parseInt(reversion_usufruit) })
                Object.assign(object['inputs'], { 'DMTG': parseInt(DMTG) })
                object.departementid = parseInt(departement)
                object.DMTG = responseDMTG

            }

            if (setter === setDepartement) {
                Object.assign(object['inputs'], { 'reserve_usufruit': parseInt(reserve_usufruit) })
                Object.assign(object['inputs'], { 'age_usufruitier_1': parseInt(age_usufruitier_1) })
                Object.assign(object['inputs'], { 'age_usufruitier_2': parseInt(age_usufruitier_2) })
                Object.assign(object['inputs'], { 'nombre_donateurs': parseInt(nombre_donateurs) })
                Object.assign(object['inputs'], { 'montant_donateur_1': parseInt(montant_donateur_1) })
                Object.assign(object['inputs'], { 'montant_donateur_2': parseInt(montant_donateur_2) })
                Object.assign(object['inputs'], { 'donation_especes': parseInt(donation_especes) })
                Object.assign(object['inputs'], { 'montant_especes_1': parseInt(montant_especes_1) })
                Object.assign(object['inputs'], { 'montant_especes_2': parseInt(montant_especes_2) })
                Object.assign(object['inputs'], { 'rapports': parseInt(rapports) })
                Object.assign(object['inputs'], { 'montant_rapport_1': parseInt(montant_rapport_1) })
                Object.assign(object['inputs'], { 'montant_rapport_2': parseInt(montant_rapport_2) })
                Object.assign(object['inputs'], { 'montant_rapport_especes_1': parseInt(montant_rapport_especes_1) })
                Object.assign(object['inputs'], { 'montant_rapport_especes_2': parseInt(montant_rapport_especes_2) })
                Object.assign(object['inputs'], { 'reversion_usufruit': parseInt(reversion_usufruit) })
                Object.assign(object['inputs'], { 'DMTG': parseInt(DMTG) })
                object.departementid = parseInt(value)
                object.DMTG = responseDMTG

            }

            if (setter === setDonateur) {
                Object.assign(object['inputs'], { 'reserve_usufruit': parseInt(reserve_usufruit) })
                Object.assign(object['inputs'], { 'age_usufruitier_1': parseInt(age_usufruitier_1) })
                Object.assign(object['inputs'], { 'age_usufruitier_2': parseInt(age_usufruitier_2) })
                Object.assign(object['inputs'], { 'nombre_donateurs': parseInt(value) })
                Object.assign(object['inputs'], { 'montant_donateur_1': parseInt(montant_donateur_1) })
                Object.assign(object['inputs'], { 'montant_donateur_2': parseInt(montant_donateur_2) })
                Object.assign(object['inputs'], { 'donation_especes': parseInt(donation_especes) })
                Object.assign(object['inputs'], { 'montant_especes_1': parseInt(montant_especes_1) })
                Object.assign(object['inputs'], { 'montant_especes_2': parseInt(montant_especes_2) })
                Object.assign(object['inputs'], { 'rapports': parseInt(rapports) })
                Object.assign(object['inputs'], { 'montant_rapport_1': parseInt(montant_rapport_1) })
                Object.assign(object['inputs'], { 'montant_rapport_2': parseInt(montant_rapport_2) })
                Object.assign(object['inputs'], { 'montant_rapport_especes_1': parseInt(montant_rapport_especes_1) })
                Object.assign(object['inputs'], { 'montant_rapport_especes_2': parseInt(montant_rapport_especes_2) })
                Object.assign(object['inputs'], { 'reversion_usufruit': parseInt(reversion_usufruit) })
                Object.assign(object['inputs'], { 'DMTG': parseInt(DMTG) })
                object.departementid = parseInt(departement)
                object.DMTG = responseDMTG
            }

            if (setter === setDonateur1) {
                Object.assign(object['inputs'], { 'reserve_usufruit': parseInt(reserve_usufruit) })
                Object.assign(object['inputs'], { 'age_usufruitier_1': parseInt(age_usufruitier_1) })
                Object.assign(object['inputs'], { 'age_usufruitier_2': parseInt(age_usufruitier_2) })
                Object.assign(object['inputs'], { 'nombre_donateurs': parseInt(nombre_donateurs) })
                Object.assign(object['inputs'], { 'montant_donateur_1': parseInt(value) })
                Object.assign(object['inputs'], { 'montant_donateur_2': parseInt(montant_donateur_2) })
                Object.assign(object['inputs'], { 'donation_especes': parseInt(donation_especes) })
                Object.assign(object['inputs'], { 'montant_especes_1': parseInt(montant_especes_1) })
                Object.assign(object['inputs'], { 'montant_especes_2': parseInt(montant_especes_2) })
                Object.assign(object['inputs'], { 'rapports': parseInt(rapports) })
                Object.assign(object['inputs'], { 'montant_rapport_1': parseInt(montant_rapport_1) })
                Object.assign(object['inputs'], { 'montant_rapport_2': parseInt(montant_rapport_2) })
                Object.assign(object['inputs'], { 'montant_rapport_especes_1': parseInt(montant_rapport_especes_1) })
                Object.assign(object['inputs'], { 'montant_rapport_especes_2': parseInt(montant_rapport_especes_2) })
                Object.assign(object['inputs'], { 'reversion_usufruit': parseInt(reversion_usufruit) })
                Object.assign(object['inputs'], { 'DMTG': parseInt(DMTG) })
                object.departementid = parseInt(departement)
                object.DMTG = responseDMTG
            }

            if (setter === setDonateur2) {
                Object.assign(object['inputs'], { 'reserve_usufruit': parseInt(reserve_usufruit) })
                Object.assign(object['inputs'], { 'age_usufruitier_1': parseInt(age_usufruitier_1) })
                Object.assign(object['inputs'], { 'age_usufruitier_2': parseInt(age_usufruitier_2) })
                Object.assign(object['inputs'], { 'nombre_donateurs': parseInt(nombre_donateurs) })
                Object.assign(object['inputs'], { 'montant_donateur_1': parseInt(montant_donateur_1) })
                Object.assign(object['inputs'], { 'montant_donateur_2': parseInt(value) })
                Object.assign(object['inputs'], { 'donation_especes': parseInt(donation_especes) })
                Object.assign(object['inputs'], { 'montant_especes_1': parseInt(montant_especes_1) })
                Object.assign(object['inputs'], { 'montant_especes_2': parseInt(montant_especes_2) })
                Object.assign(object['inputs'], { 'rapports': parseInt(rapports) })
                Object.assign(object['inputs'], { 'montant_rapport_1': parseInt(montant_rapport_1) })
                Object.assign(object['inputs'], { 'montant_rapport_2': parseInt(montant_rapport_2) })
                Object.assign(object['inputs'], { 'montant_rapport_especes_1': parseInt(montant_rapport_especes_1) })
                Object.assign(object['inputs'], { 'montant_rapport_especes_2': parseInt(montant_rapport_especes_2) })
                Object.assign(object['inputs'], { 'reversion_usufruit': parseInt(reversion_usufruit) })
                Object.assign(object['inputs'], { 'DMTG': parseInt(DMTG) })
                object.departementid = parseInt(departement)
                object.DMTG = responseDMTG

            }

            if (setter === setEspeces) {
                Object.assign(object['inputs'], { 'reserve_usufruit': parseInt(reserve_usufruit) })
                Object.assign(object['inputs'], { 'age_usufruitier_1': parseInt(age_usufruitier_1) })
                Object.assign(object['inputs'], { 'age_usufruitier_2': parseInt(age_usufruitier_2) })
                Object.assign(object['inputs'], { 'nombre_donateurs': parseInt(nombre_donateurs) })
                Object.assign(object['inputs'], { 'montant_donateur_1': parseInt(montant_donateur_1) })
                Object.assign(object['inputs'], { 'montant_donateur_2': parseInt(montant_donateur_2) })
                Object.assign(object['inputs'], { 'donation_especes': parseInt(value) })
                Object.assign(object['inputs'], { 'montant_especes_1': parseInt(montant_especes_1) })
                Object.assign(object['inputs'], { 'montant_especes_2': parseInt(montant_especes_2) })
                Object.assign(object['inputs'], { 'rapports': parseInt(rapports) })
                Object.assign(object['inputs'], { 'montant_rapport_1': parseInt(montant_rapport_1) })
                Object.assign(object['inputs'], { 'montant_rapport_2': parseInt(montant_rapport_2) })
                Object.assign(object['inputs'], { 'montant_rapport_especes_1': parseInt(montant_rapport_especes_1) })
                Object.assign(object['inputs'], { 'montant_rapport_especes_2': parseInt(montant_rapport_especes_2) })
                Object.assign(object['inputs'], { 'reversion_usufruit': parseInt(reversion_usufruit) })
                Object.assign(object['inputs'], { 'DMTG': parseInt(DMTG) })
                object.departementid = parseInt(departement)
                object.DMTG = responseDMTG
            }

            if (setter === setEspeces1) {
                Object.assign(object['inputs'], { 'reserve_usufruit': parseInt(reserve_usufruit) })
                Object.assign(object['inputs'], { 'age_usufruitier_1': parseInt(age_usufruitier_1) })
                Object.assign(object['inputs'], { 'age_usufruitier_2': parseInt(age_usufruitier_2) })
                Object.assign(object['inputs'], { 'nombre_donateurs': parseInt(nombre_donateurs) })
                Object.assign(object['inputs'], { 'montant_donateur_1': parseInt(montant_donateur_1) })
                Object.assign(object['inputs'], { 'montant_donateur_2': parseInt(montant_donateur_2) })
                Object.assign(object['inputs'], { 'donation_especes': parseInt(donation_especes) })
                Object.assign(object['inputs'], { 'montant_especes_1': parseInt(value) })
                Object.assign(object['inputs'], { 'montant_especes_2': parseInt(montant_especes_2) })
                Object.assign(object['inputs'], { 'rapports': parseInt(rapports) })
                Object.assign(object['inputs'], { 'montant_rapport_1': parseInt(montant_rapport_1) })
                Object.assign(object['inputs'], { 'montant_rapport_2': parseInt(montant_rapport_2) })
                Object.assign(object['inputs'], { 'montant_rapport_especes_1': parseInt(montant_rapport_especes_1) })
                Object.assign(object['inputs'], { 'montant_rapport_especes_2': parseInt(montant_rapport_especes_2) })
                Object.assign(object['inputs'], { 'reversion_usufruit': parseInt(reversion_usufruit) })
                Object.assign(object['inputs'], { 'DMTG': parseInt(DMTG) })
                object.departementid = parseInt(departement)
                object.DMTG = responseDMTG
            }

            if (setter === setEspeces2) {
                Object.assign(object['inputs'], { 'reserve_usufruit': parseInt(reserve_usufruit) })
                Object.assign(object['inputs'], { 'age_usufruitier_1': parseInt(age_usufruitier_1) })
                Object.assign(object['inputs'], { 'age_usufruitier_2': parseInt(age_usufruitier_2) })
                Object.assign(object['inputs'], { 'nombre_donateurs': parseInt(nombre_donateurs) })
                Object.assign(object['inputs'], { 'montant_donateur_1': parseInt(montant_donateur_1) })
                Object.assign(object['inputs'], { 'montant_donateur_2': parseInt(montant_donateur_2) })
                Object.assign(object['inputs'], { 'donation_especes': parseInt(donation_especes) })
                Object.assign(object['inputs'], { 'montant_especes_1': parseInt(montant_especes_1) })
                Object.assign(object['inputs'], { 'montant_especes_2': parseInt(value) })
                Object.assign(object['inputs'], { 'rapports': parseInt(rapports) })
                Object.assign(object['inputs'], { 'montant_rapport_1': parseInt(montant_rapport_1) })
                Object.assign(object['inputs'], { 'montant_rapport_2': parseInt(montant_rapport_2) })
                Object.assign(object['inputs'], { 'montant_rapport_especes_1': parseInt(montant_rapport_especes_1) })
                Object.assign(object['inputs'], { 'montant_rapport_especes_2': parseInt(montant_rapport_especes_2) })
                Object.assign(object['inputs'], { 'reversion_usufruit': parseInt(reversion_usufruit) })
                Object.assign(object['inputs'], { 'DMTG': parseInt(DMTG) })
                object.departementid = parseInt(departement)
                object.DMTG = responseDMTG
            }

            if (setter === setRapports) {
                Object.assign(object['inputs'], { 'reserve_usufruit': parseInt(reserve_usufruit) })
                Object.assign(object['inputs'], { 'age_usufruitier_1': parseInt(age_usufruitier_1) })
                Object.assign(object['inputs'], { 'age_usufruitier_2': parseInt(age_usufruitier_2) })
                Object.assign(object['inputs'], { 'nombre_donateurs': parseInt(nombre_donateurs) })
                Object.assign(object['inputs'], { 'montant_donateur_1': parseInt(montant_donateur_1) })
                Object.assign(object['inputs'], { 'montant_donateur_2': parseInt(montant_donateur_2) })
                Object.assign(object['inputs'], { 'donation_especes': parseInt(donation_especes) })
                Object.assign(object['inputs'], { 'montant_especes_1': parseInt(montant_especes_1) })
                Object.assign(object['inputs'], { 'montant_especes_2': parseInt(montant_especes_2) })
                Object.assign(object['inputs'], { 'rapports': parseInt(value) })
                Object.assign(object['inputs'], { 'montant_rapport_1': parseInt(montant_rapport_1) })
                Object.assign(object['inputs'], { 'montant_rapport_2': parseInt(montant_rapport_2) })
                Object.assign(object['inputs'], { 'montant_rapport_especes_1': parseInt(montant_rapport_especes_1) })
                Object.assign(object['inputs'], { 'montant_rapport_especes_2': parseInt(montant_rapport_especes_2) })
                Object.assign(object['inputs'], { 'reversion_usufruit': parseInt(reversion_usufruit) })
                Object.assign(object['inputs'], { 'DMTG': parseInt(DMTG) })
                object.departementid = parseInt(departement)
                object.DMTG = responseDMTG
            }

            if (setter === setRapports1) {
                Object.assign(object['inputs'], { 'reserve_usufruit': parseInt(reserve_usufruit) })
                Object.assign(object['inputs'], { 'age_usufruitier_1': parseInt(age_usufruitier_1) })
                Object.assign(object['inputs'], { 'age_usufruitier_2': parseInt(age_usufruitier_2) })
                Object.assign(object['inputs'], { 'nombre_donateurs': parseInt(nombre_donateurs) })
                Object.assign(object['inputs'], { 'montant_donateur_1': parseInt(montant_donateur_1) })
                Object.assign(object['inputs'], { 'montant_donateur_2': parseInt(montant_donateur_2) })
                Object.assign(object['inputs'], { 'donation_especes': parseInt(donation_especes) })
                Object.assign(object['inputs'], { 'montant_especes_1': parseInt(montant_especes_1) })
                Object.assign(object['inputs'], { 'montant_especes_2': parseInt(montant_especes_2) })
                Object.assign(object['inputs'], { 'rapports': parseInt(rapports) })
                Object.assign(object['inputs'], { 'montant_rapport_1': parseInt(value) })
                Object.assign(object['inputs'], { 'montant_rapport_2': parseInt(montant_rapport_2) })
                Object.assign(object['inputs'], { 'montant_rapport_especes_1': parseInt(montant_rapport_especes_1) })
                Object.assign(object['inputs'], { 'montant_rapport_especes_2': parseInt(montant_rapport_especes_2) })
                Object.assign(object['inputs'], { 'reversion_usufruit': parseInt(reversion_usufruit) })
                Object.assign(object['inputs'], { 'DMTG': parseInt(DMTG) })
                object.departementid = parseInt(departement)
                object.DMTG = responseDMTG

            }

            if (setter === setRapports2) {
                Object.assign(object['inputs'], { 'reserve_usufruit': parseInt(reserve_usufruit) })
                Object.assign(object['inputs'], { 'age_usufruitier_1': parseInt(age_usufruitier_1) })
                Object.assign(object['inputs'], { 'age_usufruitier_2': parseInt(age_usufruitier_2) })
                Object.assign(object['inputs'], { 'nombre_donateurs': parseInt(nombre_donateurs) })
                Object.assign(object['inputs'], { 'montant_donateur_1': parseInt(montant_donateur_1) })
                Object.assign(object['inputs'], { 'montant_donateur_2': parseInt(montant_donateur_2) })
                Object.assign(object['inputs'], { 'donation_especes': parseInt(donation_especes) })
                Object.assign(object['inputs'], { 'montant_especes_1': parseInt(montant_especes_1) })
                Object.assign(object['inputs'], { 'montant_especes_2': parseInt(montant_especes_2) })
                Object.assign(object['inputs'], { 'rapports': parseInt(rapports) })
                Object.assign(object['inputs'], { 'montant_rapport_1': parseInt(montant_rapport_1) })
                Object.assign(object['inputs'], { 'montant_rapport_2': parseInt(value) })
                Object.assign(object['inputs'], { 'montant_rapport_especes_1': parseInt(montant_rapport_especes_1) })
                Object.assign(object['inputs'], { 'montant_rapport_especes_2': parseInt(montant_rapport_especes_2) })
                Object.assign(object['inputs'], { 'reversion_usufruit': parseInt(reversion_usufruit) })
                Object.assign(object['inputs'], { 'DMTG': parseInt(DMTG) })
                object.departementid = parseInt(departement)
                object.DMTG = responseDMTG
            }

            if (setter === setRapportsEspeces1) {
                Object.assign(object['inputs'], { 'reserve_usufruit': parseInt(reserve_usufruit) })
                Object.assign(object['inputs'], { 'age_usufruitier_1': parseInt(age_usufruitier_1) })
                Object.assign(object['inputs'], { 'age_usufruitier_2': parseInt(age_usufruitier_2) })
                Object.assign(object['inputs'], { 'nombre_donateurs': parseInt(nombre_donateurs) })
                Object.assign(object['inputs'], { 'montant_donateur_1': parseInt(montant_donateur_1) })
                Object.assign(object['inputs'], { 'montant_donateur_2': parseInt(montant_donateur_2) })
                Object.assign(object['inputs'], { 'donation_especes': parseInt(donation_especes) })
                Object.assign(object['inputs'], { 'montant_especes_1': parseInt(montant_especes_1) })
                Object.assign(object['inputs'], { 'montant_especes_2': parseInt(montant_especes_2) })
                Object.assign(object['inputs'], { 'rapports': parseInt(rapports) })
                Object.assign(object['inputs'], { 'montant_rapport_1': parseInt(montant_rapport_1) })
                Object.assign(object['inputs'], { 'montant_rapport_2': parseInt(montant_rapport_2) })
                Object.assign(object['inputs'], { 'montant_rapport_especes_1': parseInt(value) })
                Object.assign(object['inputs'], { 'montant_rapport_especes_2': parseInt(montant_rapport_especes_2) })
                Object.assign(object['inputs'], { 'reversion_usufruit': parseInt(reversion_usufruit) })
                Object.assign(object['inputs'], { 'DMTG': parseInt(DMTG) })
                object.departementid = parseInt(departement)
                object.DMTG = responseDMTG
            }

            if (setter === setRapportsEspeces2) {
                Object.assign(object['inputs'], { 'reserve_usufruit': parseInt(reserve_usufruit) })
                Object.assign(object['inputs'], { 'age_usufruitier_1': parseInt(age_usufruitier_1) })
                Object.assign(object['inputs'], { 'age_usufruitier_2': parseInt(age_usufruitier_2) })
                Object.assign(object['inputs'], { 'nombre_donateurs': parseInt(nombre_donateurs) })
                Object.assign(object['inputs'], { 'montant_donateur_1': parseInt(montant_donateur_1) })
                Object.assign(object['inputs'], { 'montant_donateur_2': parseInt(montant_donateur_2) })
                Object.assign(object['inputs'], { 'donation_especes': parseInt(donation_especes) })
                Object.assign(object['inputs'], { 'montant_especes_1': parseInt(montant_especes_1) })
                Object.assign(object['inputs'], { 'montant_especes_2': parseInt(montant_especes_2) })
                Object.assign(object['inputs'], { 'rapports': parseInt(rapports) })
                Object.assign(object['inputs'], { 'montant_rapport_1': parseInt(montant_rapport_1) })
                Object.assign(object['inputs'], { 'montant_rapport_2': parseInt(montant_rapport_2) })
                Object.assign(object['inputs'], { 'montant_rapport_especes_1': parseInt(montant_rapport_especes_1) })
                Object.assign(object['inputs'], { 'montant_rapport_especes_2': parseInt(value) })
                Object.assign(object['inputs'], { 'reversion_usufruit': parseInt(reversion_usufruit) })
                Object.assign(object['inputs'], { 'DMTG': parseInt(DMTG) })
                object.departementid = parseInt(departement)
                object.DMTG = responseDMTG
            }

            if (setter === setReversUsufruit) {
                Object.assign(object['inputs'], { 'reserve_usufruit': parseInt(reserve_usufruit) })
                Object.assign(object['inputs'], { 'age_usufruitier_1': parseInt(age_usufruitier_1) })
                Object.assign(object['inputs'], { 'age_usufruitier_2': parseInt(age_usufruitier_2) })
                Object.assign(object['inputs'], { 'nombre_donateurs': parseInt(nombre_donateurs) })
                Object.assign(object['inputs'], { 'montant_donateur_1': parseInt(montant_donateur_1) })
                Object.assign(object['inputs'], { 'montant_donateur_2': parseInt(montant_donateur_2) })
                Object.assign(object['inputs'], { 'donation_especes': parseInt(donation_especes) })
                Object.assign(object['inputs'], { 'montant_especes_1': parseInt(montant_especes_1) })
                Object.assign(object['inputs'], { 'montant_especes_2': parseInt(montant_especes_2) })
                Object.assign(object['inputs'], { 'rapports': parseInt(rapports) })
                Object.assign(object['inputs'], { 'montant_rapport_1': parseInt(montant_rapport_1) })
                Object.assign(object['inputs'], { 'montant_rapport_2': parseInt(montant_rapport_2) })
                Object.assign(object['inputs'], { 'montant_rapport_especes_1': parseInt(montant_rapport_especes_1) })
                Object.assign(object['inputs'], { 'montant_rapport_especes_2': parseInt(montant_rapport_especes_2) })
                Object.assign(object['inputs'], { 'reversion_usufruit': parseInt(value) })
                Object.assign(object['inputs'], { 'DMTG': parseInt(DMTG) })
                object.departementid = parseInt(departement)
                object.DMTG = responseDMTG
            }

            if (setter === setDMTG) {
                if (value === 0) {
                    setResponseDMTG([])
                    Object.assign(object['inputs'], {'reserve_usufruit': parseInt(reserve_usufruit)})
                    Object.assign(object['inputs'], {'age_usufruitier_1': parseInt(age_usufruitier_1)})
                    Object.assign(object['inputs'], {'age_usufruitier_2': parseInt(age_usufruitier_2)})
                    Object.assign(object['inputs'], {'nombre_donateurs': parseInt(nombre_donateurs)})
                    Object.assign(object['inputs'], {'montant_donateur_1': parseInt(montant_donateur_1)})
                    Object.assign(object['inputs'], {'montant_donateur_2': parseInt(montant_donateur_2)})
                    Object.assign(object['inputs'], {'donation_especes': parseInt(donation_especes)})
                    Object.assign(object['inputs'], {'montant_especes_1': parseInt(montant_especes_1)})
                    Object.assign(object['inputs'], {'montant_especes_2': parseInt(montant_especes_2)})
                    Object.assign(object['inputs'], {'rapports': parseInt(rapports)})
                    Object.assign(object['inputs'], {'montant_rapport_1': parseInt(montant_rapport_1)})
                    Object.assign(object['inputs'], {'montant_rapport_2': parseInt(montant_rapport_2)})
                    Object.assign(object['inputs'], {'montant_rapport_especes_1': parseInt(montant_rapport_especes_1)})
                    Object.assign(object['inputs'], {'montant_rapport_especes_2': parseInt(montant_rapport_especes_2)})
                    Object.assign(object['inputs'], {'reversion_usufruit': parseInt(reversion_usufruit)})
                    Object.assign(object['inputs'], {'DMTG': parseInt(value)})
                    object.departementid = parseInt(departement)
                    object.DMTG = []
                } else {
                    Object.assign(object['inputs'], {'reserve_usufruit': parseInt(reserve_usufruit)})
                    Object.assign(object['inputs'], {'age_usufruitier_1': parseInt(age_usufruitier_1)})
                    Object.assign(object['inputs'], {'age_usufruitier_2': parseInt(age_usufruitier_2)})
                    Object.assign(object['inputs'], {'nombre_donateurs': parseInt(nombre_donateurs)})
                    Object.assign(object['inputs'], {'montant_donateur_1': parseInt(montant_donateur_1)})
                    Object.assign(object['inputs'], {'montant_donateur_2': parseInt(montant_donateur_2)})
                    Object.assign(object['inputs'], {'donation_especes': parseInt(donation_especes)})
                    Object.assign(object['inputs'], {'montant_especes_1': parseInt(montant_especes_1)})
                    Object.assign(object['inputs'], {'montant_especes_2': parseInt(montant_especes_2)})
                    Object.assign(object['inputs'], {'rapports': parseInt(rapports)})
                    Object.assign(object['inputs'], {'montant_rapport_1': parseInt(montant_rapport_1)})
                    Object.assign(object['inputs'], {'montant_rapport_2': parseInt(montant_rapport_2)})
                    Object.assign(object['inputs'], {'montant_rapport_especes_1': parseInt(montant_rapport_especes_1)})
                    Object.assign(object['inputs'], {'montant_rapport_especes_2': parseInt(montant_rapport_especes_2)})
                    Object.assign(object['inputs'], {'reversion_usufruit': parseInt(reversion_usufruit)})
                    Object.assign(object['inputs'], {'DMTG': parseInt(value)})
                    object.departementid = parseInt(departement)
                    object.DMTG = responseDMTG
                }
            }

            if (setter === setResponseDMTG) {
                Object.assign(object['inputs'], { 'reserve_usufruit': parseInt(reserve_usufruit) })
                Object.assign(object['inputs'], { 'age_usufruitier_1': parseInt(age_usufruitier_1) })
                Object.assign(object['inputs'], { 'age_usufruitier_2': parseInt(age_usufruitier_2) })
                Object.assign(object['inputs'], { 'nombre_donateurs': parseInt(nombre_donateurs) })
                Object.assign(object['inputs'], { 'montant_donateur_1': parseInt(montant_donateur_1) })
                Object.assign(object['inputs'], { 'montant_donateur_2': parseInt(montant_donateur_2) })
                Object.assign(object['inputs'], { 'donation_especes': parseInt(donation_especes) })
                Object.assign(object['inputs'], { 'montant_especes_1': parseInt(montant_especes_1) })
                Object.assign(object['inputs'], { 'montant_especes_2': parseInt(montant_especes_2) })
                Object.assign(object['inputs'], { 'rapports': parseInt(rapports) })
                Object.assign(object['inputs'], { 'montant_rapport_1': parseInt(montant_rapport_1) })
                Object.assign(object['inputs'], { 'montant_rapport_2': parseInt(montant_rapport_2) })
                Object.assign(object['inputs'], { 'montant_rapport_especes_1': parseInt(montant_rapport_especes_1) })
                Object.assign(object['inputs'], { 'montant_rapport_especes_2': parseInt(montant_rapport_especes_2) })
                Object.assign(object['inputs'], { 'reversion_usufruit': parseInt(reversion_usufruit) })
                Object.assign(object['inputs'], { 'DMTG': parseInt(DMTG) })
                Object.assign(object['inputs'], {'result': 1})
                object.departementid = parseInt(departement)
                object.DMTG = value
            }

            dispatch(setSimulateurObject(object));

        } else {

            // process child simu

        }
    }

    const nbDonateurs = [
        {
            id: 1,
            name: "Cumulative si prédécès ou réalisée par un donateur"
        },
        {
            id: 2,
            name: "Conjonctive"
        }]

    return (
        <div style={styles.container}>
            <div style={styles.card}>
                <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id} />

                <TextfieldRemarques handleChange={handleChangeRemarque}/>

                {props?.isParent === true && props.departements !== "" ?
                    <div style={styles.cardRow}>
                        <div style={styles.cardElemVerticalyCenter}>
                            Département :
                        </div>

                        <SelectDepartement items={props.departements} nameField={"name"} defaultValue={currUserDep} optionValue={"id"}
                            handleChange={handleChange} setter={setDepartement} />
                    </div>
                    :
                    <></>
                }

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Est-ce une donation :
                    </div>
                    <SelectCommon items={nbDonateurs} defaultValue={1} nameField={"name"} optionValue={"id"}
                        handleChange={handleChange} setter={setDonateur} />
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Est-ce une donation avec réserve d'usufruit ?
                    </div>
                    <div>
                        <ButtonYesno handleChange={handleChange} setter={setUsufruit} fieldUndefined={false}
                            defaultValue={0} />
                    </div>
                </div>

                {parseInt(reserve_usufruit) === 1 ?
                    <div style={styles.cardRowChild}>

                        {

                            <div style={styles.cardRow}>
                                {
                                    nombre_donateurs == 2 ? <div style={styles.cardElemVerticalyCenter}>
                                        Indiquer l'age du donateur 1 :
                                    </div>
                                        : <div style={styles.cardElemVerticalyCenter}>
                                            Indiquer l'age du donateur :
                                        </div>
                                }
                                <TextfieldNumber
                                    handleChange={handleChange}
                                    type={"number"}
                                    placeholder={"Indiquer l'age"}
                                    setter={setAgeUsufruit1}
                                />
                            </div>

                        }

                        {
                            parseInt(nombre_donateurs) === 2 ?
                                <div style={styles.cardRow}>
                                    <div style={styles.cardElemVerticalyCenter}>
                                        Indiquer l'age du donateur 2 :
                                    </div>
                                    <TextfieldNumber
                                        handleChange={handleChange}
                                        type={"number"}
                                        placeholder={"Indiquer l'age"}
                                        setter={setAgeUsufruit2}
                                    />
                                </div>
                                :
                                <></>
                        }

                    </div>
                    :
                    <></>}

                <div style={styles.cardRow}>
                    {
                        nombre_donateurs == 2 ? <div style={styles.cardElemVerticalyCenter}>
                            Quelle est la masse totale donnée (valeur Pleine-Propriété) et partagée (y compris les rapports) par le donateur 1 ?
                            <ToolTipPerso title={"Y compris le montant des espèces s'il y a lieu."} />
                        </div>
                            : <div style={styles.cardElemVerticalyCenter}>
                                Quelle est la masse totale donnée (valeur Pleine-Propriété) et partagée (y compris les rapports) ?
                                <ToolTipPerso title={"Y compris le montant des espèces s'il y a lieu."} />
                            </div>
                    }
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer le montant"}
                        setter={setDonateur1}
                    />
                </div>

                {
                    parseInt(nombre_donateurs) === 2 ?
                        <div style={styles.cardRow}>
                            <div style={styles.cardElemVerticalyCenter}>
                                Quelle est la masse totale donnée (valeur Pleine-Propriété) et partagée (y compris les rapports) donateur 2 ?
                                <ToolTipPerso title={"Y compris le montant des espèces s'il y a lieu."} />
                            </div>
                            <TextfieldNumber
                                handleChange={handleChange}
                                type={"number"}
                                placeholder={"Indiquer le montant"}
                                setter={setDonateur2}
                            />
                        </div>
                        :
                        <></>
                }

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Y a-t-il donation d'espèces ?
                    </div>
                    <div>
                        <ButtonYesno handleChange={handleChange} setter={setEspeces} fieldUndefined={false}
                            defaultValue={0} />
                    </div>
                </div>

                {parseInt(donation_especes) === 1 ?
                    <div style={styles.cardRowChild}>

                        {
                            <div style={styles.cardRow}>
                                {
                                    nombre_donateurs == 2 ? <div style={styles.cardElemVerticalyCenter}>
                                        Indiquer le montant des espèces données par le donateur 1 :
                                    </div>
                                        : <div style={styles.cardElemVerticalyCenter}>
                                            Indiquer le montant des espèces données :
                                        </div>
                                }
                                <TextfieldNumber
                                    handleChange={handleChange}
                                    type={"number"}
                                    placeholder={"Indiquer le montant"}
                                    setter={setEspeces1}
                                />
                            </div>

                        }

                        {
                            parseInt(nombre_donateurs) === 2 ?
                                <div style={styles.cardRow}>
                                    <div style={styles.cardElemVerticalyCenter}>
                                        Indiquer le montant des espèces données par le donateur 2 :
                                    </div>
                                    <TextfieldNumber
                                        handleChange={handleChange}
                                        type={"number"}
                                        placeholder={"Indiquer le montant"}
                                        setter={setEspeces2}
                                    />
                                </div>
                                :
                                <></>
                        }
                    </div>
                    :
                    <></>
                }

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Y a-t-il des donations à rapporter et à réincorporer ?
                    </div>
                    <div>
                        <ButtonYesno handleChange={handleChange} setter={setRapports} fieldUndefined={false}
                            defaultValue={0} />
                    </div>
                </div>

                {
                    parseInt(rapports) === 1 ?
                        <div style={styles.cardRowChild}>
                            {
                                <div style={styles.cardRow}>
                                    <div style={styles.cardElemVerticalyCenter}>
                                        {
                                            nombre_donateurs == 2 ? <div style={styles.cardElemVerticalyCenter}>
                                                Quel est le montant total des rapports du donateur 1 ?
                                            </div>
                                                : <div style={styles.cardElemVerticalyCenter}>
                                                    Quel est le montant total des rapports ?
                                                </div>
                                        }

                                    </div>
                                    <TextfieldNumber
                                        handleChange={handleChange}
                                        type={"number"}
                                        placeholder={"Indiquer le montant"}
                                        setter={setRapports1}
                                    />
                                </div>
                            }

                            {
                                <div style={styles.cardRow}>
                                    <div style={styles.cardElemVerticalyCenter}>
                                        Dont espèces :
                                    </div>
                                    <TextfieldNumber
                                        handleChange={handleChange}
                                        type={"number"}
                                        placeholder={"Indiquer le montant"}
                                        setter={setRapportsEspeces1}
                                    />
                                </div>
                            }

                            {
                                parseInt(nombre_donateurs) === 2 ?
                                    <div>
                                        {
                                            <div style={styles.cardRow}>
                                                <div style={styles.cardElemVerticalyCenter}>
                                                    <div style={styles.cardElemVerticalyCenter}>
                                                        Quel est le montant total des rapports du donateur 2 ?
                                                    </div>

                                                </div>
                                                <TextfieldNumber
                                                    handleChange={handleChange}
                                                    type={"number"}
                                                    placeholder={"Indiquer le montant"}
                                                    setter={setRapports2}
                                                />
                                            </div>}

                                        {<div style={styles.cardRow}>
                                            <div style={styles.cardElemVerticalyCenter}>
                                                Dont espèces :
                                            </div>
                                            <TextfieldNumber
                                                handleChange={handleChange}
                                                type={"number"}
                                                placeholder={"Indiquer le montant"}
                                                setter={setRapportsEspeces2}
                                            />
                                        </div>

                                        }
                                    </div>
                                    :
                                    <></>

                            }
                        </div>

                        :
                        <></>
                }

                {
                    parseInt(reserve_usufruit) === 1 ?
                        <div style={styles.cardRow}>
                            <div style={styles.cardElemVerticalyCenter}>
                                Y’a-t-il une clause de réversion d’usufruit ?
                            </div>
                            <div>
                                <ButtonYesno handleChange={handleChange} setter={setReversUsufruit} fieldUndefined={false}
                                    defaultValue={0} />
                            </div>
                        </div>
                        :
                        <></>
                }

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Souhaitez-vous faire le calcul des DMTG ?
                    </div>
                    <div>
                        <ButtonYesno handleChange={handleChange} setter={setDMTG} fieldUndefined={false}
                            defaultValue={0} />
                    </div>
                </div>


                {
                    DMTG ?
                        <DmtgMain
                            callSinceSimu={true}
                            simulateur={simulateurForDMTG}
                            setter={setResponseDMTG}
                            donateurs={parseInt(nombre_donateurs)}
                            handleChange={handleChange}
                            calculateDMTGState={props.calculateDMTGState}
                        />
                        :
                        <></>
                }

            </div>

        </div>
    )
}

export default Simulateur_49;
