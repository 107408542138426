import React, { useEffect, useState } from "react";
import { setSimulateurObject } from "../../../actions/simulateur.action";
import { useDispatch } from 'react-redux';
import { styles } from "../../../stylesheets/simulateurs.css";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import SelectCommon from "../../../components/selects/select.common";
import SelectDepartement from "../../../components/selects/select.departement";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";

// Calcul d'un montant d'usufruit
// Id simulateur 105

function Simulateur_105(props, simulateur, isParent, departements) {
    const dispatch = useDispatch();

    let currUser = JSON.parse(localStorage.getItem("efluser"));
    let currUserDep = 0
    if (currUser.societe_zip.startsWith("97")) {
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 3);
        currUserDep = parseInt(trimedZip)
    } else {
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 2);
        currUserDep = parseInt(trimedZip)
    }

    const [departement, setDepartement] = useState(1)
    const [nombre_donateurs, setDonateur] = useState(0)
    const [age_usufruitier_1, setUsu1] = useState(0)
    const [age_usufruitier_2, setUsu2] = useState(0)
    const [valeur_bien_1, setBien1] = useState(0)
    const [valeur_bien_2, setBien2] = useState(0)

    const handleChange = async (setter, value) => {
        if (setter === setDepartement) {
            setDepartement(parseInt(value))
        } else setter(value);
        setInRedux(setter, value)
    };

    const handleChangeRemarque = async (value) => {
        localStorage.setItem("remarque", value)
    }

    function setInRedux(setter, value) {
        if (props.isParent) {

            let object = {
                "simulateurid": props.simulateur.id,
                "bureauid": parseInt(currUser.id),
                "inputs": {}
            };

            if (setter === setDonateur) {
                Object.assign(object['inputs'], { 'nombre_donateurs': parseInt(value) })
                Object.assign(object['inputs'], { 'age_usufruitier_1': parseInt(age_usufruitier_1) })
                Object.assign(object['inputs'], { 'age_usufruitier_2': parseInt(age_usufruitier_2) })
                Object.assign(object['inputs'], { 'valeur_bien_1': parseInt(valeur_bien_1) })
                Object.assign(object['inputs'], { 'valeur_bien_2': parseInt(valeur_bien_2) })
                object.departementid = parseInt(departement)
            }

            if (setter === setUsu1) {
                Object.assign(object['inputs'], { 'nombre_donateurs': parseInt(nombre_donateurs) })
                Object.assign(object['inputs'], { 'age_usufruitier_1': parseInt(value) })
                Object.assign(object['inputs'], { 'age_usufruitier_2': parseInt(age_usufruitier_2) })
                Object.assign(object['inputs'], { 'valeur_bien_1': parseInt(valeur_bien_1) })
                Object.assign(object['inputs'], { 'valeur_bien_2': parseInt(valeur_bien_2) })
                object.departementid = parseInt(departement)
            }

            if (setter === setUsu2) {
                Object.assign(object['inputs'], { 'nombre_donateurs': parseInt(nombre_donateurs) })
                Object.assign(object['inputs'], { 'age_usufruitier_1': parseInt(age_usufruitier_1) })
                Object.assign(object['inputs'], { 'age_usufruitier_2': parseInt(value) })
                Object.assign(object['inputs'], { 'valeur_bien_1': parseInt(valeur_bien_1) })
                Object.assign(object['inputs'], { 'valeur_bien_2': parseInt(valeur_bien_2) })
                object.departementid = parseInt(departement)
            }

            if (setter === setBien1) {
                Object.assign(object['inputs'], { 'nombre_donateurs': parseInt(nombre_donateurs) })
                Object.assign(object['inputs'], { 'age_usufruitier_1': parseInt(age_usufruitier_1) })
                Object.assign(object['inputs'], { 'age_usufruitier_2': parseInt(age_usufruitier_2) })
                Object.assign(object['inputs'], { 'valeur_bien_1': parseInt(value) })
                Object.assign(object['inputs'], { 'valeur_bien_2': parseInt(valeur_bien_2) })
                object.departementid = parseInt(departement)
            }

            if (setter === setBien2) {
                Object.assign(object['inputs'], { 'nombre_donateurs': parseInt(nombre_donateurs) })
                Object.assign(object['inputs'], { 'age_usufruitier_1': parseInt(age_usufruitier_1) })
                Object.assign(object['inputs'], { 'age_usufruitier_2': parseInt(age_usufruitier_2) })
                Object.assign(object['inputs'], { 'valeur_bien_1': parseInt(valeur_bien_1) })
                Object.assign(object['inputs'], { 'valeur_bien_2': parseInt(value) })
                object.departementid = parseInt(departement)
            }

            dispatch(setSimulateurObject(object));

        } else {

            // process child simu

        }
    }

    const nbDonateur = [
        {
            id: 1,
            name: "Un donateur"
        },
        {
            id: 2,
            name: "Deux donateurs"
        }
    ]

    return (
        <div style={styles.container}>
            <div style={styles.card}>
                <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id} />

                <TextfieldRemarques handleChange={handleChangeRemarque} />

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Combien y’a-t-il de donateur ?
                    </div>
                    <SelectCommon items={nbDonateur} defaultValue={1} nameField={"name"} optionValue={"id"}
                        handleChange={handleChange} setter={setDonateur} />
                </div>

                <div style={styles.cardRow}>
                    {
                        nombre_donateurs == 2 ? <div style={styles.cardElemVerticalyCenter}>
                            Indiquer l'age du donateur 1 :
                        </div>
                            : <div style={styles.cardElemVerticalyCenter}>
                                Indiquer l'age du donateur :
                            </div>
                    }
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer l'age"}
                        setter={setUsu1}
                    />
                </div>


                {
                    parseInt(nombre_donateurs) === 2 ?
                        <div style={styles.cardRow}>
                            <div style={styles.cardElemVerticalyCenter}>
                                Indiquer l'age du donateur 2 :
                            </div>
                            <TextfieldNumber
                                handleChange={handleChange}
                                type={"number"}
                                placeholder={"Indiquer l'age"}
                                setter={setUsu2}
                            />
                        </div>
                        :
                        <></>
                }


                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Quelle est la valeur du bien donateur 1 ?
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer la valeur"}
                        setter={setBien1}
                    />
                </div>

                {
                    parseInt(nombre_donateurs) === 2 ?
                        <div style={styles.cardRow}>
                            <div style={styles.cardElemVerticalyCenter}>
                                Quelle est la valeur du bien donateur 2 ?
                            </div>
                            <TextfieldNumber
                                handleChange={handleChange}
                                type={"number"}
                                placeholder={"Indiquer la valeur"}
                                setter={setBien2}
                            />
                        </div>
                        :
                        <></>
                }
            </div>
        </div>
    )
}

export default Simulateur_105;
