import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import * as React from "react";
import moment from "moment";

import fontRobotoRegular from "../../assets/fonts/Roboto/Roboto-Regular.ttf";
import fontRobotoLight from "../../assets/fonts/Roboto/Roboto-Light.ttf";
import fontRobotoBold from "../../assets/fonts/Roboto/Roboto-Bold.ttf";

Font.register({ family: "RobotoRegular", src: fontRobotoRegular });
Font.register({ family: "RobotoLight", src: fontRobotoLight });
Font.register({ family: "RobotoBold", src: fontRobotoBold });

let newDate = new Date();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
let day = newDate.getDate();

if (month < 10) {
  month = "0" + month;
}

if (day < 10) {
  day = "0" + day;
}
const currentDate = `${day}/${month}/${year}`;

const repeatTaxeRow = 3;

const styles = StyleSheet.create({
  fontRegular: { fontFamily: "RobotoRegular" },
  fontLight: { fontFamily: "RobotoLight" },
  fontBold: { fontFamily: "RobotoBold" },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: "RobotoRegular",
    fontSize: 10,
  },
  headerRight: {
    marginLeft: "50%",
    marginTop: 25,
    marginRight: 40,
    fontSize: 12,
    textAlign: "right",
    lineHeight: 1.5,
    wordBreak: "keep-all",
  },
  headerName: {
    fontSize: 11,
    fontFamily: "RobotoBold",
    wordBreak: "keep-all",
  },
  headerAdress: {
    fontSize: 10,
    fontFamily: "RobotoRegular",
  },
  headerDate: {
    marginTop: 20,
    fontSize: 9.5,
    fontFamily: "RobotoRegular",
  },
  remarque: {
    border: "1px solid grey",
    borderRadius: 3,
    width: "80%",
    marginLeft: "10%",
    fontFamily: "RobotoRegular",
    fontSize: 10,
    marginTop: 25,
    lineHeight: 2,
  },
  remarqueText: {
    marginTop: 8,
    marginLeft: 5,
  },
  ttc: {
    minHeight: 100,
    height: 200,
    overflow: "auto",
    border: "1px solid grey",
    borderRadius: 3,
    width: "80%",
    marginLeft: "10%",
    fontFamily: "RobotoBold",
    fontSize: 14,
    marginTop: 15,
  },
  ttcInternCtn: {
    flex: 1,
    flexDirection: "column",
  },
  ttcLeft: {
    paddingTop: 10,
    paddingLeft: 15,
    paddingBottom: 10,
    width: "100%",
    borderBottom: "1px solid grey",
    backgroundColor: "rgba(94,145,182,0.8)",
  },
  othersResultsBlock: {
    padding: "15 0 10 15",
    width: "70%",
  },
  othersResults: {
    fontSize: 12,
    paddingTop: 15,
    paddingBottom: 15,
  },
  bodyFooterCtn: {
    width: "80%",
    marginLeft: "10%",
    // marginTop: 175,
    position: "absolute",
    bottom: 15,
    left: 0,
    right: 0,
  },
  bodyFooterBold: {
    fontFamily: "RobotoBold",
    fontSize: 8,
    marginTop: 10,
    textAlign: "justify",
  },
  bodyFooterLight: {
    fontFamily: "RobotoLight",
    fontSize: 6,
    marginTop: 5,
  },
  bodyFooterMapped: {
    fontSize: 8,
    marginTop: 4,
  },
  bodyFooterMappedTitle: {
    fontSize: 8,
    marginTop: 4,
    fontFamily: "RobotoBold",
  },
  rappel: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    columnGap: 100,
    width: "80%",
    height: 120,
    marginLeft: "10%",
    position: "absolute",
    bottom: "-60%",
    paddingRight: 20,
    paddingLeft: 20,
  },
  containerTitleSimu: {
    position: "relative",
    marginTop: 20,
    marginRight: "auto",
    marginLeft: "auto",
    width: "75%",
  },
  titleSimu: {
    fontSize: 14,
    textAlign: "center",
    fontFamily: "RobotoBold",
    wordBreak: "keep-all",
  },
  usuTitle: {
    color: "rgba(94,145,182,0.8)",
    fontSize: 13,
    paddingBottom: 5,
  },
  usuTitle2: {
    color: "rgba(94,145,182,0.8)",
    fontSize: 13,
    paddingBottom: 5,
    paddingTop: 15,
  },
  usuResults: {
    fontSize: 12,
    paddingTop: 5,
    paddingBottom: 5,
  },
});

const GenerateOthersPdfFile: React.FC<DocumentProps> = ({
  simulateurObject,
}) => {
  let remarque = localStorage.getItem("remarque");

  let currUser = JSON.parse(localStorage.getItem("efluser"));

  let requestSimu = JSON.parse(localStorage.getItem("requestSimu"));

  let nameSimu = localStorage.getItem("nameSimu");

  let result = simulateurObject["results"]["output"];
  //console.log(JSON.stringify(result))

  return (
    // <PDFViewer style={{width: "98vw", height: "98vh"}}>
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <View style={styles.headerRight}>
            <View style={styles.headerDate}>
              <Text>Le : {currentDate}</Text>
            </View>
          </View>
          <View style={styles.containerTitleSimu}>
            <Text style={styles.titleSimu}>{nameSimu}</Text>
          </View>
          <View style={styles.remarque}>
            <Text style={styles.remarqueText}>Remarques : {remarque}</Text>
          </View>

          <View style={styles.ttc}>
            <View style={styles.ttcInternCtn}>
              <Text style={styles.ttcLeft}>Résultat de votre simulation :</Text>

              <View style={styles.othersResultsBlock}>
                {result?.map((object, index) => {
                  //console.log("object : " + JSON.stringify(object))

                  if (result[index].key === "plus_value") {
                    return (
                      <Text style={styles.othersResults}>
                        Plus-Value :{" "}
                        {parseInt(result[index].value)
                          .toLocaleString("en-EN")
                          .replace(/,/g, " ")}{" "}
                        €
                      </Text>
                    );
                  } else if (result[index].key === "impot_revenu") {
                    return (
                      <Text style={styles.othersResults}>
                        Impôts sur le revenu :{" "}
                        {parseInt(result[index].value)
                          .toLocaleString("en-EN")
                          .replace(/,/g, " ")}{" "}
                        €
                      </Text>
                    );
                  } else if (result[index].key === "prelevements_sociaux") {
                    return (
                      <Text style={styles.othersResults}>
                        Prélèvements sociaux :{" "}
                        {parseInt(result[index].value)
                          .toLocaleString("en-EN")
                          .replace(/,/g, " ")}{" "}
                        €
                      </Text>
                    );
                  } else if (result[index].key === "taxe_additionnelle") {
                    return (
                      <Text style={styles.othersResults}>
                        Taxe additionnelle :{" "}
                        {parseInt(result[index].value)
                          .toLocaleString("en-EN")
                          .replace(/,/g, " ")}{" "}
                        €
                      </Text>
                    );
                  } else if (result[index].key === "total_impots") {
                    return (
                      <Text style={styles.othersResults}>
                        Total Impôts :{" "}
                        {parseInt(result[index].value)
                          .toLocaleString("en-EN")
                          .replace(/,/g, " ")}{" "}
                        €
                      </Text>
                    );
                  } else if (result[index].key === "montant_mensualites") {
                    return (
                      <Text style={styles.othersResults}>
                        Montant des mensualités :{" "}
                        {parseInt(result[index].value)
                          .toLocaleString("en-EN")
                          .replace(/,/g, " ")}{" "}
                        €
                      </Text>
                    );
                  } else if (result[index].key === "total_interets") {
                    return (
                      <Text style={styles.othersResults}>
                        Total des intérêts :{" "}
                        {parseInt(result[index].value)
                          .toLocaleString("en-EN")
                          .replace(/,/g, " ")}{" "}
                        €
                      </Text>
                    );
                  } else if (result[index].key === "Usufruit_1") {
                    return (
                      <View>
                        <Text style={styles.usuTitle}>Donataire 1</Text>
                        <Text style={styles.usuResults}>
                          Usufruit :{" "}
                          {parseInt(result[index].value)
                            .toLocaleString("en-EN")
                            .replace(/,/g, " ")}{" "}
                          €
                        </Text>
                      </View>
                    );
                  } else if (result[index].key === "Nue_propriete_1") {
                    return (
                      <Text style={styles.usuResults}>
                        Nue-Propriété :{" "}
                        {parseInt(result[index].value)
                          .toLocaleString("en-EN")
                          .replace(/,/g, " ")}{" "}
                        €
                      </Text>
                    );
                  } else if (result[index].key === "Usufruit_2") {
                    return (
                      <View>
                        <Text style={styles.usuTitle2}>Donataire 2</Text>
                        <Text style={styles.usuResults}>
                          Usufruit :{" "}
                          {parseInt(result[index].value)
                            .toLocaleString("en-EN")
                            .replace(/,/g, " ")}{" "}
                          €
                        </Text>
                      </View>
                    );
                  } else if (result[index].key === "Nue_propriete_2") {
                    return (
                      <Text style={styles.usuResults}>
                        Nue-Propriété :{" "}
                        {parseInt(result[index].value)
                          .toLocaleString("en-EN")
                          .replace(/,/g, " ")}{" "}
                        €
                      </Text>
                    );
                  } else if (result[index].key === "charges_total") {
                    return (
                      <Text style={styles.othersResults}>
                        Total des charges :{" "}
                        {parseInt(result[index].value)
                          .toLocaleString("en-EN")
                          .replace(/,/g, " ")}{" "}
                        €
                      </Text>
                    );
                  } else if (result[index].key === "charges_prorata") {
                    return (
                      <Text style={styles.othersResults}>
                        Prorata de Charges :{" "}
                        {parseInt(result[index].value)
                          .toLocaleString("en-EN")
                          .replace(/,/g, " ")}{" "}
                        €
                      </Text>
                    );
                  } else if (result[index].key === "taxe_fonciere") {
                    return (
                      <Text style={styles.othersResults}>
                        Taxe Foncière :{" "}
                        {parseInt(result[index].value)
                          .toLocaleString("en-EN")
                          .replace(/,/g, " ")}{" "}
                        €
                      </Text>
                    );
                  } else if (result[index].key === "prix_vente") {
                    return (
                      <Text style={styles.othersResults}>
                        Prix de vente :{" "}
                        {parseInt(result[index].value)
                          .toLocaleString("en-EN")
                          .replace(/,/g, " ")}{" "}
                        €
                      </Text>
                    );
                  } else if (result[index].key === "honoraires_ht") {
                    return (
                      <Text style={styles.othersResults}>
                        Honoraires HT :{" "}
                        {parseInt(result[index].value)
                          .toLocaleString("en-EN")
                          .replace(/,/g, " ")}{" "}
                        €
                      </Text>
                    );
                  } else if (result[index].key === "honoraires_ttc") {
                    return (
                      <Text style={styles.othersResults}>
                        Honoraires TTC :{" "}
                        {parseInt(result[index].value)
                          .toLocaleString("en-EN")
                          .replace(/,/g, " ")}{" "}
                        €
                      </Text>
                    );
                  }
                })}
              </View>
            </View>
          </View>

          {/* MAP FOR INPUTS*/}

          <View style={styles.rappel}>
            <Text style={styles.bodyFooterMappedTitle}>
              Rappel de votre simulation :
            </Text>
            {Object.keys(requestSimu?.inputs).map((key) => {
              if (key === "taxe_fonciere") {
                if (requestSimu.inputs[key] > 0) {
                  return (
                    <Text style={styles.bodyFooterMapped}>
                      Montant taxe foncière :{" "}
                      {parseInt(requestSimu.inputs[key])
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                  );
                }
              } else if (key === "charges_copropriete") {
                if (requestSimu.inputs[key] > 0) {
                  return (
                    <Text style={styles.bodyFooterMapped}>
                      Montant charges de copropriété :{" "}
                      {parseInt(requestSimu.inputs[key])
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                  );
                }
              } else if (key === "fonds_roulement") {
                if (requestSimu.inputs[key] > 0) {
                  return (
                    <Text style={styles.bodyFooterMapped}>
                      Besoin en fonds de roulement :{" "}
                      {parseInt(requestSimu.inputs[key])
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                  );
                }
              } else if (key === "date_vente") {
                const { inputs } = requestSimu;
                const value = inputs[key];
                if (value) {
                  const dateString = moment(value).format("DD/MM/YYYY");
                  return (
                    <Text style={styles.bodyFooterMapped}>
                      Date de vente : {dateString}
                    </Text>
                  );
                }
              } else if (key === "nombre_mensualites") {
                if (requestSimu.inputs[key] > 0) {
                  return (
                    <Text style={styles.bodyFooterMapped}>
                      Nombre de mensualités : {requestSimu.inputs[key]}
                    </Text>
                  );
                }
              } else if (key === "taux_interet") {
                if (requestSimu.inputs[key] > 0) {
                  return (
                    <Text style={styles.bodyFooterMapped}>
                      Taux d'intérêt : {requestSimu.inputs[key]} %
                    </Text>
                  );
                }
              } else if (key === "capital") {
                if (requestSimu.inputs[key] > 0) {
                  return (
                    <Text style={styles.bodyFooterMapped}>
                      Capital emprunté :{" "}
                      {parseInt(requestSimu.inputs[key])
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                  );
                }
              } else if (key === "nombre_donateurs") {
                if (requestSimu.inputs[key] === 1) {
                  return (
                    <Text style={styles.bodyFooterMapped}>
                      Nombre donateurs : Un donateur
                    </Text>
                  );
                } else if (requestSimu.inputs[key] === 0) {
                  return (
                    <Text style={styles.bodyFooterMapped}>
                      Nombre donateurs : Deux donateurs
                    </Text>
                  );
                }
              } else if (key === "age_usufruitier_1") {
                if (requestSimu.inputs[key] > 0) {
                  return (
                    <Text style={styles.bodyFooterMapped}>
                      Age donateur 1 : {requestSimu.inputs[key]} ans
                    </Text>
                  );
                }
              } else if (key === "age_usufruitier_2") {
                if (requestSimu.inputs[key] > 0) {
                  return (
                    <Text style={styles.bodyFooterMapped}>
                      Age donateur 2 : {requestSimu.inputs[key]} ans
                    </Text>
                  );
                }
              } else if (key === "valeur_bien_1") {
                if (requestSimu.inputs[key] > 0) {
                  return (
                    <Text style={styles.bodyFooterMapped}>
                      Valeur bien donateur 1 :{" "}
                      {parseInt(requestSimu.inputs[key])
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                  );
                }
              } else if (key === "valeur_bien_2") {
                if (requestSimu.inputs[key] > 0) {
                  return (
                    <Text style={styles.bodyFooterMapped}>
                      Valeur bien donateur 2 :{" "}
                      {parseInt(requestSimu.inputs[key])
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                  );
                }
              } else if (key === "type_bien") {
                if (requestSimu.inputs[key] === 1) {
                  return (
                    <Text style={styles.bodyFooterMapped}>
                      Type de bien : Maison ou appartement
                    </Text>
                  );
                } else if (requestSimu.inputs[key] === 2) {
                  return (
                    <Text style={styles.bodyFooterMapped}>
                      Type de bien : Terrain
                    </Text>
                  );
                } else if (requestSimu.inputs[key] === 3) {
                  return (
                    <Text style={styles.bodyFooterMapped}>
                      Type de bien : Terrain avec construction
                    </Text>
                  );
                }
              } else if (key === "prix_vente") {
                if (requestSimu.inputs[key] > 0) {
                  return (
                    <Text style={styles.bodyFooterMapped}>
                      Prix de vente :{" "}
                      {parseInt(requestSimu.inputs[key])
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                  );
                }
              } else if (key === "frais_vente") {
                if (requestSimu.inputs[key] > 0) {
                  return (
                    <Text style={styles.bodyFooterMapped}>
                      Montant des frais engagés :{" "}
                      {parseInt(requestSimu.inputs[key])
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                  );
                }
              } else if (key === "prix_acquisition") {
                if (requestSimu.inputs[key] > 0) {
                  return (
                    <Text style={styles.bodyFooterMapped}>
                      Prix d'acquisition :{" "}
                      {parseInt(requestSimu.inputs[key])
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                  );
                }
              } else if (key === "frais_acquisition") {
                if (requestSimu.inputs[key] > 0) {
                  return (
                    <Text style={styles.bodyFooterMapped}>
                      Montant des frais d'achat :{" "}
                      {parseInt(requestSimu.inputs[key])
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                  );
                }
              } else if (key === "date_acquisition") {
                const { inputs } = requestSimu;
                const value = inputs[key];
                if (value) {
                  const dateString = moment(value).format("DD/MM/YYYY");
                  return (
                    <Text style={styles.bodyFooterMapped}>
                      Date d'acquisition : {dateString}
                    </Text>
                  );
                }
              } else if (key === "montant_travaux") {
                if (requestSimu.inputs[key] > 0) {
                  return (
                    <Text style={styles.bodyFooterMapped}>
                      Montant des travaux :{" "}
                      {parseInt(requestSimu.inputs[key])
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                  );
                }
              } else if (key === "type_calcul") {
                if (requestSimu.inputs[key] == 0) {
                  return (
                    <Text style={styles.bodyFooterMapped}>
                      Le calcul est : Depuis la base demandée par le vendeur
                    </Text>
                  );
                } else if (requestSimu.inputs[key] == 1) {
                  return (
                    <Text style={styles.bodyFooterMapped}>
                      Le calcul est : Depuis le prix comprenant les honoraires
                      de négociation
                    </Text>
                  );
                }
              } else if (key === "montant_forfait") {
                if (requestSimu.inputs[key] > 0) {
                  return (
                    <Text style={styles.bodyFooterMapped}>
                      Montant forfaitaire minimum :{" "}
                      {parseInt(requestSimu.inputs[key])
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                  );
                }
              } else if (key === "montant_demande") {
                if (requestSimu.inputs[key] > 0) {
                  return (
                    <Text style={styles.bodyFooterMapped}>
                      Montant demandé par le vendeur (ou prix de vente) :{" "}
                      {parseInt(requestSimu.inputs[key])
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                  );
                }
              } else if (key === "taux_remuneration") {
                if (requestSimu.inputs[key] > 0) {
                  return (
                    <Text style={styles.bodyFooterMapped}>
                      Rémunération souhaitée: {requestSimu.inputs[key]} %
                    </Text>
                  );
                }
              }
            })}
          </View>
        </View>
        <View style={styles.bodyFooterCtn} fixed>
          <Text style={styles.bodyFooterBold}>
            IMPORTANT : Les informations et les résultats présentés ci-dessus
            sont donnés à titre indicatif et ne peuvent en aucun cas constituer
            un document à caractère contractuel. Les frais indiqués doivent être
            adressés par virement au minimum 48h-72h à l'avance.
          </Text>
          <Text style={styles.bodyFooterLight}>
            Sources : Editions Langloÿs - Le Barème Rapide® {year}
          </Text>
        </View>
      </Page>
    </Document>
    // </PDFViewer>
  );
};

export default GenerateOthersPdfFile;
