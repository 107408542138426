import React from 'react';
import logo from "../../assets/images/logo_blanc.png"

const Header = () => {
    const styles = {
        container: {
            padding: 0,
            backgroundColor: "#0D1E46",
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            width: "100vw",
            height: 150
        },
        containerHeader: {
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
        },
        containerImage: {
            minWidth: 150,
            height: "100%",
            paddingLeft: "1%",
            alignItems: "center",
        },
        image: {
            height: "75%",
            paddingTop: 19
        },
    };

    return (
        <div style={styles.container}>
            <div style={styles.containerHeader}>
                <div style={styles.containerImage}>
                    <img src={`${logo}`} style={styles.image} alt='logo'/>
                </div>
            </div>
        </div>
    );
};

export default Header;
