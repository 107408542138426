import React, { useEffect, useState } from "react";
import { setSimulateurObject } from "../../../actions/simulateur.action";
import { useDispatch } from 'react-redux';
import { styles } from "../../../stylesheets/simulateurs.css";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import ButtonYesno from "../../../components/buttons/button.yesno";
import SelectDepartement from "../../../components/selects/select.departement";
import { ToolTipPerso } from "../../../components/selects/tooltip_perso";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";

function Simulateur_A1_7(props, simulateur, isParent, departements) {
    const dispatch = useDispatch();

    let currUser = JSON.parse(localStorage.getItem("efluser"));
    let currUserDep = 0
    if (currUser.societe_zip.startsWith("97")) {
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 3);
        currUserDep = parseInt(trimedZip)
    } else {
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 2);
        currUserDep = parseInt(trimedZip)
    }

    const [departement, setDepartement] = useState(0)
    const [montantVente, setMontantVente] = useState(0)
    const [urbanisme, setUrbanisme] = useState(0)
    const [montantUrbanisme, setMontantUrbanisme] = useState(0)

    const handleChange = async (setter, value) => {
        if (setter === setDepartement) {
            setDepartement(parseInt(value))
        } else setter(value);
        setInRedux(setter, value)
    };

    const handleChangeRemarque = async (value) => {
        localStorage.setItem("remarque", value)
    }

    function setInRedux(setter, value) {
        if (props.isParent) {

            let object = {
                "simulateurid": props.simulateur.id,
                "bureauid": parseInt(currUser.id),
                "departementid": 0,
                "inputs": {}
            };

            if (setter === setDepartement) {
                Object.assign(object['inputs'], { 'montant_vente': parseInt(montantVente) })
                Object.assign(object['inputs'], { 'urbanisme': parseInt(urbanisme) })
                Object.assign(object['inputs'], { 'montant_urbanisme': parseInt(montantUrbanisme) })
                object.departementid = parseInt(value)
            }

            if (setter === setMontantVente) {
                Object.assign(object['inputs'], { 'montant_vente': parseInt(value) })
                Object.assign(object['inputs'], { 'urbanisme': parseInt(urbanisme) })
                Object.assign(object['inputs'], { 'montant_urbanisme': parseInt(montantUrbanisme) })
                object.departementid = parseInt(departement)
            }

            if (setter === setUrbanisme) {
                Object.assign(object['inputs'], { 'montant_vente': parseInt(montantVente) })
                Object.assign(object['inputs'], { 'urbanisme': parseInt(value) })
                Object.assign(object['inputs'], { 'montant_urbanisme': parseInt(montantUrbanisme) })
                object.departementid = parseInt(departement)
            }

            if (setter === setMontantUrbanisme) {
                Object.assign(object['inputs'], { 'montant_vente': parseInt(montantVente) })
                Object.assign(object['inputs'], { 'urbanisme': parseInt(urbanisme) })
                Object.assign(object['inputs'], { 'montant_urbanisme': parseInt(value) })
                object.departementid = parseInt(departement)
            }

            dispatch(setSimulateurObject(object));

        } else {

            // process child simu

        }
    }

    return (
        <div style={styles.container}>
            <div style={styles.card}>
                <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id} />

                <TextfieldRemarques handleChange={handleChangeRemarque}/>

                <p style={styles.cardSubTitle}>
                    Les débours ici comprennent les frais JAL en TTC ainsi que la taxe Min.Not.
                </p>

                {props?.isParent === true && props.departements !== "" ?
                    <div style={styles.cardRow}>
                        <div style={styles.cardElemVerticalyCenter}>
                            Département :
                        </div>

                        <SelectDepartement items={props.departements} nameField={"name"} defaultValue={currUserDep} optionValue={"id"}
                            handleChange={handleChange} setter={setDepartement} />
                    </div>
                    :
                    <></>
                }

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Indiquer le montant de la vente par adjudication
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer le montant"}
                        setter={setMontantVente}
                    />
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Y a-t-il des débours à prévoir et/ou des frais de PV de quittance à ajouter et/ou des demandes d’urbanisme particulières ?
                        <ToolTipPerso title={"Les débours sur les ventes comprennent une somme de 120 euros représentant le coût moyen d'obtention d'un certificat d'urbanisme complet. Pour retirer ce coût, répondre 'non' à la question 'urbanisme demandé à un tiers'."} />
                    </div>
                    <div>
                        <ButtonYesno
                            handleChange={handleChange}
                            setter={setUrbanisme}
                            fieldUndefined={true}
                            defaultValue={2}
                        />
                    </div>
                </div>

                {
                    parseInt(urbanisme) === 1 ?
                        <div style={styles.cardRow}>
                            <div style={styles.cardElemVerticalyCenter}>
                                Indiquer le montant :
                            </div>
                            <TextfieldNumber
                                handleChange={handleChange}
                                type={"number"}
                                placeholder={"Indiquer le montant"}
                                setter={setMontantUrbanisme}
                            />
                        </div>
                        :
                        <></>
                }
            </div>
        </div>
    )
}

export default Simulateur_A1_7;
