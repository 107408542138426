import React, {useState} from 'react';
import Colors from "../../useclass/colors";

const TextSimulateurTitle = (props) => {
    const stylesTempo = {
        containerTitle: {
            color: Colors.primaryBlue,
            width: "90%",
            fontSize: 22,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 10,
            marginBottom: 10
        },
        containerBody: {
            width: "88%",
            marginLeft: "auto",
            marginRight: "auto",
        },
        container: {
            width: "70vw",
            marginLeft: "auto",
            marginRight: "auto",
        },
        containerItem: {
            display: "grid",
            gridTemplateColumns: "0px 15px 100%",
            marginBottom: 35,
            paddingTop: 55,
            fontSize: 22,
            lineHeight: 1.5,
        },
        bar: {
            backgroundColor: Colors.primaryBlue,
            width: 3,
            height: "100%",
            marginTop: "auto",
            marginBottom: "auto"
        },
        containerStar: {
            marginTop: "auto",
            marginBottom: "auto"
        }
    }




    return (
        <div style={stylesTempo.container}>
            <div style={stylesTempo.containerItem}>

                <div style={stylesTempo.containerStar}>


                </div>

                <div style={stylesTempo.bar}>

                </div>
                <div style={stylesTempo.bodyText}>
                    {props.title}
                </div>
            </div>
        </div>
    );
};

export default TextSimulateurTitle;
