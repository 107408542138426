import Colors from "../../useclass/colors";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import {API_BASE_URL, PARTNER_JWT} from "../../config";
import { useSnackbar } from 'notistack';
import useIsMountedRef from "../../utils/mountedRef";
import Simulateur_Donations from "./simulateur.donations/simulateur.Donations";
import Simulateur_Successions from "./simulateur.successions/simulateur.Successions";
import { useSelector } from "react-redux";
import LinkDMTGPDF from "../../utils/simulateurs/link.DMTG.PDF";
import "./modal.css";
import close from "../../assets/images/close.png";
import "../../assets/fonts/Roboto/Roboto-Regular.ttf";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";

function DmtgMain(props) {
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const [show, setShow] = useState(false)
  const [parentSimulateur, setParentSimulateur] = useState({})
  const [createPDF, setCreatePDF] = useState(false)
  const [simulateurResponse, setSimulateurResponse] = useState({})
  const [readyPDF, setReadyPDF] = useState(false)
  let data = useSelector((state) => state.dmtgResults);
  const [arrayContent, setArrayContent] = useState([])

  useEffect(() => {
    const items = { ...localStorage };
    Object.keys(items).map((key, value) => {
      if (isNumeric(key.toString())) {
        localStorage.removeItem(key);
      }
    });
    localStorage.removeItem("outputAffinage");
    localStorage.removeItem("usageId");
    localStorage.removeItem("nameSimuDMTG");
  }, [isMountedRef]);

  const showHideClassName = show ? "modal display-block" : "modal display-none";

  function setid() {
    let checkTest;
    if (props?.callSinceSimu) checkTest = props.simulateur.id;
    else checkTest = props.match.params.simulateur;

    return checkTest;
  }

  const getSimulateur = useCallback(() => {
    axios.defaults.withCredentials = true;

    const myPromise = new Promise(async (resolve, reject) => {
      if (props?.callSinceSimu) {
        let checkTest = await setid();
        resolve(checkTest);
      } else resolve(props.match.params.simulateur);
    });


    myPromise
        .then((response) => {
          var config = {
            method: 'get',
            url: `${API_BASE_URL}/simulateurs/${response}`,
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `${PARTNER_JWT}`,
            },
          };
          axios(config)
              .then((response) => {
                if (response.status === 200 && response.data != null) {
                  if (isMountedRef.current) {
                    setParentSimulateur(response.data)
                  }
                } else {
                  enqueueSnackbar('Une erreur est survenue', {
                    variant: 'error'
                  });
                  //    window.location = "/app"
                }
              })
              .catch((err) => {
                enqueueSnackbar('Une erreur est survenue', {
                  variant: 'error'
                });
                //  window.location = "/app"
              });
        })
  }, [isMountedRef])

  const styles = {
    container: {
      width: "100%",
      color: Colors.textBlack,
      fontFamily: "Regular",
    },
    container1: {
      display:"flex",
      flexDirection:"row",
      width: "100%",
      color: Colors.textBlack,
      minHeight: "75vh",
      backgroundColor: Colors.ultraLightGrey,
      fontFamily: "Regular"

    },
    ctnButton: {
      width: "90%",
      marginLeft: "auto",
      marginRight: "auto",
      paddingBottom: 30,
      paddingTop: 30,
      marginBottom:150
    },
    ctnButtonValide: {
      marginLeft: "auto",
      marginRight: "auto",
      paddingBottom: 30,
      paddingTop: 0,
      textAlign: "center",
      width: "30%",
    },
    buttonCalculate: {
      display: "block",
      margin: "auto",
      width: "30%",
      height: 35,
      borderRadius: 5,
      border: "none",
      backgroundColor: "#49708C",
      color: Colors.white,
      fontWeight: "bold",
      cursor: "pointer",
      boxShadow: "2px 2px 1px rgba(0,0,0, 0.2)",
      hover: "#6399BF",
    },
    ctnTable: {
      width: "97%",
      marginTop: 10,
      marginBottom: 30,
      backgroundColor: Colors.white,
      //  boxShadow: "2px 2px 1px rgba(0,0,0, 0.2)",
      //width: "95%",
      marginLeft: "auto",
      marginRight: "auto",
      borderRadius: 4,
      minWidth: 400,
    },

    ctnTableData: {
      width: "100%",
      textAlign: "left",
      display: "flex",
      flexDirection: "row",
      height: 400,
    },
    table: {
      width: "75%",
    },

    tableBody: {
      width: "100%",
      display: "flex",
      borderCollapse: "collapse",
      margin: "auto",
      flexDirection: "row",
      flexWrap: "wrap",
      alignContent: "center",
      justifyContent: "space-evenly",
    },

    tr: {
      width: "auto",
      borderCollapse: "collapse",
      borderRadius: 5,
      textAlign: "center",
    },

    td1: {
      width: "60%",
      borderCollapse: "collapse",
      padding: "5px 5px 5px 10px",
      textAlign: "left",
      verticalAlign: "middle",
    },

    td2: {
      width: "200px",
      borderCollapse: "collapse",
      padding: "5px 10px 5px 5px",
      height: 40,
      textAlign: "right",
      verticalAlign: "middle",
    },
  };

  useEffect(() => {
    getSimulateur();
  }, [getSimulateur]);

  useEffect(() => {
    localStorage.setItem("nameSimuDMTG", parentSimulateur.name);
  }, [parentSimulateur]);

  let handleData;

  function dmtgTreatment(dependance, i, j) {
    return new Promise(resolve => {
      try {
        var config = {
          method: 'post', url: `${API_BASE_URL}/calcul`, headers: {
            'Content-Type': 'application/json',
            'Authorization': `${PARTNER_JWT}`
          }, data: dependance
        };
        axios(config)
            .then((response) => {
              if (response.status === 200 && response.data != null) {
                handleData.data[i].donataires[j] = { "output": response.data, } //simulateurObject.data.dependances[i].results
                resolve();
              } else {
                enqueueSnackbar('Le simulateur ' + parentSimulateur.name + " est mal renseigné", {
                  variant: 'error'
                });
                Promise.reject("fail dependence")
              }
            })
            .catch((err) => {
              enqueueSnackbar('Tous les champs n\'ont pas été correctement renseignés.', {
                variant: 'error'
              });
              Promise.reject("fail dependence")
            });
      } catch (e) {
        return null
      }
    })
  }

  async function check(data) {
    if (Array.isArray(data.data) && data.data.length > 0) {
      localStorage.setItem("dmtgInputs", JSON.stringify(data));
      handleData = JSON.parse(localStorage.getItem("dmtgInputs"));
      if (data?.data?.length > 0 || data?.data !== undefined) {
        for (let i = 0; i < handleData.data.length; i++) {
          for (let j = 0; j < handleData.data[i].donataires?.length; j++) {
            let handle = handleData.data[i].donataires[j]; //simulateurObject.data.dependances[i].inputs
            await dmtgTreatment(handle, i, j);
          }
        }
        //MISE EN FORME DE L'OBJET RESULTAT
        //console.log("handleData" + JSON.stringify(handleData))
        setSimulateurResponse(handleData);
        var handleVar = handleData.data;
        var allDonataireData = [];
        for (let i = 0; i < handleData.data[0].donataires.length; i++) {
          allDonataireData[i] = [];
          //console.log("allDonataireData" + allDonataireData[i])
          //console.log(JSON.stringify(handleData.data[i]))
          for (let j = 0; j < handleData.data.length; j++) {
            var allData = [];
            if (handleData.data[j].donataires[i].output.DMTG !== undefined) {
              allData.push({
                key: "DMTG",
                name: "Droits DMTG à payer",
                value: parseInt(handleData.data[j].donataires[i].output.DMTG).toLocaleString("en-EN").replace(/,/g, " ") + " €",
              });
            } else {
              allData.push({
                key: "DMTG",
                name: "Droits DMTG à payer",
                value: "0€",
              });
            }

            if (handleData.data[j].donataires[i].output.base_dmtg !== undefined) {
              allData.push({
                key: "base_dmtg",
                name: "Base Taxable",
                value: parseInt(handleData.data[j].donataires[i].output.base_dmtg).toLocaleString("en-EN").replace(/,/g, " ") + " €",
              });
            } if (handleData.data[j].donataires[i].output.reliquat_total !== undefined && handleData.data[j].donataires[i].output.reliquat_total !== 0) {
              allData.push({
                key: "reliquat_total",
                name: "Déduction faite du reliquat total des parts de tranches non utilisées lors des donations antérieures à 2012 :",
                value: parseInt(handleData.data[j].donataires[i].output.reliquat_total).toLocaleString('en-EN').replace(/,/g, ' ') + " €"
              })
            }
            if (handleData.data[j].donataires[i].output.immo !== undefined && handleData.data[j].donataires[i].output.immo !== 0) {
              allData.push({
                key: "immo",
                name: "Montant Immobilier",
                value: parseInt(handleData.data[j].donataires[i].output.immo).toLocaleString("en-EN").replace(/,/g, " ") + " €",
              });
            }
            if (handleData.data[j].donataires[i].output.especes !== undefined && handleData.data[j].donataires[i].output.especes !== 0) {
              allData.push({
                key: "especes",
                name: "Montant Espèces",
                value: parseInt(handleData.data[j].donataires[i].output.especes).toLocaleString("en-EN").replace(/,/g, " ") + " €",
              });
            }
            if (handleData.data[j].donataires[i].output.abattement_total !== undefined) {
              allData.push({
                key: "abattement_total",
                name: "Abattement appliqué",
                value: parseInt(handleData.data[j].donataires[i].output.abattement_total).toLocaleString('en-EN').replace(/,/g, ' ') + " €"
              });
            }
            if (handleData.data[j].donataires[i].output.abattement_restant !== undefined) {
              allData.push({
                key: "abattement_restant",
                name: "Abattement légal restant",
                value: parseInt(handleData.data[j].donataires[i].output.abattement_restant).toLocaleString("en-EN").replace(/,/g, " ") + " €",
              });
            }
            if (handleData.data[j].donataires[i].output.tranche_initiale !== undefined) {
              allData.push({
                key: "tranche_initiale",
                name: "Taux tranche initiale",
                value: parseInt(handleData.data[j].donataires[i].output.tranche_initiale * 100).toLocaleString("en-EN") + " %",
              });
            }
            if (handleData.data[j].donataires[i].output.reliquat_tranche_donation !== undefined) {
              allData.push({
                key: "reliquat_tranche_donation",
                name: "Reliquat de tranche initiale non utilisé lors des donations antérieures",
                value: parseInt(handleData.data[j].donataires[i].output.reliquat_tranche_donation).toLocaleString('en-EN').replace(/,/g, ' ') + " €"
              });
            }
            if (handleData.data[j].donataires[i].output.reliquat_tranche_donation !== undefined) {
              allData.push({
                key: "reliquat_tranche_donation",
                name: "Reliquat de tranche initiale non utilisé lors des donations antérieures",
                value: parseInt(handleData.data[j].donataires[i].output.reliquat_tranche_donation).toLocaleString('en-EN').replace(/,/g, ' ') + " €"
              });
            }
            if (handleData.data[j].donataires[i].output.tranche_finale !== undefined) {
              allData.push({
                key: "tranche_finale",
                name: "Taux tranche finale",
                value: parseInt(handleData.data[j].donataires[i].output.tranche_finale * 100).toLocaleString("en-EN") + " %"
              })
            }
            if (handleData.data[j].donataires[i].output.total_succession !== undefined) {
              allData.push({
                key: "total_succession",
                name: "Masse à partager avec l'ensemble des héritiers",
                value: parseInt(handleData.data[j].donataires[i].output.total_succession).toLocaleString('en-EN').replace(/,/g, ' ') + " €"
              })
            }

            allDonataireData[i][j] = allData;
            //allDonateurData.push(allDonataireData)
            //const base64Image = chartRef?.current?.chartInstance?.toBase64Image();
          }
        }
        //console.log("donatairedata : " + JSON.stringify(allDonataireData))
        if (props?.callSinceSimu) {
          props.handleChange(props.setter, handleVar);
        } else {
          if (allDonataireData.length > 0) {
            localStorage.setItem("arrayContent", JSON.stringify(allDonataireData));
            await setArrayContent(allDonataireData);
            setCreatePDF(true);
          }
        }
      }
    } else return data;
  }

  function calculate() {
    check(data).then(showModal());
  }

  useEffect(() => {
    //console.log("ARRAYCONTENT IS SET")
  }, [arrayContent]);

  useEffect(() => {
    if (props.calculateDMTGState) calculate();
  }, [props.calculateDMTGState]);

  function isNumeric(str) {
    if (typeof str != "string") return false;
    return !isNaN(str) && !isNaN(parseFloat(str));
  }

  function showModal() {
    setShow(true);
  }

  function hideModal() {
    setShow(false);
    setReadyPDF(false);
  }

  const handleChange = (setter, value) => {
    setter(value);
  };

  function tablePrint() {
    if (arrayContent?.length > 0) {
      return (
          <div className={showHideClassName}>
            <div className="modal-dmtg" >
              <div className="modal-main-dmtg">
                <>
                  {simulateurResponse !== undefined ? (
                        <div style={styles.ctnTable}>
                          <div style={styles.ctnTableData}>
                            <div style={{ flex: 6, boder: "1px solid grey" }}>
                              <p style={{
                                textAlign: "center",
                                fontSize: 20,
                              }}>Résultat de votre calcul de DMTG</p>


                              {buttonPrint()}

                              <div
                                  style={{
                                    display: "flex",
                                    flexFlow: "row wrap",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    flexWrap: "nowrap",
                                    alignItems: "center",
                                  }}
                              >
                                <table style={styles.table}>
                                  <tbody style={styles.tableBody}>
                                  {arrayContent ? (
                                        arrayContent.map((donataire, i) => {
                                          if (data?.data[0].donataires[0].simulateurid === 55) {
                                            return (
                                                <>
                                                  <div className="blockHeritier1">
                                                    <div className="heritierTitle">Héritier {i + 1} </div>
                                                    {donataire.map((donateur, j) => {
                                                      return (
                                                          <>
                                                            <div className="blockHeritier2">
                                                              {donateur.map((value) => {
                                                                var currentClass = {};
                                                                if (value.key === "DMTG") {
                                                                  currentClass = {};
                                                                }
                                                                return (
                                                                    <>
                                                                      <div className="dmtgResults">
                                                                        <tr style={currentClass}>
                                                                          <td style={styles.td1}>{value.name}</td>
                                                                          <td style={styles.td2}>{value.value}</td>
                                                                        </tr>
                                                                      </div>
                                                                    </>
                                                                );
                                                              })}
                                                            </div>
                                                          </>
                                                      );
                                                    })}
                                                  </div>
                                                </>
                                            );
                                          } else {
                                            return (
                                                <>
                                                  <div className="blockDonataire">
                                                    <div className="donataireTitle">Donataire {i + 1} </div>
                                                    {donataire.map((donateur, j) => {
                                                      return (
                                                          <>
                                                            <div className="blockDonateur">
                                                              <div className="donateurTitle">Donateur {j + 1} </div>
                                                              {donateur.map((value) => {
                                                                var currentClass = {};
                                                                if (value.key === "DMTG") {
                                                                  currentClass = {};
                                                                }
                                                                return (
                                                                    <>
                                                                      <div className="dmtgResults">
                                                                        <tr style={currentClass}>
                                                                          <td style={styles.td1}>{value.name}</td>
                                                                          <td style={styles.td2}>{value.value}</td>
                                                                        </tr>
                                                                      </div>
                                                                    </>
                                                                );
                                                              })}
                                                            </div>
                                                          </>
                                                      );
                                                    })}
                                                  </div>
                                                </>
                                            );
                                          }
                                        })
                                     ) : (
                                      <></>
                                  )}
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <img
                                src={`${close}`}
                                style={{
                                  position: "absolute",
                                  fontWeight: "bold",
                                  right: 20,
                                  height: 20,
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  hideModal();
                                  setReadyPDF(false);
                                }}
                            />
                          </div>
                        </div>
                       ) : (
                        <div></div>
                  )}
                </>
              </div>
            </div>
          </div >
      );
    }
  }

  function buttonPrint() {
    return (
      <>
        <div style={{ ...styles.ctnButtonValide }}>
          {createPDF === true ? (
            <div>
              <LinkDMTGPDF
                simulateurObject={arrayContent}
                ready={readyPDF}
                setReadyMain={setReadyPDF}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }

  // useEffect(() => {
  //     tablePrint()
  // }, [arrayContent]);

  function printCalculateResult() {
    if (parserSimulator().props.children !== "Simulateur non disponible") {
      const useStyles = makeStyles({
        textarea: {
          resize: "both",
        },
      });

      return (
          <>
            <div style={styles.ctnButton}>
              <button
                  style={styles.buttonCalculate}
                  onClick={(e) => {
                    calculate();
                  }}
              >
                Calculer les DMTG
              </button>
            </div>
            {tablePrint()}
          </>
      );
    } else {
      return <></>;
    }
  }

  function parserSimulator() {
    let check;
    if (props?.callSinceSimu) {
      check = props.simulateur.id.toString();
    } else {
      check = props.match.params.simulateur.toString();
    }

    switch (check) {
        // OK
      case "55": {
        return (
            <>
              <Simulateur_Successions
                  simulateur={parentSimulateur}
                  callSinceSimu={props.callSinceSimu}
                  montant_brut={props.montant_brut}
                  total_rapportable={props.total_rapportable}
                  montant_passif={props.montant_passif}
                  montant_net={props.montant_net}
                  total_succession={props.total_succession}
              />
            </>
        )
      }
      case "56": {
        return (
            <>
              <Simulateur_Donations
                  simulateur={parentSimulateur}
                  donateurs={props.donateurs}
                  callSinceSimu={props.callSinceSimu}
              />
            </>
        )
      }
      case "109": {
        return (
            <>
              <Simulateur_Donations
                  simulateur={parentSimulateur}
                  donateurs={props.donateurs}
                  callSinceSimu={props.callSinceSimu}
              />
            </>
        )
      }
      case "110": {
        return (
            <>
              <Simulateur_Donations
                  simulateur={parentSimulateur}
                  donateurs={props.donateurs}
                  callSinceSimu={props.callSinceSimu}
              />
            </>
        )
      }
      default: {
        return <h2>Simulateur non disponible</h2>;
      }
    }
  }

  // SWITCH WITH ID SIMULATEUR
  return (
    <div style={styles.container}>
      {parserSimulator()}
      {props?.callSinceSimu ? <></> : printCalculateResult()}
    </div>
  );
}

export default DmtgMain;
