import React, { useEffect, useState } from "react";
import { setSimulateurObject } from "../../../actions/simulateur.action";
import { useDispatch } from 'react-redux';
import { styles } from "../../../stylesheets/simulateurs.css";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import SelectCommon from "../../../components/selects/select.common";
import ButtonEDD from "../../../components/buttons/button.EDD";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import SelectDepartement from "../../../components/selects/select.departement";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";

// Etat Descriptif de Division (EDD) - règlement de copropriété
// simulateur Id 10

function Simulateur_EDD(props, simulateur, isParent, departements) {
    const dispatch = useDispatch();
    const currentIdSimu = 10
    let currUser = JSON.parse(localStorage.getItem("efluser"));
    let currUserDep = 0
    if (currUser.societe_zip.startsWith("97")) {
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 3);
        currUserDep = parseInt(trimedZip)
    } else {
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 2);
        currUserDep = parseInt(trimedZip)
    }

    const [departement, setDepartement] = useState(0)
    const [type_immeuble, setImmeuble] = useState(0)
    const [nombre_lots, setLots] = useState(0)
    const [nombre_diagnostics, setDiag] = useState(0)

    const handleChange = async (setter, value) => {
        if (setter === setDepartement) {
            setDepartement(parseInt(value))
        } else setter(value);
        setInRedux(setter, value)
    };

    const handleChangeRemarque = async (value) => {
        localStorage.setItem("remarque", value)
    }

    function setInRedux(setter, value) {
        if (props.isParent) {

            let object = {
                "simulateurid": props.simulateur.id,
                "departementid": 0,
                "bureauid": parseInt(currUser.id),
                "inputs": {}
            };

            if (setter === setDepartement) {
                Object.assign(object['inputs'], { 'type_immeuble': parseInt(type_immeuble) })
                Object.assign(object['inputs'], { 'nombre_lots': parseInt(nombre_lots) })
                Object.assign(object['inputs'], { 'nombre_diagnostics': parseInt(nombre_diagnostics) })
                object.departementid = parseInt(value)
            }

            if (setter === setImmeuble) {
                Object.assign(object['inputs'], { 'type_immeuble': parseInt(value) })
                Object.assign(object['inputs'], { 'nombre_lots': parseInt(nombre_lots) })
                Object.assign(object['inputs'], { 'nombre_diagnostics': parseInt(nombre_diagnostics) })
                object.departementid = parseInt(departement)
            }

            if (setter === setLots) {
                Object.assign(object['inputs'], { 'type_immeuble': parseInt(type_immeuble) })
                Object.assign(object['inputs'], { 'nombre_lots': parseInt(value) })
                Object.assign(object['inputs'], { 'nombre_diagnostics': parseInt(nombre_diagnostics) })
                object.departementid = parseInt(departement)
            }

            if (setter === setDiag) {
                Object.assign(object['inputs'], { 'type_immeuble': parseInt(type_immeuble) })
                Object.assign(object['inputs'], { 'nombre_lots': parseInt(nombre_lots) })
                Object.assign(object['inputs'], { 'nombre_diagnostics': parseInt(value) })
                object.departementid = parseInt(departement)
            }

            dispatch(setSimulateurObject(object));

        } else {

            // process child simu

        }
    }

    return (
        <div style={styles.container}>
            <div style={styles.card}>
                <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id} />

                <TextfieldRemarques handleChange={handleChangeRemarque} />

                {props?.isParent === true && props.departements !== "" ?
                    <div style={styles.cardRow}>
                        <div style={styles.cardElemVerticalyCenter}>
                            Département :
                        </div>

                        <SelectDepartement items={props.departements} nameField={"name"} defaultValue={currUserDep} optionValue={"id"}
                            handleChange={handleChange} setter={setDepartement} />
                    </div>
                    :
                    <></>
                }

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        De quel type d'immeuble s'agit-il ?
                    </div>
                    <ButtonEDD handleChange={handleChange} setter={setImmeuble} defaultValue={0} />
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Quel est le nombre de lots à créer ?
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer le nombre de pages"}
                        setter={setLots}
                    />
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Combien de diagnostics sont-ils insérés dans l'EDD ? (DTG,Certificat Carrez, ...)                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer le nombre de pages"}
                        setter={setDiag}
                    />
                </div>

            </div>

        </div>
    )
}

export default Simulateur_EDD;
