import Colors from "../useclass/colors";
import { useEffect, useState } from "react";
import axios from "axios";
import {API_BASE_URL, PARTNER_JWT} from "../config";
import { useSnackbar } from 'notistack';
import useIsMountedRef from "../utils/mountedRef";
import ReactGA from "react-ga4";

function Home() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [categoriesOther, setCategoriesOther] = useState([]);
    const [simulateurs, setSimulateurs] = useState([]);
    const [allFavoris, setAllFavoris] = useState(localStorage.getItem("favoris"))
    const [categories, setCategories] = useState([]);
    const isMountedRef = useIsMountedRef();
    const dmtg = [55, 56, 109, 110];
    const others = [42, 103, 104, 105, 106];

    useEffect(() => {


        var config = {
            method: 'get',
            url: `${API_BASE_URL}/categories`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${PARTNER_JWT}`,
            },
        };
        axios(config)
            .then((response) => {
                // response.data renvoie directement le token sans json il faut donc stocker le token en async storage et faire la securité sur les autres pages si l'utilisateurs 'nest pas connecté
                if (response.status === 200 && response.data != null) {
                    if (isMountedRef.current) {
                        setCategories(response.data)
                    }
                } else {
                    enqueueSnackbar('Une erreur est survenue', {
                        variant: 'error'
                    });
                }

            });
    }, [isMountedRef]);

    useEffect(() => {
        var others = {
            method: 'get',
            url: `${API_BASE_URL}/categories/autres`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${PARTNER_JWT}`,
            },
        };
        axios(others)
            .then((response) => {
                // response.data renvoie directement le token sans json il faut donc stocker le token en async storage et faire la securité sur les autres pages si l'utilisateurs 'nest pas connecté
                if (response.status === 200 && response.data != null) {
                    if (isMountedRef.current) {
                        setCategoriesOther(response.data.subcategories)
                    }
                } else {
                    enqueueSnackbar('Une erreur est survenue', {
                        variant: 'error'
                    });
                }

            });
    }, [isMountedRef]);

    useEffect(() => {
        var simulateurs = {
            method: 'get',
            url: `${API_BASE_URL}/simulateurs`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${PARTNER_JWT}`,
            },
        };
        axios(simulateurs)
            .then((response) => {
                // response.data renvoie directement le token sans json il faut donc stocker le token en async storage et faire la securité sur les autres pages si l'utilisateurs 'nest pas connecté
                if (response.status === 200 && response.data != null) {
                    if (isMountedRef.current) {
                        setSimulateurs(response.data)
                        //console.log(simulateurs);
                    }
                } else {
                    enqueueSnackbar('Une erreur est survenue', {
                        variant: 'error'
                    });
                }

            });
    }, [isMountedRef]);


    useEffect(() => {
        if (allFavoris !== null && allFavoris.length > 0) {
            try {
                setAllFavoris(JSON.parse(allFavoris));
            } catch (e) {

            }

        }

    }, [isMountedRef]);

    function returnStar(item) {
        if (allFavoris == null) {
            return (<svg xmlns="http://www.w3.org/2000/svg" width="20.847" height="19.011"
                preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512">
                <path fill="none" stroke="#5E91B6" stroke-linejoin="round" stroke-width="32"
                    d="M480 208H308L256 48l-52 160H32l140 96l-54 160l138-100l138 100l-54-160Z" />
            </svg>)
        }
        if (allFavoris.hasOwnProperty(item.id)) {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="20.847" height="19.011"
                    preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512">
                    <path fill="#5E91B6"
                        d="M394 480a16 16 0 0 1-9.39-3L256 383.76L127.39 477a16 16 0 0 1-24.55-18.08L153 310.35L23 221.2a16 16 0 0 1 9-29.2h160.38l48.4-148.95a16 16 0 0 1 30.44 0l48.4 149H480a16 16 0 0 1 9.05 29.2L359 310.35l50.13 148.53A16 16 0 0 1 394 480Z" />
                </svg>
            )
        } else {
            return (<svg xmlns="http://www.w3.org/2000/svg" width="20.847" height="19.011"
                preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512">
                <path fill="none" stroke="#5E91B6" stroke-linejoin="round" stroke-width="32"
                    d="M480 208H308L256 48l-52 160H32l140 96l-54 160l138-100l138 100l-54-160Z" />
            </svg>)
        }
    }

    function onClickFavoris(id) {
        var config = {
            method: 'get',
            url: `${API_BASE_URL}/favoris/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${PARTNER_JWT}`,
            },
        };
        axios(config)
            .then((response) => {
                if (response.status === 200 && response.data != null) {
                    localStorage.setItem("favoris", response.data)
                    if (response.data !== null)
                        setAllFavoris(JSON.parse(response.data))
                    else
                        setAllFavoris(response.data)
                }
            });
    }

    const styles = {
        bar: {
            backgroundColor: "#0D1E46",
            width: 2,
            height: 30
        },
        container: {
            width: "100%",
            color: Colors.textBlack,
            minHeight: "68vh"
        },
        containerCards: {
            // display: "grid",
            // gridGap: "30px 15px",
            // gridTemplateColumns: "repeat(3, 1fr)",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",


            width: "90%",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 50,
        },
        containerItem: {
            display: "grid",
            gridTemplateColumns: "32px 12px 1fr",
            marginBottom: 15,
            lineHeight: 1.5,
        },
        card: {
            marginTop: 10,
            marginBottom: 30,
            backgroundColor: Colors.white,
            boxShadow: "2px 2px 1px rgba(0,0,0, 0.2)",
            //width: "95%",
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 4,
            minWidth: 400,
            width: "30%"
        },

        starCard: {
            marginTop: 16,
            marginBottom: 16,
            paddingLeft: 20,
            paddingRight: 20,
        },

        cardHeader: {
            backgroundColor: Colors.lightGrey,
            borderRadius: "4px 4px 0px 0px",
            width: "100%",
            height: "auto",
            display: "grid",
            gridTemplateColumns: "1fr 3fr",
            paddingTop: 5,
            paddingBottom: 5
        },
        cardHeaderBlue: {
            backgroundColor: Colors.primaryBlue,
            borderRadius: "4px 4px 0px 0px",
            width: "100%",
            height: "auto",
            display: "grid",
            gridTemplateColumns: "1fr 3fr",
            color: "white",
            paddingTop: 5,
            paddingBottom: 5
        },
        logo: {
            marginTop: "auto",
            marginBottom: "auto",
            marginRight: "auto",
            marginLeft: 15,
        },
        list: {},
        listElem: {
            lineHeight: 1.8,
            cursor: "pointer"
        },
        listElemStar: {
            lineHeight: 1.8,
            listStyleType: "none",
            display: "flex",
            flexDirection: "row",
            cursor: "pointer",
        },
        liStar: {
            marginRight: 10,
            marginTop: 3
        },
        cardBody: {
            minHeight: 180,
            overflowY: 'scroll'
        },
        cardTitle: {
            fontWeight: "normal",
            color: Colors.titleBlue
        },
        cardTitleWhite: {
            fontWeight: "normal",
            color: "white",
            marginLeft:15
        },

    };

    return (
        <div style={styles.container}>

            <div style={styles.containerCards}>
                <div style={styles.card}>
                    <div style={styles.cardHeader}>
                        <div style={styles.logo}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50.693" height="52.554"
                                viewBox="0 0 50.693 52.554">
                                <path id="Icon_ionic-ios-calculator" data-name="Icon ionic-ios-calculator"
                                    d="M52.806,3.375H13.074c-3.031,0-5.48,1.807-5.48,4.043V51.886c0,2.236,2.449,4.043,5.48,4.043H52.806c3.031,0,5.48-1.807,5.48-4.043V7.418C58.287,5.182,55.838,3.375,52.806,3.375ZM50.751,37.737V47.843c0,1.68-1.832,3.032-4.11,3.032h0c-2.278,0-4.11-1.352-4.11-3.032V37.737c0-1.68,1.832-3.032,4.11-3.032h0C48.919,34.705,50.751,36.057,50.751,37.737Zm0-10.106h0c0,1.68-1.832,3.032-4.11,3.032h0c-2.278,0-4.11-1.352-4.11-3.032h0c0-1.68,1.832-3.032,4.11-3.032h0C48.919,24.6,50.751,25.95,50.751,27.631Zm-13.7,20.213h0c0,1.68-1.832,3.032-4.11,3.032h0c-2.278,0-4.11-1.352-4.11-3.032h0c0-1.68,1.832-3.032,4.11-3.032h0C35.218,44.811,37.051,46.163,37.051,47.843Zm0-10.106h0c0,1.68-1.832,3.032-4.11,3.032h0c-2.278,0-4.11-1.352-4.11-3.032h0c0-1.68,1.832-3.032,4.11-3.032h0C35.218,34.705,37.051,36.057,37.051,37.737Zm0-10.106h0c0,1.68-1.832,3.032-4.11,3.032h0c-2.278,0-4.11-1.352-4.11-3.032h0c0-1.68,1.832-3.032,4.11-3.032h0C35.218,24.6,37.051,25.95,37.051,27.631ZM23.35,47.843h0c0,1.68-1.832,3.032-4.11,3.032h0c-2.278,0-4.11-1.352-4.11-3.032h0c0-1.68,1.832-3.032,4.11-3.032h0C21.517,44.811,23.35,46.163,23.35,47.843Zm0-10.106h0c0,1.68-1.832,3.032-4.11,3.032h0c-2.278,0-4.11-1.352-4.11-3.032h0c0-1.68,1.832-3.032,4.11-3.032h0C21.517,34.705,23.35,36.057,23.35,37.737Zm0-10.106h0c0,1.68-1.832,3.032-4.11,3.032h0c-2.278,0-4.11-1.352-4.11-3.032h0c0-1.68,1.832-3.032,4.11-3.032h0C21.517,24.6,23.35,25.95,23.35,27.631Zm-8.22-10.106V11.46c0-1.112,1.233-2.021,2.74-2.021H48.011c1.507,0,2.74.91,2.74,2.021v6.064c0,1.112-1.233,2.021-2.74,2.021H17.869C16.362,19.545,15.129,18.636,15.129,17.524Z"
                                    transform="translate(-7.594 -3.375)" fill="#7CA8DF" />
                            </svg>
                        </div>

                        <h2 style={styles.cardTitle}>
                            Calculs de frais
                        </h2>
                    </div>


                    <div style={styles.cardBody}>
                        <ul>
                            {
                                categories.map((value => {
                                    if (value.categorie_id != 5 && value.categorie_id != 4) {
                                        return (<li style={styles.listElem}
                                            onClick={() => window.location = `/categorie/${value.url_name}`}>
                                            {value.categorie_name}
                                        </li>
                                        )
                                    }
                                }))
                            }
                        </ul>
                    </div>
                </div>

                <div style={styles.card}>
                    <div style={styles.cardHeader}>
                        <div style={styles.logo}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="66.425" height="58.128"
                                viewBox="0 0 66.425 58.128">
                                <path id="Icon_awesome-cubes" data-name="Icon awesome-cubes"
                                    d="M63.389,30.56l-12.532-4.7V11.787a4.67,4.67,0,0,0-3.036-4.372L34.847,2.55a4.615,4.615,0,0,0-3.282,0L18.591,7.415a4.67,4.67,0,0,0-3.036,4.372V25.863L3.023,30.56A4.676,4.676,0,0,0,0,34.932V49.216a4.675,4.675,0,0,0,2.582,4.177L15.555,59.88a4.644,4.644,0,0,0,4.177,0l13.48-6.746,13.48,6.746a4.644,4.644,0,0,0,4.177,0l12.974-6.487a4.675,4.675,0,0,0,2.582-4.177V34.932a4.67,4.67,0,0,0-3.036-4.372ZM46.445,25.967,35.418,30.106V21.258l11.028-4.8ZM19.979,11.605,33.212,6.65l13.233,4.956v.078L33.212,17.054,19.979,11.683Zm10.9,37.766L19.85,54.885V44.623l11.028-5.034Zm0-14.53L17.644,40.212,4.411,34.841v-.078l13.233-4.956,13.233,4.956Zm31.137,14.53L50.986,54.885V44.623l11.028-5.034Zm0-14.53L48.781,40.212,35.548,34.841v-.078l13.233-4.956,13.233,4.956Z"
                                    transform="translate(0 -2.248)" fill="#7CA8DF" />
                            </svg>
                        </div>
                        <h2 style={styles.cardTitle}>
                            Simulateurs
                        </h2>
                    </div>
                    <div style={styles.cardBody}>
                        <ul>
                            {
                                categoriesOther.length > 0 ?
                                    categoriesOther["0"].simulateur.map(value => {
                                        if (value.id != 107 && value.id != 108) {
                                            return (
                                                <li style={styles.listElem}
                                                    onClick={() => {
                                                        if (dmtg.includes(value.id)) {
                                                            window.location = `/categorie/autres/dmtg/${value.id}`
                                                        } else if (others.includes(value.id)) {
                                                            window.location = `/categorie/autres/others/${value.id}`
                                                        }
                                                    }
                                                    }
                                                >
                                                    {value.name}
                                                </li>
                                            )
                                        }
                                    })
                                    :
                                    <></>
                            }
                        </ul>
                    </div>
                </div>


                <div style={styles.card}>
                    <div style={styles.cardHeader}>
                        <div style={styles.logo}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53">
                                <path id="Icon_ionic-ios-information-circle"
                                    data-name="Icon ionic-ios-information-circle"
                                    d="M29.875,3.375a26.5,26.5,0,1,0,26.5,26.5A26.5,26.5,0,0,0,29.875,3.375ZM32.3,42.106H27.429V23.747H32.3ZM29.862,21.747A2.543,2.543,0,1,1,32.5,19.2,2.554,2.554,0,0,1,29.862,21.747Z"
                                    transform="translate(-3.375 -3.375)" fill="#7CA8DF" />
                            </svg>
                        </div>
                        <h2 style={styles.cardTitle}>
                            Pour aller plus loin
                        </h2>
                    </div>
                    <div style={styles.cardBody}>
                        <ul>
                            <li style={styles.listElem} 
                            onClick={() => {
                                ReactGA.event({
                                    category: "HomePage",
                                    action: "Efl - Clic sur lien fiches pratiques home",
                                    label: "lien fiches pratiques home",
                                  });
                                window.location = `/fiches`
                            }}>
                                Fiches pratiques
                            </li>

                        </ul>
                    </div>
                </div>

            </div>


        </div>
    )


}

export default Home;
