import * as React from "react";
import Colors from "../../useclass/colors";
import {useCallback, useEffect, useRef, useState} from "react";
import axios from "axios";
import {API_BASE_URL, PARTNER_JWT, useForceUpdate} from "../../config";
import {useSnackbar} from 'notistack';
import {useDispatch, useSelector} from "react-redux";
import "../../stylesheets/simulateur.main.css";
import LinkOthersPDF from "../../utils/simulateurs/link.others.PDF";
import roundDecimal from "../../utils/roundDecimal";
import "./modal.css";
import close from "../../assets/images/close.png";
import "../../assets/fonts/Roboto/Roboto-Regular.ttf";
import useIsMountedRef from "../../utils/mountedRef";
import Simulateur_42 from "./simulateur.42/simulateur.42";
import Simulateur_105 from "./simulateur.105/simulateur.105";
import Simulateur_104 from "./simulateur.104/simulateur.104";
import Simulateur_103 from "./simulateur.103/simulateur.103";
import Simulateur_106 from "./simulateur.106/simulateur.106";

function OthersMain(props) {
    const {enqueueSnackbar} = useSnackbar();
    const dispatch = useDispatch();
    const [readyPDF, setReadyPDF] = useState(false);
    const isMountedRef = useIsMountedRef();
    const isMountedRefEmol = useIsMountedRef();
    const [parentSimulateur, setParentSimulateur] = useState({});
    const [departements, setDepartements] = useState("");
    const [createPDF, setCreatePDF] = useState(false);
    var data = useSelector((state) => state.simulateur);

    //var selectorDataStored = useSelector((state) => state.simulateur);
    //const [currentMontant, setCurrentMontant] = useState(0)
    //const [arrayContent, setArrayContent] = useState([])
    const [show, setShow] = useState(false);

    const [simulateurResponse, setSimulateurResponse] = useState([]);
    const [remarque, setRemarque] = useState("");
    let currUser = JSON.parse(localStorage.getItem("efluser"));

    const [simulateurObject, setSimulateurObject] = useState({
        data: {
            simulateurid: parentSimulateur.id,
            departementid: null,
            bureauid: parseInt(currUser.id),
            inputs: null,
        },
        results: {
            output: [],
        },
    });

    useEffect(() => {
        localStorage.setItem("remarque", remarque);
    }, [remarque]);

    useEffect(() => {
        simulateurObject.data.departementid = data.departementid;
        localStorage.removeItem("tempo");
    }, [data]);

    useEffect(() => {
        const items = {...localStorage};
        Object.keys(items).map((key, value) => {
            if (isNumeric(key.toString())) {
                localStorage.removeItem(key);
            }
        });
        localStorage.removeItem("outputAffinage");
        localStorage.removeItem("usageId");
        localStorage.removeItem("nameSimu");
    }, [isMountedRef]);

    const showHideClassName = show ? "modal display-block" : "modal display-none";

    var forceUpdate = useForceUpdate();


    const getSimulateur = useCallback(() => {
        axios.defaults.withCredentials = true;
        var config = {
            method: 'get', url: `${API_BASE_URL}/simulateurs/${props.match.params.simulateur}`, headers: {
                'Content-Type': 'application/json', 'Authorization': `${PARTNER_JWT}`,
            },
        };
        axios(config)
            .then((response) => {
                if (response.status === 200 && response.data != null) {
                    if (isMountedRef.current) setParentSimulateur(response.data)
                } else enqueueSnackbar('Une erreur est survenue', {
                    variant: 'error'
                });
            })
            .catch((err) => {
                enqueueSnackbar('Une erreur est survenue', {
                    variant: 'error'
                });
            });
    }, [isMountedRef])

    const styles = {
        container: {
            width: "100%",
            color: Colors.textBlack,
            minHeight: "75vh",
            backgroundColor: Colors.ultraLightGrey,
            fontFamily: "Regular",
        },
        ctnButton: {
            width: "90%",
            marginLeft: "auto",
            marginRight: "auto",
            paddingBottom: 30,
            paddingTop: 30,
            marginBottom: 150
        },
        ctnButtonValide: {
            marginTop: "30px",
            marginLeft: "auto",
            marginRight: "auto",
            paddingBottom: 30,
            paddingTop: 30,
            textAlign: "center",
            maxWidth: 250,
        },
        buttonCalculate: {
            display: "block",
            margin: "auto",
            width: "30%",
            height: 35,
            borderRadius: 5,
            border: "none",
            backgroundColor: "#49708C",
            color: Colors.white,
            fontWeight: "bold",
            cursor: "pointer",
            boxShadow: "2px 2px 1px rgba(0,0,0, 0.2)",
            hover: "#6399BF",
        },
        ctnTable: {
            width: "97%",
            marginTop: 10,
            marginBottom: 30,
            backgroundColor: Colors.white,
            //  boxShadow: "2px 2px 1px rgba(0,0,0, 0.2)",
            //width: "95%",
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 4,
            minWidth: 400,
        },

        ctnTableData: {
            width: "100%",
            textAlign: "left",
            display: "flex",
            flexDirection: "row",
            height: "100%",
            overflowY: "scroll",
        },

        ctnTableDataElemLeft: {
            width: "100%",
            border: "grey solid 1px",
            borderCollapse: "collapse",
            borderRadiusTopLeft: 3,
            borderRadiusBottomLeft: 3,
            borderRadiusBottomRight: 3,
            height: 35,
            marginBottom: 5,
        },
        ctnTableDataElemRight: {
            width: "100%",
            borderRight: "grey solid 1px",
            borderTop: "grey solid 1px",
            borderBottom: "grey solid 1px",
            borderCollapse: "collapse",
            borderRadius: 3,
            borderRadiusBottomLeft: 0,
            borderRadiusTopLeft: 0,
            height: 35,
        },
        ctnTableDataTxt: {
            lineHeight: 0.5,
            marginLeft: 10,
            fontSize: 16,
        },

        table: {
            width: "40%",
            borderCollapse: "collapse",
            marginRight: 5,
            border: "1px solid rgb(238, 236, 236)",
            margin: "auto",
        },

        tr: {
            borderCollapse: "collapse",
            borderRadius: 5,
            fontWeight: "bold",
            textAlign: "center",
        },

        td: {
            borderCollapse: "collapse",
            borderRadius: 5,
            paddingLeft: 10,
            height: 40,
            fontWeight: "bold",
            textAlign: "left",
            borderRight: "1px solid rgb(212, 212, 212)",
        },

        td2: {
            borderCollapse: "collapse",
            borderRadius: 5,
            paddingRight: 10,
            height: 40,
            fontWeight: "bold",
            textAlign: "right",
        },

        th: {
            borderCollapse: "collapse",
            borderRadius: 5,
            paddingLeft: 5,
            height: 45,
            textAlign: "center",
            fontStyle: "italic",
        },

        table2: {
            width: "100%",
            borderCollapse: "collapse",
            marginRight: 5,
            border: "1px solid rgb(238, 236, 236)",
        },

        thead: {
            backgroundColor: "rgb(216, 211, 201)",
        },

        td1form: {
            backgroundColor: "white",
            height: 45,
        },

        td2form: {
            backgroundColor: "rgb(245, 242, 240)",
            height: 45,
        },
    };

    const getDepart = useCallback(() => {
        var config = {
            method: 'get', url: `${API_BASE_URL}/departements`, headers: {
                'Content-Type': 'application/json', 'Authorization': `${PARTNER_JWT}`,
            },
        };
        axios(config)
            .then((response) => {
                if (response.status === 200 && response.data != null) {
                    if (isMountedRef.current) {
                        setDepartements(response.data)
                    }
                } else {
                    enqueueSnackbar('Une erreur est survenue', {
                        variant: 'error'
                    });
                }
            })
            .catch((err) => {
                enqueueSnackbar('Une erreur est survenue', {
                    variant: 'error'
                });
            });
    }, [isMountedRef])

    useEffect(() => {
        getDepart();
    }, [getDepart]);

    useEffect(() => {
        getSimulateur();
    }, [getSimulateur]);

    useEffect(() => {
        localStorage.setItem("nameSimu", parentSimulateur.name);
    }, [parentSimulateur]);

    async function check(data) {
        localStorage.setItem("content", JSON.stringify(data));
        return data;
    }

    function calculate() {
        simulateurObject["data"]["inputs"] = data.inputs;
        simulateurObject["data"]["departementid"] = data.departementid;
        simulateurObject["data"]["simulateurid"] = data.simulateurid;

        if (
            simulateurObject["data"]["departementid"] === undefined ||
            isNaN(simulateurObject["data"]["departementid"]) ||
            simulateurObject["data"]["departementid"] == 0
        ) {
            enqueueSnackbar(
                "Vous devez renseigner tous les champs afin de procéder au calcul",
                {
                    variant: "error",
                }
            );
            return;
        }

        check(simulateurObject["data"]).then((r) => {
            localStorage.setItem("requestSimu", JSON.stringify(r));

            var config = {
                method: 'post', url: `${API_BASE_URL}/calcul`, headers: {
                    'Content-Type': 'application/json', 'Authorization': `${PARTNER_JWT}`,
                }, data: r
            };
            axios(config)
                .then((response) => {
                    if (response.status === 200 && response.data != null) {
                        localStorage.setItem("usageId", response.data.usage_id)

                        setSimulateurResponse(response.data);

                        //   simulateurObject["results"]["output"] = (response.data)

                        var allData = [];
                        if (response.data.charges_total !== undefined) {
                            allData.push({
                                key: "charges_total",
                                name: "Total des charges",
                                value:
                                    roundDecimal(
                                        parseFloat(response.data.charges_total),
                                        2
                                    ).toString() + "€",
                            });
                        }
                        if (response.data.charges_prorata !== undefined) {
                            allData.push({
                                key: "charges_prorata",
                                name: "Charges de copropriété",
                                value:
                                    roundDecimal(
                                        parseFloat(response.data.charges_prorata),
                                        2
                                    ).toString() + "€",
                            });
                        }
                        if (response.data.plus_value !== undefined) {
                            allData.push({
                                key: "plus_value",
                                name: "Plus-Value",
                                value:
                                    roundDecimal(
                                        parseFloat(response.data.plus_value).toString(),
                                        2
                                    ) + "€",
                            });
                        }
                        if (response.data.taxe_fonciere !== undefined) {
                            allData.push({
                                key: "taxe_fonciere",
                                name: "Taxe Foncière",
                                value:
                                    roundDecimal(
                                        parseFloat(response.data.taxe_fonciere),
                                        2
                                    ).toString() + "€",
                            });
                        }
                        if (response.data.total_impots !== undefined) {
                            allData.push({
                                key: "total_impots",
                                name: "Total Impôts",
                                value:
                                    roundDecimal(
                                        parseFloat(response.data.total_impots),
                                        2
                                    ).toString() + "€",
                            });
                        }
                        if (response.data.Usufruit_1 !== undefined) {
                            allData.push({
                                key: "Usufruit_1",
                                name: "Usufruit - Donateur 1",
                                value:
                                    roundDecimal(
                                        parseFloat(response.data.Usufruit_1),
                                        2
                                    ).toString() + "€",
                            });
                        }
                        if (response.data.Nue_propriete_1 !== undefined) {
                            allData.push({
                                key: "Nue_propriete_1",
                                name: "Nue-Propriété - Donateur 1",
                                value:
                                    roundDecimal(
                                        parseFloat(response.data.Nue_propriete_1).toString(),
                                        2
                                    ) + "€",
                            });
                        }
                        if (response.data.Usufruit_2 !== undefined) {
                            allData.push({
                                key: "Usufruit_2",
                                name: "Usufruit - Donateur 2",
                                value:
                                    roundDecimal(
                                        parseFloat(response.data.Usufruit_2),
                                        2
                                    ).toString() + "€",
                            });
                        }
                        if (response.data.Nue_propriete_2 !== undefined) {
                            allData.push({
                                key: "Nue_propriete_2",
                                name: "Nue-Propriété - Donateur 2",
                                value:
                                    roundDecimal(
                                        parseFloat(response.data.Nue_propriete_2).toString(),
                                        2
                                    ) + "€",
                            });
                        }
                        if (response.data.montant_mensualites !== undefined) {
                            allData.push({
                                key: "montant_mensualites",
                                name: "Montant des mensualités",
                                value:
                                    roundDecimal(
                                        parseFloat(response.data.montant_mensualites).toString(),
                                        2
                                    ) + "€",
                            });
                        }
                        if (response.data.total_interets !== undefined) {
                            allData.push({
                                key: "total_interets",
                                name: "Total des intérêts",
                                value:
                                    roundDecimal(
                                        parseFloat(response.data.total_interets).toString(),
                                        2
                                    ) + "€",
                            });
                        }
                        if (response.data.impot_revenu !== undefined) {
                            allData.push({
                                key: "impot_revenu",
                                name: "Dont impôt sur le revenu",
                                value:
                                    roundDecimal(
                                        parseFloat(response.data.impot_revenu).toString(),
                                        2
                                    ) + "€",
                            });
                        }
                        if (response.data.prelevements_sociaux !== undefined) {
                            allData.push({
                                key: "prelevements_sociaux",
                                name: "Dont prélèvements sociaux",
                                value:
                                    roundDecimal(
                                        parseFloat(response.data.prelevements_sociaux),
                                        2
                                    ).toString() + "€",
                            });
                        }
                        if (response.data.taxe_additionnelle !== undefined) {
                            allData.push({
                                key: "taxe_additionnelle",
                                name: "Dont taxe additionnelle",
                                value:
                                    roundDecimal(
                                        parseFloat(response.data.taxe_additionnelle),
                                        2
                                    ).toString() + "€",
                            });
                        }
                        if (response.data.prix_vente !== undefined) {
                            allData.push({
                                key: "prix_vente",
                                name: "Prix de vente",
                                value:
                                    roundDecimal(
                                        parseFloat(response.data.prix_vente).toString(),
                                        2
                                    ) + "€",
                            });
                        }
                        if (response.data.honoraires_ht !== undefined) {
                            allData.push({
                                key: "honoraires_ht",
                                name: "Honoraires HT",
                                value:
                                    roundDecimal(
                                        parseFloat(response.data.honoraires_ht),
                                        2
                                    ).toString() + "€",
                            });
                        }
                        if (response.data.honoraires_ttc !== undefined) {
                            allData.push({
                                key: "honoraires_ttc",
                                name: "Honoraires TTC",
                                value:
                                    roundDecimal(
                                        parseFloat(response.data.honoraires_ttc),
                                        2
                                    ).toString() + "€",
                            });
                        }

                        simulateurObject["results"]["output"] = allData;
                        //setArrayContent(allData)
                        setCreatePDF(true);
                        //const base64Image = chartRef?.current?.chartInstance?.toBase64Image();

                        showModal();
                    } else {
                        enqueueSnackbar("Une erreur est survenue", {
                            variant: "error",
                        });
                    }
                })
                .catch((err) => {
                    enqueueSnackbar(
                        "Tous les champs n'ont pas été correctement renseignés.",
                        {
                            variant: "error",
                        }
                    );
                });
        });
    }

    function isNumeric(str) {
        if (typeof str != "string") return false;
        return !isNaN(str) && !isNaN(parseFloat(str));
    }

    function showModal() {
        setShow(true);
    }

    function hideModal() {
        setShow(false);
        setReadyPDF(false);
    }

    let [objValues, setObjValues] = useState({});

    useEffect(() => {
        objValues = simulateurObject["results"]["output"];

        // tablePrint()
    }, [simulateurObject["results"]["output"]]);

    function tablePrint() {
        for (let i = 0; i < simulateurObject["results"]["output"]?.length; i++) {
            objValues[simulateurObject["results"]["output"][`${i}`]["key"]] =
                simulateurObject["results"]["output"][`${i}`].value.replace("€", "");
        }

        let elem = null;
        elem = simulateurObject["results"]["output"];

        return (
            <div className={showHideClassName}>
                <div className="modal">
                    <div className="modal-main">
                        <>
                            {elem !== undefined ? (
                                <div style={styles.ctnTable}>
                                    <div style={styles.ctnTableData}>
                                        <div style={{flex: 6}}>
                                            <p
                                                style={{
                                                    textAlign: "center",
                                                    fontSize: 20,
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                Résultat de votre simulation
                                            </p>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    flexWrap: "wrap",
                                                    justifyContent: "space-around",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: "40%",
                                                        height: "40%",
                                                    }}
                                                >
                                                    <table
                                                        style={{
                                                            ...styles.table,
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <tbody>
                                                        {elem !== undefined ? (
                                                            elem.map((value, index) => {
                                                                var currentClass = {
                                                                    backgroundColor: "rgba(94,145,182,0.8)",
                                                                };
                                                                if (value.key == "charges_total") {
                                                                    currentClass = {backgroundColor: "white"};
                                                                }
                                                                if (value.key == "charges_prorata") {
                                                                    currentClass = {backgroundColor: "white"};
                                                                }
                                                                if (value.key == "plus_value") {
                                                                    currentClass = {backgroundColor: "white"};
                                                                }
                                                                if (value.key == "Usufruit_1") {
                                                                    currentClass = {
                                                                        backgroundColor: "rgba(94,145,182,0.8)",
                                                                    };
                                                                }
                                                                if (value.key == "Nue_Propriete_1") {
                                                                    currentClass = {
                                                                        backgroundColor: "rgba(94,145,182,0.8)",
                                                                    };
                                                                }
                                                                if (value.key == "Usufruit_2") {
                                                                    currentClass = {
                                                                        backgroundColor: "rgba(94,145,182,0.8)",
                                                                    };
                                                                }
                                                                if (value.key == "Nue_Propriete_2") {
                                                                    currentClass = {
                                                                        backgroundColor: "rgba(94,145,182,0.8)",
                                                                    };
                                                                }
                                                                if (value.key == "taxe_fonciere") {
                                                                    currentClass = {
                                                                        backgroundColor: "rgba(71,143,154,0.73)",
                                                                    };
                                                                }
                                                                if (value.key == "total_impots") {
                                                                    currentClass = {
                                                                        backgroundColor: "rgba(71,143,154,0.73)",
                                                                    };
                                                                }
                                                                if (
                                                                    value.key == "impot_revenu" ||
                                                                    value.key == "prelevements_sociaux" ||
                                                                    value.key == "taxe_additionnelle"
                                                                ) {
                                                                    currentClass = {
                                                                        backgroundColor: "rgba(71,143,154,0.73)",
                                                                    };
                                                                }
                                                                if (
                                                                    value.key == "Emol_total" ||
                                                                    value.key == "Major" ||
                                                                    value.key == "Emol_Form_Total"
                                                                ) {
                                                                    currentClass = {
                                                                        backgroundColor: "rgba(164,148,128,0.8)",
                                                                    };
                                                                }
                                                                if (
                                                                    value.key == "TVA_total" ||
                                                                    value.key == "Tresor_total"
                                                                ) {
                                                                    currentClass = {
                                                                        backgroundColor: "rgba(71,143,154,0.73)",
                                                                    };
                                                                }
                                                                if (value.key == "Debours_total") {
                                                                    currentClass = {
                                                                        backgroundColor: "rgba(214,127,98,0.71)",
                                                                    };
                                                                }
                                                                if (value.key == "Emol_Form") {
                                                                    currentClass["borderBottom"] =
                                                                        "1px solid rgb(238, 236, 236)";
                                                                }

                                                                if (elem[index + 1] !== undefined) {
                                                                    if (elem[index + 1].key === "Major") {
                                                                        currentClass["borderBottom"] = "";
                                                                    }
                                                                }
                                                                if (
                                                                    value.key == "Major" ||
                                                                    value.key == "Tresor_total"
                                                                ) {
                                                                    currentClass["borderBottom"] =
                                                                        "1px solid rgb(238, 236, 236)";
                                                                }
                                                                simulateurObject["results"]["output"] = elem;

                                                                return (
                                                                    <>
                                                                        <tr style={currentClass}>
                                                                            <td
                                                                                style={{
                                                                                    ...styles.td,
                                                                                    paddingLeft:
                                                                                        value.key == "Major" ||
                                                                                        value.key == "Emol_Form"
                                                                                            ? 25
                                                                                            : 10,
                                                                                    fontStyle:
                                                                                        value.key == "Major" ||
                                                                                        value.key == "Emol_Form"
                                                                                            ? "italic"
                                                                                            : "",
                                                                                }}
                                                                            >
                                                                                {value.name}
                                                                            </td>
                                                                            <td style={styles.td2}>
                                                                                {value.value}
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                );
                                                            })
                                                        ) : (
                                                            <></>
                                                        )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            {buttonPrint()}
                                        </div>

                                        <img
                                            src={`${close}`}
                                            style={{
                                                position: "absolute",
                                                fontWeight: "bold",
                                                right: 20,
                                                height: 20,
                                                cursor: "pointer",
                                            }}
                                            onClick={(e) => {
                                                setReadyPDF(false);
                                                hideModal();
                                            }}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                        </>
                    </div>
                </div>
            </div>
        );
    }

    function buttonPrint() {
        return (
            <>
                <div style={{...styles.ctnButtonValide, width: "50%"}}>
                    {createPDF === true ? (
                        <LinkOthersPDF
                            simulateurObject={simulateurObject}
                            ready={readyPDF}
                            setReadyMain={setReadyPDF}
                        />
                    ) : (
                        <></>
                    )}
                </div>
            </>
        );
    }

    function printCalculateResult() {
        if (parserSimulator().props.children !== "Simulateur non disponible") {
            return (
                <>
                    <div style={styles.ctnButton}>
                        <button
                            style={styles.buttonCalculate}
                            onClick={(e) => {
                                calculate();
                            }}
                        >
                            Calculer
                        </button>
                    </div>
                    {tablePrint()}
                </>
            );
        } else return <></>;
    }

    function parserSimulator() {
        switch (props.match.params.simulateur) {
            case "42": {
                return (
                    <>
                        <Simulateur_42
                            simulateur={parentSimulateur}
                            isParent={true}
                            departements={departements}
                        />
                    </>
                );
            }
            case "103": {
                return (
                    <>
                        <Simulateur_103
                            simulateur={parentSimulateur}
                            isParent={true}
                            departements={departements}
                        />
                    </>
                );
            }
            case "104": {
                return (
                    <>
                        <Simulateur_104
                            simulateur={parentSimulateur}
                            isParent={true}
                            departements={departements}
                        />
                    </>
                );
            }
            case "105": {
                return (
                    <>
                        <Simulateur_105
                            simulateur={parentSimulateur}
                            isParent={true}
                            departements={departements}
                        />
                    </>
                );
            }
            case "106": {
                return (
                    <>
                        <Simulateur_106
                            simulateur={parentSimulateur}
                            isParent={true}
                            departements={departements}
                        />
                    </>
                );
            }
            default: {
                return <h2>Simulateur non disponible</h2>;
            }
        }
    }

    // SWITCH WITH ID SIMULATEUR
    return (
        <div style={styles.container}>
            {parserSimulator()}
            {printCalculateResult()}
        </div>
    );
}

export default OthersMain;
