import { Document, Font, Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import * as React from "react";
import fontRobotoRegular from "../../assets/fonts/Roboto/Roboto-Regular.ttf";
import fontRobotoLight from "../../assets/fonts/Roboto/Roboto-Light.ttf";
import fontRobotoBold from "../../assets/fonts/Roboto/Roboto-Bold.ttf";
import fontRobotoMedium from "../../assets/fonts/Roboto/Roboto-Medium.ttf";
import fontRobotoItalic from "../../assets/fonts/Roboto/Roboto-MediumItalic.ttf";
import moment from "moment/moment";

Font.register({ family: "RobotoRegular", src: fontRobotoRegular });
Font.register({ family: "RobotoLight", src: fontRobotoLight });
Font.register({ family: "RobotoMedium", src: fontRobotoMedium });
Font.register({ family: "RobotoBold", src: fontRobotoBold });
Font.register({ family: "RobotoItalic", src: fontRobotoItalic });
Font.registerHyphenationCallback((word) => [word]);

let newDate = new Date();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
let day = newDate.getDate();

if (month < 10) {
  month = "0" + month;
}

if (day < 10) {
  day = "0" + day;
}
const currentDate = `${day}/${month}/${year}`;

const styles = StyleSheet.create({
  fontRegular: { fontFamily: "RobotoRegular" },
  fontLight: { fontFamily: "RobotoLight" },
  fontBold: { fontFamily: "RobotoBold" },
  fontMedium: { fontFamily: "RobotoMedium" },
  blockDebours: {
    width: 225,
    maxHeight: 25,
    marginTop: 20,
    marginRight: 0,
    marginLeft: 0,
    flex: 0.5,
    flexDirection: "row",
    border: "1px solid grey",
    borderRadius: 3,
    paddingBottom: 24,
  },
  blockEmoluments: {
    width: 225,
    marginLeft: 0,
    marginRight: 0,
    flexDirection: "column",
    border: "1px solid grey",
    borderRadius: 3,
    minHeight: 60,
    height: "auto",
    maxHeight: 130,
  },
  blockFiscalite: {
    width: 225,
    marginRight: 0,
    marginLeft: 0,
    flex: 0.5,
    flexDirection: "column",
    border: "1px solid grey",
    borderRadius: 3,
    minHeight: 80,
  },
  blockFiscalite2: {
    width: 225,
    marginRight: 0,
    marginLeft: 0,
    flex: 0.5,
    flexDirection: "column",
    border: "1px solid grey",
    borderRadius: 3,
    minHeight: 100,
  },
  blockLeft: {
    position: "relative",
    marginLeft: "10%",
    marginRight: 0,
    width: "100%",
  },
  blockRight: {
    position: "relative",
    marginLeft: 0,
    marginRight: "5%",
    width: "100%",
  },
  bodyCtnRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    alignContent: "space-between",
    marginTop: 15,
    width: "100%",
  },
  bodyFooterMedium: {
    fontFamily: "RobotoMedium",
    fontSize: 8,
    marginTop: 10,
    textAlign: "justify",
  },
  bodyFooterCtn: {
    width: "80%",
    marginLeft: "10%",
    position: "absolute",
    bottom: 15,
    left: 0,
    right: 0,
    objectPosition: "bottom",
  },
  bodyFooterLight: {
    fontFamily: "RobotoLight",
    fontSize: 6,
    marginTop: 5,
  },
  bodyFooterMapped: {
    fontSize: 8,
    marginTop: 4,
  },
  bodyFooterMappedItalic: {
    fontSize: 8,
    marginTop: 4,
    fontFamily: "RobotoItalic",
  },
  bodyFooterMappedTitle: {
    fontSize: 8,
    marginTop: 4,
    fontFamily: "RobotoBold",
  },
  containerTitleSimu: {
    position: "relative",
    marginTop: 20,
    marginRight: "auto",
    marginLeft: "auto",
    width: "75%",
    maxHeight: 50,
    overflow: "hidden",
  },
  ctnChart: {
    position: "absolute",
    bottom: 220,
    width: 170,
    height: 180,
    left: "35%",
  },
  ctnEmol: {
    width: 150,
    height: 20,
    fontFamily: "RobotoRegular",
    textAlign: "left",
    fontSize: 10,
    paddingLeft: 5,
  },
  ctnResultatsNb: {
    width: "60%",
    height: 25,
    fontFamily: "RobotoBold",
    fontSize: 12,
    paddingTop: 5,
    textAlign: "right",
  },
  ctnResultatsNb2: {
    width: 75,
    fontFamily: "RobotoBold",
    fontSize: 12,
    textAlign: "right",
  },
  ctnResultatsNb3: {
    width: "60%",
    height: 20,
    fontFamily: "RobotoBold",
    fontSize: 12,
    paddingTop: 1,
    textAlign: "right",
  },
  ctnResultatsNbTop: {
    width: "60%",
    height: 25,
    fontFamily: "RobotoBold",
    fontSize: 12,
    paddingTop: 5,
    borderBottom: "1px solid grey",
    textAlign: "right",
  },
  ctnResultatsTxt: {
    width: "100%",
    flex: 1,
    flexDirection: "row",
    justifyItems: "right",
    marginBottom: 25,
  },
  ctnResultatsTxt2: {
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignContent: "space-between",
    justifyContent: "space-between",
  },
  debours: {
    width: 100,
    height: 25,
    fontFamily: "RobotoBold",
    fontSize: 12,
    paddingLeft: 5,
    paddingTop: 5,
    backgroundColor: "rgba(214,127,98,0.71)",
    borderTopLeftRadius: 2,
    borderBottom: "1px solid grey",
  },
  dmtgResults: {
    fontWeight: "normal",
    fontSize: 10,
    paddingTop: 5,
    paddingBottom: 5,
  },
  donataireBlock: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginTop: "5px",
    marginBottom: "15px",
    border: "1px solid #71ADD9",
    borderRadius: 3,
    width: "80%",
    marginLeft: "10%",
    fontFamily: "RobotoBold",
    height: "auto",
    minHeight: "150px",
  },
  donataireTitle: {
    height: "100%",
    width: "auto",
    fontSize: 13,
    color: "white",
    backgroundColor: "#71ADD9",
    textAlign: "center",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 40,
  },
  donateurBlock: {
    flex: 1,
    border: "1px solid rgb(157 114 169)",
    borderRadius: 3,
    margin: 10,
    maxWidth: "45%",
  },
  donateurResults: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  donateurTitle: {
    width: "100%",
    textAlign: "center",
    color: "white",
    backgroundColor: "rgb(157 114 169)",
    fontSize: 12,
    padding: 10,
  },
  emoluments: {
    width: "70%",
    height: 25,
    fontFamily: "RobotoBold",
    fontSize: 12,
    paddingLeft: 5,
    paddingTop: 5,
    backgroundColor: "rgba(164,148,128,0.8)",
    borderTopLeftRadius: 2,
    borderBottom: "1px solid grey",
  },
  headerAdress: {
    fontSize: 10,
    fontFamily: "RobotoRegular",
  },
  headerDate: {
    marginTop: 40,
    marginBottom: 20,
    fontSize: 9.5,
    fontFamily: "RobotoRegular",
  },
  headerName: {
    fontSize: 11,
    fontFamily: "RobotoBold",
    wordBreak: "keep-all",
  },
  headerRight: {
    marginLeft: "50%",
    marginTop: 5,
    marginRight: 40,
    fontSize: 12,
    textAlign: "right",
    lineHeight: 1.5,
    wordBreak: "keep-all",
  },
  heritierBlock: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginTop: 5,
    marginBottom: 15,
    border: "1px solid #71ADD9",
    borderRadius: 3,
    width: "80%",
    marginLeft: "10%",
    fontFamily: "RobotoBold",
    height: "auto",
  },
  heritierBlock2: {
    flex: 1,
    margin: 10,
    maxWidth: "100%",
  },
  heritierTitle: {
    height: "100%",
    width: "auto",
    fontSize: 13,
    color: "white",
    backgroundColor: "#71ADD9",
    textAlign: "center",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 20,
  },
  page: {
    paddingTop: 20,
    paddingBottom: 50,
  },
  rappel: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    columnGap: 50,
    width: "50%",
    height: 150,
    marginLeft: "10%",
    marginRight: "10%",
    position: "absolute",
    bottom: 60,
  },
  rappelBlockDonataire: {
    marginTop: 5,
    marginBottom: 10,
  },
  rappelDMTG: {
    position: "relative",
    width: "80%",
    marginLeft: "10%",
    // marginTop: 175,
    marginTop: 30,
    marginBottom: 50,
    paddingBottom: 150,
  },
  rappelDonateurTitle: {
    fontSize: 10,
    marginTop: 5,
    fontWeight: "bold",
    color: "rgb(157 114 169)",
  },
  rappelDonataireTitle: {
    fontSize: 9,
    fontWeight: "bold",
    color: "#71ADD9",
  },
  remarque: {
    border: "1px solid grey",
    borderRadius: 3,
    width: "80%",
    marginLeft: "10%",
    fontFamily: "RobotoRegular",
    fontSize: 10,
    marginTop: 15,
    lineHeight: 2,
    maxHeight: 40,
    overflow: "hidden",
  },
  remarqueText: {
    marginTop: 8,
    marginLeft: 5,
  },
  resultatsNb: {
    textAlign: "right",
    position: "relative",
    right: 7,
  },
  resultatsNb2: {
    fontSize: 12,
    textAlign: "right",
    position: "relative",
    right: 7,
  },
  resultsBlock: {
    marginTop: 5,
    marginBottom: 10,
  },
  resultsTitle: {
    fontSize: 14,
    paddingLeft: "10%",
    width: "100%",
    marginBottom: 10,
    marginTop: 15,
  },
  resultatsTxt: {
    width: 100,
    height: 25,
    fontFamily: "RobotoRegular",
    textAlign: "left",
    fontSize: 10,
    paddingLeft: 30,
    paddingTop: 5,
  },
  resultatsTxt2: {
    width: 170,
    height: 20,
    fontFamily: "RobotoRegular",
    textAlign: "left",
    fontSize: 10,
    paddingLeft: 30,
    paddingTop: 1,
  },
  resultatsTxt3: {
    width: 100,
    height: 20,
    fontFamily: "RobotoLight",
    textAlign: "left",
    fontSize: 10,
    paddingLeft: 30,
    paddingTop: 1,
  },
  superEmol: {
    height: "auto",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    marginTop: 5,
    marginBottom: 5,
  },
  taxes: {
    width: 100,
    height: 25,
    fontFamily: "RobotoBold",
    fontSize: 12,
    paddingLeft: 5,
    paddingTop: 5,
    backgroundColor: "rgba(71,143,154,0.73)",
    borderTopLeftRadius: 2,
    borderBottom: "1px solid grey",
  },
  titleSimu: {
    fontSize: 13,
    textAlign: "center",
    fontFamily: "RobotoBold",
    wordBreak: "keep-all",
  },
  ttc: {
    border: "1px solid grey",
    borderRadius: 3,
    width: "80%",
    marginLeft: "10%",
    fontFamily: "RobotoBold",
    fontSize: 14,
    marginTop: 10,
    height: 45,
  },
  ttcInternCtn: {
    flex: 1,
    flexDirection: "row",
    marginTop: 15,
  },
  ttcLeft: {
    paddingLeft: 5,
    width: "70%",
  },
  ttcRight: {
    paddingRight: 5,
    width: "30%",
    textAlign: "center",
  },
});

const GeneratePdfFile = ({ simulateurObject }) => {
  let remarque = localStorage.getItem("remarque");

  let currUser = JSON.parse(localStorage.getItem("efluser"));

  let requestSimu = JSON.parse(localStorage.getItem("requestSimu"));

  let nameSimu = localStorage.getItem("nameSimu");

  let result;
  if (simulateurObject["results"]["custom"].length > 0) {
    result = simulateurObject["results"]["custom"];
  } else {
    result = simulateurObject["results"]["output"];
  }

  let servitudes = [34, 35];

  let valeurSPF = [81, 82];

  let simuSuccession = [55];

  let dmtgInputs = null;

  if (requestSimu?.DMTG?.length > 0) {
    dmtgInputs = JSON.parse(localStorage.getItem("dmtgInputs"));
  }

  // console.log("------—————————————")
  // console.log("SIMULATEUR OBJECT ::vv")
  // console.log(simulateurObject)
  // console.log("------—————————————")
  //
  // console.log("------—————————————")
  // console.log("simulateurObject.data")
  // console.log(simulateurObject.data)
  // console.log("------—————————————")
  //
  // console.log("------—————————————")
  // console.log("REQUEST SIMU")
  // console.log(requestSimu)
  // console.log("------—————————————")

  let simu84Index = null;
  let simu89Index = null;
  let simu90Index = null;

  if (simulateurObject?.data?.dependances?.length > 0) {
    for (let i = 0; i < simulateurObject.data.dependances.length; i++) {
      if (simulateurObject.data.dependances[i].simulateurid === 84)
        simu84Index = i;
      if (simulateurObject.data.dependances[i].simulateurid === 89)
        simu89Index = i;
      if (simulateurObject.data.dependances[i].simulateurid === 90)
        simu90Index = i;
    }
  }

  function checkAndTransformNullToZero(field) {
    if (field !== null && field !== undefined) return field;
    else return 0;
  }

  const RenderChart = () => (
    <Image
      source={{ uri: localStorage.getItem("chart") }}
      style={{
        width: "100%",
        // height: 460
      }}
    />
  );

  const formatMontant = (value) => {
    return parseInt(value).toLocaleString("en-EN").replace(/,/g, " ") + " €";
  };

  return (
    // <PDFViewer style={{width: "98vw", height: "98vh"}}>
    <Document>
      {/*  P1  */}
      <Page size="A4" style={styles.page}>
        <View style={styles.headerRight}>
          <View style={styles.headerDate}>
            <Text>Le : {currentDate}</Text>
          </View>
        </View>
        <View style={styles.containerTitleSimu}>
          <Text style={styles.titleSimu}>{nameSimu}</Text>
        </View>
        <View style={styles.remarque}>
          <Text style={styles.remarqueText}>Remarques : {remarque}</Text>
        </View>

        <View style={styles.ttc}>
          <View style={styles.ttcInternCtn}>
            <Text style={styles.ttcLeft}>
              Estimation du montant total de vos frais :
            </Text>

            <Text style={styles.ttcRight}>
              {(() => {
                for (let i = 0; i < result.length; i++) {
                  if (result[i].key === "Provision_total") {
                    const formattedValue = parseInt(result[i].value)
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ");
                    return (formattedValue + " €").toString();
                  }
                }
                return "";
              })()}
            </Text>
          </View>
        </View>

        <View style={styles.bodyCtnRow}>
          <View style={styles.blockLeft}>
            <View style={styles.blockEmoluments}>
              <View style={styles.ctnResultatsTxt}>
                {requestSimu.simulateurid === 32 ||
                requestSimu.simulateurid === 83 ? (
                  <Text style={styles.emoluments}>Honoraires HT</Text>
                ) : (
                  <Text style={styles.emoluments}>Total Emoluments</Text>
                )}
                <View style={styles.ctnResultatsNbTop}>
                  <Text style={styles.resultatsNb}>
                    {(() => {
                      for (let i = 0; i < result.length; i++) {
                        if (result[i].key === "Emol_total") {
                          const formattedValue = parseInt(result[i].value)
                            .toLocaleString("en-EN")
                            .replace(/,/g, " ");
                          return (formattedValue + " € HT").toString();
                        }
                      }
                      return "";
                    })()}
                  </Text>
                </View>
              </View>

              <View style={styles.ctnResultatsTxt2}>
                {requestSimu.hasOwnProperty("dependances") &&
                requestSimu.simulateurid !== 34 &&
                requestSimu.simulateurid !== 35 &&
                requestSimu.simulateurid !== 81 &&
                requestSimu.simulateurid !== 82 ? (
                  <View style={styles.superEmol}>
                    <Text style={styles.ctnEmol}>Vente</Text>
                    <View style={styles.ctnResultatsNb2}>
                      <Text style={styles.resultatsNb2}>
                        {(() => {
                          for (let i = 0; i < result.length; i++) {
                            if (result[i].key === "emoluments_actes") {
                              const formattedValue = parseInt(result[i].value)
                                .toLocaleString("en-EN")
                                .replace(/,/g, " ");
                              return (formattedValue + " € HT").toString();
                            }
                          }
                          return "";
                        })()}
                      </Text>
                    </View>
                  </View>
                ) : requestSimu.simulateurid === 32 ||
                  requestSimu.simulateurid === 83 ? (
                  <View style={styles.superEmol}>
                    <Text style={styles.ctnEmol}>Honoraires HT</Text>
                    <View style={styles.ctnResultatsNb2}>
                      <Text style={styles.resultatsNb2}>
                        {(() => {
                          for (let i = 0; i < result.length; i++) {
                            if (result[i].key === "Honoraires") {
                              const formattedValue = parseInt(result[i].value)
                                .toLocaleString("en-EN")
                                .replace(/,/g, " ");
                              return (formattedValue + " € HT").toString();
                            }
                          }
                          return "";
                        })()}
                      </Text>
                    </View>
                  </View>
                ) : (
                  <View style={styles.superEmol}>
                    <Text style={styles.ctnEmol}>Emoluments d'acte</Text>
                    <View style={styles.ctnResultatsNb2}>
                      <Text style={styles.resultatsNb2}>
                        {(() => {
                          for (let i = 0; i < result.length; i++) {
                            if (result[i].key === "emoluments_actes_total") {
                              const formattedValue = parseInt(result[i].value)
                                .toLocaleString("en-EN")
                                .replace(/,/g, " ");
                              return (formattedValue + " € HT").toString();
                            }
                          }
                          return "";
                        })()}
                      </Text>
                    </View>
                  </View>
                )}

                {requestSimu.hasOwnProperty("dependances") ? (
                  requestSimu?.dependances.map((dependance, index) => {
                    console.log(
                      JSON.stringify(requestSimu?.dependances[index])
                    );
                    if (dependance.simulateurid === 84) {
                      return (
                        <View style={styles.superEmol}>
                          <Text style={styles.ctnEmol}>Prêt</Text>
                          <View style={styles.ctnResultatsNb2}>
                            <Text style={styles.resultatsNb2}>
                              {parseInt(
                                requestSimu?.dependances[index].output[
                                  "emoluments_actes_total"
                                ]
                              )
                                .toLocaleString("en-EN")
                                .replace(/,/g, " ") + " € HT"}
                            </Text>
                          </View>
                        </View>
                      );
                    } else if (dependance.simulateurid === 85) {
                      return (
                        <View style={styles.superEmol}>
                          <Text style={styles.ctnEmol}>Prêt</Text>
                          <View style={styles.ctnResultatsNb2}>
                            <Text style={styles.resultatsNb2}>
                              {parseInt(
                                requestSimu?.dependances[index].output[
                                  "emoluments_actes_total"
                                ]
                              )
                                .toLocaleString("en-EN")
                                .replace(/,/g, " ") + " € HT"}
                            </Text>
                          </View>
                        </View>
                      );
                    } else if (dependance.simulateurid === 92) {
                      return (
                        <View style={styles.superEmol}>
                          <Text style={styles.ctnEmol}>Prêt</Text>
                          <View style={styles.ctnResultatsNb2}>
                            <Text style={styles.resultatsNb2}>
                              {parseInt(
                                requestSimu?.dependances[index].output[
                                  "emoluments_actes_total"
                                ]
                              )
                                .toLocaleString("en-EN")
                                .replace(/,/g, " ") + " € HT"}
                            </Text>
                          </View>
                        </View>
                      );
                    } else if (dependance.simulateurid === 89) {
                      return (
                        <View style={styles.superEmol}>
                          <Text style={styles.ctnEmol}>
                            Cautionnement sans garantie
                          </Text>
                          <View style={styles.ctnResultatsNb2}>
                            <Text style={styles.resultatsNb2}>
                              {parseInt(
                                requestSimu?.dependances[index].output[
                                  "emoluments_actes_total"
                                ]
                              )
                                .toLocaleString("en-EN")
                                .replace(/,/g, " ") + " € HT"}
                            </Text>
                          </View>
                        </View>
                      );
                    } else if (dependance.simulateurid === 90) {
                      return (
                        <View style={styles.superEmol}>
                          <Text style={styles.ctnEmol}>
                            Cautionnement avec garantie
                          </Text>
                          <View style={styles.ctnResultatsNb2}>
                            <Text style={styles.resultatsNb2}>
                              {parseInt(
                                requestSimu?.dependances[index].output[
                                  "emoluments_actes_total"
                                ]
                              )
                                .toLocaleString("en-EN")
                                .replace(/,/g, " ") + " € HT"}
                            </Text>
                          </View>
                        </View>
                      );
                    } else {
                      <></>;
                    }
                  })
                ) : (
                  <></>
                )}

                <View style={styles.superEmol}>
                  {requestSimu.simulateurid === 32 ||
                  requestSimu.simulateurid === 83 ? (
                    <></>
                  ) : (
                    <Text style={styles.ctnEmol}>Emoluments de formalités</Text>
                  )}
                  <View style={styles.ctnResultatsNb2}>
                    <Text style={styles.resultatsNb2}>
                      {(() => {
                        for (let i = 0; i < result.length; i++) {
                          //console.log(result[i].key)
                          if (result[i].key === "Emol_Form_Total") {
                            //console.log(result[i].value)
                            if (result[i].value !== "écrêtés") {
                              const formattedValue = parseInt(result[i].value)
                                .toLocaleString("en-EN")
                                .replace(/,/g, " ");
                              return (formattedValue + " € HT").toString();
                            } else {
                              return result[i].value;
                            }
                          }
                        }
                        return "";
                      })()}
                    </Text>
                  </View>
                </View>

                {simulateurObject?.data?.inputs?.promesse === 1 ? (
                  <View style={styles.superEmol}>
                    <Text style={styles.ctnEmol}>
                      Honoraires de la promesse
                    </Text>
                    <View style={styles.ctnResultatsNb2}>
                      <Text style={styles.resultatsNb2}>
                        {parseInt(requestSimu.inputs["montant_promesse"])
                          .toLocaleString("en-EN")
                          .replace(/,/g, " ") + " € HT"}
                      </Text>
                    </View>
                  </View>
                ) : (
                  <></>
                )}
              </View>
            </View>
          </View>

          <View style={styles.blockRight}>
            {simulateurObject?.data?.DMTG?.length > 0 ? (
              <View style={styles.blockFiscalite2}>
                <View style={styles.ctnResultatsTxt}>
                  <Text style={styles.taxes}>Total Fiscalité</Text>
                  <View style={styles.ctnResultatsNbTop}>
                    <Text style={styles.resultatsNb}>
                      {(() => {
                        let tva = 0;
                        let tresor = 0;
                        for (let i = 0; i < result.length; i++) {
                          if (result[i].key === "TVA_total")
                            tva = parseFloat(result[i].value);
                          else if (result[i].key === "Tresor_total") {
                            tresor = parseFloat(result[i].value);
                          }
                        }
                        const formattedValue = parseInt(
                          (tva + tresor).toFixed(2)
                        )
                          .toLocaleString("en-EN")
                          .replace(/,/g, " ");
                        return (formattedValue + " €").toString();
                      })()}
                    </Text>
                  </View>
                </View>

                <View style={styles.ctnResultatsTxt}>
                  <Text style={styles.resultatsTxt}>Trésor</Text>
                  <View style={styles.ctnResultatsNb}>
                    <Text style={styles.resultatsNb}>
                      {(() => {
                        for (let i = 0; i < result.length; i++) {
                          if (result[i].key === "Tresor_total") {
                            const formattedValue = parseInt(result[i].value)
                              .toLocaleString("en-EN")
                              .replace(/,/g, " ");
                            return (formattedValue + " €").toString();
                          }
                        }
                        return "";
                      })()}
                    </Text>
                  </View>
                </View>
                <View style={styles.ctnResultatsTxt}>
                  <Text style={styles.resultatsTxt3}>Dont DMTG</Text>
                  <View style={styles.ctnResultatsNb3}>
                    <Text style={styles.resultatsNb}>
                      {(() => {
                        for (let i = 0; i < result.length; i++) {
                          if (result[i].key === "DMTG") {
                            const formattedValue = parseInt(result[i].value)
                              .toLocaleString("en-EN")
                              .replace(/,/g, " ");
                            return (formattedValue + " €").toString();
                          }
                        }
                        return "";
                      })()}
                    </Text>
                  </View>
                </View>

                <View style={styles.ctnResultatsTxt}>
                  <Text style={styles.resultatsTxt2}>TVA</Text>
                  <View style={styles.ctnResultatsNb3}>
                    <Text style={styles.resultatsNb}>
                      {(() => {
                        for (let i = 0; i < result.length; i++) {
                          if (result[i].key === "TVA_total") {
                            const formattedValue = parseInt(result[i].value)
                              .toLocaleString("en-EN")
                              .replace(/,/g, " ");
                            return (formattedValue + " €").toString();
                          }
                        }
                        return "";
                      })()}
                    </Text>
                  </View>
                </View>
              </View>
            ) : (
              <View style={styles.blockFiscalite}>
                <View style={styles.ctnResultatsTxt}>
                  <Text style={styles.taxes}>Total Fiscalité</Text>
                  <View style={styles.ctnResultatsNbTop}>
                    <Text style={styles.resultatsNb}>
                      {(() => {
                        let tva = 0;
                        let tresor = 0;
                        for (let i = 0; i < result.length; i++) {
                          if (result[i].key === "TVA_total") {
                            tva = parseFloat(result[i].value);
                          } else if (result[i].key === "Tresor_total") {
                            tresor = parseFloat(result[i].value);
                          }
                        }
                        const formattedValue = parseInt(
                          (tva + tresor).toFixed(2)
                        )
                          .toLocaleString("en-EN")
                          .replace(/,/g, " ");
                        return (formattedValue + " €").toString();
                      })()}
                    </Text>
                  </View>
                </View>

                <View style={styles.ctnResultatsTxt}>
                  <Text style={styles.resultatsTxt}>Trésor</Text>
                  <View style={styles.ctnResultatsNb}>
                    <Text style={styles.resultatsNb}>
                      {(() => {
                        for (let i = 0; i < result.length; i++) {
                          if (result[i].key === "Tresor_total") {
                            const formattedValue = parseInt(result[i].value)
                              .toLocaleString("en-EN")
                              .replace(/,/g, " ");
                            return (formattedValue + " €").toString();
                          }
                        }
                        return "";
                      })()}
                    </Text>
                  </View>
                </View>

                <View style={styles.ctnResultatsTxt}>
                  {requestSimu.simulateurid === 32 ||
                  requestSimu.simulateurid === 83 ? (
                    <Text style={styles.resultatsTxt2}>TVA sur honoraires</Text>
                  ) : (
                    <Text style={styles.resultatsTxt2}>TVA sur émoluments</Text>
                  )}
                  <View style={styles.ctnResultatsNb}>
                    <Text style={styles.resultatsNb}>
                      {(() => {
                        for (let i = 0; i < result.length; i++) {
                          if (result[i].key === "TVA_total") {
                            const formattedValue = parseInt(result[i].value)
                              .toLocaleString("en-EN")
                              .replace(/,/g, " ");
                            return (formattedValue + " €").toString();
                          }
                        }
                        return "";
                      })()}
                    </Text>
                  </View>
                </View>
              </View>
            )}

            <View style={styles.blockDebours}>
              <Text style={styles.debours}>Debours</Text>
              <View style={styles.ctnResultatsNb}>
                <Text style={styles.resultatsNb}>
                  {(() => {
                    for (let i = 0; i < result.length; i++) {
                      if (result[i].key === "Debours_total") {
                        const formattedValue = parseInt(result[i].value)
                          .toLocaleString("en-EN")
                          .replace(/,/g, " ");
                        return (formattedValue + " €").toString();
                      }
                    }
                    return "";
                  })()}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.ctnChart}>
          <RenderChart />
        </View>

        {/* MAP FOR INPUTS*/}

        <View style={styles.rappel}>
          <Text style={styles.bodyFooterMappedTitle}>
            Rappel de votre simulation :
          </Text>
          {requestSimu?.departementid !== 0 ? (
            <Text style={styles.bodyFooterMapped}>
              Département concerné :{" "}
              {requestSimu.departementid < 10
                ? `0${requestSimu.departementid}`
                : requestSimu.departementid}{" "}
              {/*TODO Afficher le nom du département*/}
            </Text>
          ) : (
            <></>
          )}
          {Object.keys(requestSimu?.inputs).map((key) => {
            //console.log("key : " + key)
            if (key === "taxe_ile_de_france") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Taxe IDF 0,6% : Oui
                  </Text>
                );
              }
            } else if (key === "zrr") {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>ZRR : Oui</Text>;
              }
            } else if (key === "acte_en_main") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Acte en main : Oui
                  </Text>
                );
              } else if (requestSimu.inputs[key] === 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Acte en main : Non
                  </Text>
                );
              }
            } else if (key === "montant_vente") {
              return (
                <Text style={styles.bodyFooterMapped}>
                  Montant de la vente :{" "}
                  {parseInt(requestSimu.inputs[key])
                    .toLocaleString("en-EN")
                    .replace(/,/g, " ") + " €"}
                </Text>
              );
            } else if (key === "montant_cautionnement") {
              return (
                <Text style={styles.bodyFooterMapped}>
                  Montant du cautionnement :{" "}
                  {parseInt(requestSimu.inputs[key])
                    .toLocaleString("en-EN")
                    .replace(/,/g, " ") + " €"}
                </Text>
              );
            } else if (key === "montant_meuble") {
              return (
                <Text style={styles.bodyFooterMapped}>
                  Montant des meubles :{" "}
                  {parseInt(requestSimu.inputs[key])
                    .toLocaleString("en-EN")
                    .replace(/,/g, " ") + " €"}
                </Text>
              );
            } else if (key === "montant_cautionnement") {
              return (
                <Text style={styles.bodyFooterMapped}>
                  Montant du cautionnement :{" "}
                  {parseInt(requestSimu.inputs[key])
                    .toLocaleString("en-EN")
                    .replace(/,/g, " ") + " €"}
                </Text>
              );
            } else if (key === "promesse") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <>
                    <Text style={styles.bodyFooterMapped}>
                      Promesse : Oui {"\n"}
                    </Text>
                    <Text style={styles.bodyFooterMapped}>
                      Montant de la promesse :{" "}
                      {parseInt(requestSimu.inputs["montant_promesse"])
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                  </>
                );
              }
            } else if (key === "urbanisme") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <>
                    <Text style={styles.bodyFooterMapped}>
                      Urbanisme : Oui {"\n"}
                    </Text>
                    <Text style={styles.bodyFooterMapped}>
                      Montant urbanisme :{" "}
                      {parseInt(requestSimu.inputs["montant_urbanisme"])
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                  </>
                );
              }
            } else if (key === "pretHypo") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Prêt hypothécaire : Oui
                  </Text>
                );
              } else if (requestSimu.inputs[key] === 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Prêt hypothécaire : Non
                  </Text>
                );
              }
            } else if (key === "sansGarantieHypo") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Cautionnement sans garantie : Oui
                  </Text>
                );
              } else if (requestSimu.inputs[key] === 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Cautionnement sans garantie : Non
                  </Text>
                );
              }
            } else if (key === "avecGarantieHypo") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Cautionnement avec garantie : Oui
                  </Text>
                );
              } else if (requestSimu.inputs[key] === 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Cautionnement avec garantie : Non
                  </Text>
                );
              }
            } else if (key === "montant_acquisition") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant de l'acquisition :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_retrocession") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant rétrocession ou substitution :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "pacte_preference") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Pacte de préférence : Oui
                  </Text>
                );
              } else if (requestSimu.inputs[key] === 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Pacte de préférence : Non
                  </Text>
                );
              }
            } else if (key === "action_resolutoire") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Action résolutoire ou droit de délaissement : Oui
                  </Text>
                );
              } else if (requestSimu.inputs[key] === 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Action résolutoire ou droit de délaissement : Non
                  </Text>
                );
              }
            } else if (key === "type_immeuble") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Type d'immeuble : En état d’achèvement ou neuf
                  </Text>
                );
              } else if (requestSimu.inputs[key] === 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Type d'immeuble : Ancien
                  </Text>
                );
              }
            } else if (key === "nombre_lots") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Nombre de lots à créer : {requestSimu.inputs[key]}
                  </Text>
                );
              }
            } else if (key === "nombre_diagnostics") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Nombre de diagnostics : {requestSimu.inputs[key]}
                  </Text>
                );
              }
            } else if (key === "montant_exigible") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant de la partie exigible :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "tva_concerne") {
              if (requestSimu.inputs[key] === 0.2) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Taux de TVA concerné : 20%
                  </Text>
                );
              } else if (requestSimu.inputs[key] === 0.1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Taux de TVA concerné : 10%
                  </Text>
                );
              } else if (requestSimu.inputs[key] === 0.085) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Taux de TVA concerné : 8,5% (DOM)
                  </Text>
                );
              } else if (requestSimu.inputs[key] === 0.055) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Taux de TVA concerné : 5,5%
                  </Text>
                );
              } else if (requestSimu.inputs[key] === 0.021) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Taux de TVA concerné : 2,1% (DOM)
                  </Text>
                );
              } else if (requestSimu.inputs[key] === 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Taux de TVA concerné : 0%
                  </Text>
                );
              }
            } else if (key === "montant_lot_1") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant lot le plus élevé :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_lot_2") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant lot le plus faible :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_lot") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant lots 1 ou 2 :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_meuble_1") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant des meubles :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_meuble_2") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant des meubles :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_licitee") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant de la part licitée :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_immeuble") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur totale de l’immeuble :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_acquis") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant des parts acquises :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_loyer_annuel") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant du loyer annuel :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "duree_bail") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Durée du bail : {requestSimu.inputs[key]}
                  </Text>
                );
              }
            } else if (key === "nombre_page_copie") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Nombre de page(s) : {requestSimu.inputs[key]}
                  </Text>
                );
              }
            } else if (key === "montant_loyer") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant cumulé des loyers :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "valeur_residuelle") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur résiduelle des constructions :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_loyer_mensuel") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant du loyer mensuel :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_loyer_versements") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant annuel des loyers et versements :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "nombre_diagnostic") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Nombre diagnostics immobiliers : {requestSimu.inputs[key]}
                  </Text>
                );
              }
            } else if (key === "montant_charges") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant cumulé des charges :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_honoraires") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant HT des honoraires :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_cession") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant fixé pour la cession :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "regime_cession") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Régime de la cession : Régime spécial
                  </Text>
                );
              } else if (requestSimu.inputs[key] === 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Régime de la cession : Régime droit commun
                  </Text>
                );
              }
            } else if (key === "acquisition_zone_geographique") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Acquisition en zone géographique spécifique : Oui
                  </Text>
                );
              }
            } else if (key === "cession_fond_artisanal") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Cession en pleine propriété d’un fonds artisanal : Oui
                  </Text>
                );
              }
            } else if (
              key === "cession_fond_artisanal" &&
              requestSimu.inputs["conditions_speciales"] === 1
            ) {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Respect des conditions spéciales : Oui
                  </Text>
                );
              } else if (requestSimu.inputs[key] === 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Respect des conditions spéciales : Non
                  </Text>
                );
              }
            } else if (key === "acquisition_brevet") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Acquisition de brevet ou marque : Oui
                  </Text>
                );
              }
            } else if (
              key === "brevet_vendu_seul" &&
              requestSimu.inputs["acquisition_brevet"] === 1
            ) {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Brevet vendu : Seul
                  </Text>
                );
              } else if (requestSimu.inputs[key] === 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Brevet vendu : Avec le fonds
                  </Text>
                );
              }
            } else if (
              key === "brevet_vendu_seul" &&
              requestSimu.inputs["brevet_exploite"] === 1
            ) {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Brevet exploité : Oui
                  </Text>
                );
              } else if (requestSimu.inputs[key] === 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Brevet exploité : Non
                  </Text>
                );
              }
            } else if (key === "marchandises_neuves") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Vente de marchandises neuves : Oui
                  </Text>
                );
              }
            } else if (key === "nombre_servitude") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Nombre servitudes : {requestSimu.inputs[key]}
                  </Text>
                );
              }
            } else if (key === "prix_acte") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Prix exprimé dans l'acte : Oui
                  </Text>
                );
              }
            } else if (key === "valeur_servitude") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur servitude(s) à créer :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "facturation_forfait") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Facturation de plusieurs forfaits : Oui
                  </Text>
                );
              }
            } else if (key === "nombre_forfait") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Nombre de forfaits : {requestSimu.inputs[key]}
                  </Text>
                );
              }
            } else if (key === "valeur_residuelle") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur résiduelle :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "valeur_venale") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur vénale :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_investissement") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant investissement :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "duree_bail") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Durée du contrat : {requestSimu.inputs[key]}
                  </Text>
                );
              }
            } else if (key === "nombre_copie_executoire") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Nombre de pages : {requestSimu.inputs[key]}
                  </Text>
                );
              }
            } else if (key === "montant_loyer_mensuel_ht") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant HT mensuel des loyers :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "loyer_tva") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Loyers soumis à TVA : Oui
                  </Text>
                );
              }
            } else if (
              key === "loyer_tva_taux" &&
              requestSimu.inputs["loyer_tva"] === 1
            ) {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>Taux de TVA : 20%</Text>
                );
              } else if (requestSimu.inputs[key] === 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Taux de TVA : 5,5%
                  </Text>
                );
              }
            } else if (key === "montant_frais_financier") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant des frais financiers :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "terrain_a_batir") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Terrain à batir : Oui
                  </Text>
                );
              }
            } else if (key === "engagement_construire") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Engagement de construire : Oui
                  </Text>
                );
              }
            } else if (key === "type_calcul") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Calcul : Depuis la base demandée par le vendeur
                  </Text>
                );
              } else if (requestSimu.inputs[key] === 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Calcul : Depuis le prix comprenant les honoraires de
                    négociation
                  </Text>
                );
              }
            } else if (key === "montant_forfait") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant forfaitaire minimum :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_demande") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant demandé :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "taux_remuneration") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Rémunération souhaitée :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "archivage") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>Archivage : Oui</Text>
                );
              } else if (requestSimu.inputs[key] === 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>Archivage : Non</Text>
                );
              }
            } else if (key === "valeur_transmise") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur transmise :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "nombre_page") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Nombre de pages : {requestSimu.inputs[key]}
                  </Text>
                );
              }
            } else if (key === "etat_civil") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Pièces d’état-civil demandées : Oui
                  </Text>
                );
              }
            } else if (key === "comedec") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Recours à Comedec : Oui
                  </Text>
                );
              }
            } else if (key === "nombre_comedec_2") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Nombre de Comedec à 3,61€ : {requestSimu.inputs[key]}
                  </Text>
                );
              }
            } else if (key === "nombre_comedec_1") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Nombre de Comedec à 0,61€ : {requestSimu.inputs[key]}
                  </Text>
                );
              }
            } else if (key === "nombre_copie_authentique") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Nombre pages "copie authentique" : {requestSimu.inputs[key]}
                  </Text>
                );
              }
            } else if (key === "nombre_copie_libre") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Nombre pages "copie libre" : {requestSimu.inputs[key]}
                  </Text>
                );
              }
            } else if (key === "nombre_archivage") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Nombre pages "archivage numérisé" :{" "}
                    {requestSimu.inputs[key]}
                  </Text>
                );
              }
            } else if (key === "reserve_usufruit") {
              if (requestSimu.inputs[key] === 1) {
                if (requestSimu.inputs["age_usufruitier_2"] === 0) {
                  return (
                    <>
                      <Text style={styles.bodyFooterMapped}>
                        Réserve d'usufruit : Oui {"\n"}
                      </Text>
                      <Text style={styles.bodyFooterMapped}>
                        Âge de l'Usufruitier :{" "}
                        {requestSimu.inputs["age_usufruitier_1"]}
                      </Text>
                    </>
                  );
                } else {
                  return (
                    <>
                      <Text style={styles.bodyFooterMapped}>
                        Réserve d'usufruit : Oui {"\n"}
                      </Text>
                      <Text style={styles.bodyFooterMapped}>
                        Âge du premier Usufruitier :{" "}
                        {requestSimu.inputs["age_usufruitier_1"]} {"\n"}
                      </Text>
                      <Text style={styles.bodyFooterMapped}>
                        Âge du second Usufruitier :{" "}
                        {requestSimu.inputs["age_usufruitier_2"]}
                      </Text>
                    </>
                  );
                }
              } else if (requestSimu.inputs[key] === 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Réserve d'usufruit : Non
                  </Text>
                );
              }
            } else if (key === "nombre_donateurs") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Nombre donateurs : Un donateur
                  </Text>
                );
              } else if (requestSimu.inputs[key] === 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Nombre donateurs : Deux donateurs
                  </Text>
                );
              }
            } else if (key === "montant_donateur_1") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Masse donnée donateur 1 :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_donateur_2") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Masse donnée donateur 2 :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "donation_especes") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Donation d'espèces : Oui
                  </Text>
                );
              } else if (requestSimu.inputs[key] === 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Donation d'espèces : Non
                  </Text>
                );
              }
            } else if (key === "montant_especes_1") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant espèces donateur 1 :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_especes_2") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant espèces donateur 2 :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "nombre_spf") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Nombre SPF : {requestSimu.inputs[key]}
                  </Text>
                );
              }
            } else if (key === "montant_fiscal_1") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Masse immobilière donnée donateur 1 :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_fiscal_2") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Masse immobilière donnée donateur 2 :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "reversion_usufruit") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Clause de réversion d'usufruit : Oui
                  </Text>
                );
              } else {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Clause de réversion d'usufruit : Non
                  </Text>
                );
              }
            } else if (key === "DMTG") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <>
                    <Text style={styles.bodyFooterMapped}>
                      Calcul des DMTG : Oui
                    </Text>
                    <Text style={styles.bodyFooterMappedItalic}>
                      Vous trouverez le détail de votre calcul de DMTG ci-après
                    </Text>
                  </>
                );
              } else if (requestSimu.inputs[key] === 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Calcul des DMTG : Non
                  </Text>
                );
              }
            } else if (key === "nombre_donateurs") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Donation : Cumulative si prédécès ou réalisée par un
                    donateur
                  </Text>
                );
              } else if (requestSimu.inputs[key] === 2) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Donation : conjonctive
                  </Text>
                );
              }
            } else if (key === "montant_rapports") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant donations à rapporter et à réincorporer :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_rapports_especes") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Dont espèces :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_donateur_1") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Somme donnée donateur 1 :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_donateur_2") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Somme donnée donateur 2 :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_NP_1") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant Nue-Propriété donnée donateur 1 :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_NP_2") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant Nue-Propriété donnée donateur 2 :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_succession") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant de la succession :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "premier_deces") {
              if (requestSimu.inputs[key] === 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Premier décès d’une communauté : Oui
                  </Text>
                );
              } else if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Premier décès d’une communauté : Non
                  </Text>
                );
              }
            } else if (key === "valeur_biens_communs") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur biens communs transmis :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "valeur_biens_propres") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur biens propres transmis :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "valeur_biens_transmis") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur biens transmis :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "nombre_publicite_fonciere") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Nombre SPF : {requestSimu.inputs[key]}
                  </Text>
                );
              }
            } else if (key === "valeur_biens_propres_1") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur biens propres 1er époux transmis :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "valeur_biens_propres_2") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur biens propres 2nd époux transmis :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "masse_brute") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Masse brute partagée :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "actif_net") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Actif net partagé :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "valeur_cumulee") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur cumulée biens immobiliers partagés :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "droit_partage_reduit") {
              if (requestSimu.inputs[key] === 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Droit partage concerné : Normal
                  </Text>
                );
              } else if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Droit partage concerné : Réduit
                  </Text>
                );
              }
            } else if (key === "montant_soulte") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant de la soulte :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_passif") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant du passif :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "codicilles") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Codicille(s) déposé(s) conservé(s) à l’étude : Oui
                  </Text>
                );
              }
            } else if (key === "nombre_codicilles") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Nombre de codicilles déposés : {requestSimu.inputs[key]}
                  </Text>
                );
              }
            } else if (key === "envoi_possession") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Envoi en possession : Oui
                  </Text>
                );
              }
            } else if (key === "valeur_bien") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur bien immobilier :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "valeur_biens_declares") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur biens déclarés et/ou apportés :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "formalites_publication") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Formalités de publication au SPF : Oui
                  </Text>
                );
              }
            } else if (key === "valeur_biens_immo") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur bien immobilier :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "nombre_enfants") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Nombre enfants majeurs : {requestSimu.inputs[key]}
                  </Text>
                );
              }
            } else if (key === "montant_partage") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant actif brut à partager :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_partage_brut") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant actif brut à partager :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_partage_net") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant actif net partagé :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "valeur_biens_partages") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur biens immobiliers partagés :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "nombre_parts_partage") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Nombre parts sociales faisant l’objet du partage partiel :
                    {requestSimu.inputs[key]}
                  </Text>
                );
              }
            } else if (key === "nombre_parts_capital") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Nombre parts sociales composant le capital social :{" "}
                    {requestSimu.inputs[key]}
                  </Text>
                );
              }
            } else if (key === "montant_honoraire") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant honoraire HT :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "valeur_biens_apportes") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur biens immobiliers apportés :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "valeur_spf") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur bien apporté au SPF n°1 [n] :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "apport_pur_simple") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Dont apport à titre pur et simple :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "apport_onereux") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Dont apport à titre onéreux :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "pret_realise") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Prêt réalisé : Dans l'acte de valeur_transmise
                  </Text>
                );
              } else if (requestSimu.inputs[key] === 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Prêt réalisé : Par acte séparé
                  </Text>
                );
              }
            } else if (key === "montant_pret") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant prêt ou capitaux empruntés :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_pret_hp") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant garanti par une hypothèque légale de préteur de
                    deniers :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_pret_hc") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant garanti par l'Hypothèque Conventionnelle :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_pret") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant du prêt :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "cautionnement_realise") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Cautionnement réalisé : Dans l'acte de prêt
                  </Text>
                );
              } else if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Cautionnement réalisé : Par acte séparé
                  </Text>
                );
              }
            } else if (key === "montant_cautionnement") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant cautionnement :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "nantissement_realise") {
              if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Nantissement réalisé : Dans l'acte de prêt
                  </Text>
                );
              } else if (requestSimu.inputs[key] === 1) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Nantissement réalisé : Par acte séparé
                  </Text>
                );
              }
            } else if (key === "montant_nantissement") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant cautionnement :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_creance") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant créance :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_pouvoir") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Frais de pouvoir :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_quittance") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant quittance :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_prorogation") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant prorogation :{" "}
                    {parseInt(requestSimu.inputs[key])
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            }
          })}

          {requestSimu.hasOwnProperty("dependances") ? (
            requestSimu?.dependances.map((dependance, index) => {
              console.log(JSON.stringify(requestSimu?.dependances[index]));
              if (
                servitudes.includes(requestSimu?.simulateurid) &&
                requestSimu?.inputs["prix_acte"] === 1
              ) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Prix de la servitude {index + 1} :{" "}
                    {parseInt(requestSimu?.dependances[index].prix_servitude)
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              } else if (valeurSPF.includes(requestSimu?.simulateurid)) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant du SPF {index + 1} :{" "}
                    {parseInt(requestSimu?.dependances[index].valeur_spf)
                      .toLocaleString("en-EN")
                      .replace(/,/g, " ") + " €"}
                  </Text>
                );
              } else if (dependance.simulateurid === 84) {
                return (
                  <View>
                    {requestSimu?.dependances[index].inputs["pret_realise"] ===
                    1 ? (
                      <Text style={styles.bodyFooterMapped}>
                        Prêt réalisé : Par acte séparé
                      </Text>
                    ) : requestSimu?.dependances[index].inputs[
                        "pret_realise"
                      ] === 0 ? (
                      <Text style={styles.bodyFooterMapped}>
                        Prêt réalisé : Dans l'acte de vente
                      </Text>
                    ) : (
                      <></>
                    )}
                    <Text style={styles.bodyFooterMapped}>
                      Montant prêt ou capitaux empruntés :{" "}
                      {parseInt(
                        requestSimu?.dependances[index].inputs["montant_pret"]
                      )
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                    <Text style={styles.bodyFooterMapped}>
                      Montant garanti par une hypothèque légale de préteur de
                      deniers :{" "}
                      {parseInt(
                        requestSimu?.dependances[index].inputs[
                          "montant_pret_hp"
                        ]
                      )
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                    <Text style={styles.bodyFooterMapped}>
                      Montant garanti par l'Hypothèque Conventionnelle :{" "}
                      {parseInt(
                        requestSimu?.dependances[index].inputs[
                          "montant_pret_hc"
                        ]
                      )
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                  </View>
                );
              } else if (dependance.simulateurid === 85) {
                return (
                  <View>
                    {requestSimu?.dependances[index].inputs["pret_realise"] ===
                    1 ? (
                      <Text style={styles.bodyFooterMapped}>
                        Prêt réalisé : Par acte séparé
                      </Text>
                    ) : requestSimu?.dependances[index].inputs[
                        "pret_realise"
                      ] === 0 ? (
                      <Text style={styles.bodyFooterMapped}>
                        Prêt réalisé : Dans l'acte de vente
                      </Text>
                    ) : (
                      <></>
                    )}
                    <Text style={styles.bodyFooterMapped}>
                      Montant total du prêt ou capitaux empruntés :{" "}
                      {parseInt(
                        requestSimu?.dependances[index].inputs["montant_pret"]
                      )
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                    <Text style={styles.bodyFooterMapped}>
                      Montant du prêt soumis à un prêt aidé :{" "}
                      {parseInt(
                        requestSimu?.dependances[index].inputs[
                          "montant_pret_hp"
                        ]
                      )
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                    <Text style={styles.bodyFooterMapped}>
                      Montant garanti par l'Hypothèque Conventionnelle :{" "}
                      {parseInt(
                        requestSimu?.dependances[index].inputs[
                          "montant_pret_hc"
                        ]
                      )
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                  </View>
                );
              } else if (dependance.simulateurid === 92) {
                return (
                  <View>
                    {requestSimu?.dependances[index].inputs["pret_realise"] ===
                    1 ? (
                      <Text style={styles.bodyFooterMapped}>
                        Prêt réalisé : Par acte séparé
                      </Text>
                    ) : requestSimu?.dependances[index].inputs[
                        "pret_realise"
                      ] === 0 ? (
                      <Text style={styles.bodyFooterMapped}>
                        Prêt réalisé : Dans l'acte de vente
                      </Text>
                    ) : (
                      <></>
                    )}
                    <Text style={styles.bodyFooterMapped}>
                      Montant prêt ou capitaux empruntés :{" "}
                      {parseInt(
                        requestSimu?.dependances[index].inputs["montant_pret"]
                      )
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                    <Text style={styles.bodyFooterMapped}>
                      Montant garanti par une hypothèque légale de préteur de
                      deniers :{" "}
                      {parseInt(
                        requestSimu?.dependances[index].inputs[
                          "montant_pret_hp"
                        ]
                      )
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                    <Text style={styles.bodyFooterMapped}>
                      Montant garanti par l'Hypothèque Conventionnelle :{" "}
                      {parseInt(
                        requestSimu?.dependances[index].inputs[
                          "montant_pret_hc"
                        ]
                      )
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                  </View>
                );
              } else if (dependance.simulateurid === 89) {
                return (
                  <View>
                    {requestSimu?.dependances[index].inputs[
                      "cautionnement_realise"
                    ] === 1 ? (
                      <Text style={styles.bodyFooterMapped}>
                        Acte de cautionnement réalisé : Par acte séparé
                      </Text>
                    ) : requestSimu?.dependances[index].inputs[
                        "cautionnement_realise"
                      ] === 0 ? (
                      <Text style={styles.bodyFooterMapped}>
                        Acte de cautionnement réalisé : Dans l'acte principal
                      </Text>
                    ) : (
                      <></>
                    )}
                    <Text style={styles.bodyFooterMapped}>
                      Montant cautionnement sans garantie :{" "}
                      {parseInt(
                        requestSimu?.dependances[index].inputs[
                          "montant_cautionnement"
                        ]
                      )
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                  </View>
                );
              } else if (dependance.simulateurid === 90) {
                return (
                  <View>
                    {requestSimu?.dependances[index].inputs[
                      "cautionnement_realise"
                    ] === 1 ? (
                      <Text style={styles.bodyFooterMapped}>
                        Acte de cautionnement réalisé : Par acte séparé
                      </Text>
                    ) : requestSimu?.dependances[index].inputs[
                        "cautionnement_realise"
                      ] === 0 ? (
                      <Text style={styles.bodyFooterMapped}>
                        Acte de cautionnement réalisé : Dans l'acte principal
                      </Text>
                    ) : (
                      <></>
                    )}
                    <Text style={styles.bodyFooterMapped}>
                      Montant cautionnement avec garantie :{" "}
                      {parseInt(
                        requestSimu?.dependances[index].inputs[
                          "montant_cautionnement"
                        ]
                      )
                        .toLocaleString("en-EN")
                        .replace(/,/g, " ") + " €"}
                    </Text>
                  </View>
                );
              }
            })
          ) : (
            <></>
          )}
        </View>

        <View style={styles.bodyFooterCtn} fixed>
          <Text style={styles.bodyFooterMedium}>
            IMPORTANT : Les informations et les résultats présentés ci-dessus
            sont donnés à titre indicatif et ne peuvent en aucun cas constituer
            un document à caractère contractuel. Les frais indiqués doivent être
            adressés par virement au minimum 48h-72h à l'avance.
          </Text>
          <Text style={styles.bodyFooterLight}>
            Sources : Éditions Langloÿs - Le Barème Rapide® {year}
          </Text>
        </View>
      </Page>
      {/*  END P1  */}

      {/* restitution des DMTG */}

      {requestSimu?.DMTG?.length > 0 ? (
          <Page style={styles.page}>
            <View>
              <Text style={styles.resultsTitle}>Résultat de votre calcul de DMTG :</Text>

              <View style={styles.resultsBlock}>
                {requestSimu?.DMTG.map((array, donataire) => {
                  if (simuSuccession.includes(dmtgInputs.data[0].donataires[0].simulateurid)) {
                    return (
                        <>
                          {array?.donataires.map((object, donataire) => {
                            let output = object.output;

                            const orderedKeys = [
                              "DMTG",
                              "base_dmtg",
                              "reliquat_total",
                              "immo",
                              "especes",
                              "abattement_total",
                              "abattement_restant",
                              "tranche_initiale",
                              "reliquat_tranche_donation",
                              "tranche_finale"
                            ];


                            return (
                                <>
                                  <View style={styles.heritierBlock} wrap={false}>
                                    <Text style={styles.heritierTitle}>Héritier {donataire + 1}</Text>
                                    <View style={styles.heritierBlock2} wrap={false}>
                                      <View style={styles.donateurResults}>
                                        {orderedKeys.map((line) => {
                                          if (line === "DMTG" && output["DMTG"] !== undefined) {
                                            return (
                                                <Text style={styles.dmtgResults} key="DMTG">
                                                  Droits DMTG à payer :
                                                  {parseInt(output["DMTG"]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                                                </Text>
                                            );
                                          } else if (line === "base_dmtg" && output["base_dmtg"] !== undefined) {
                                            return (
                                                <Text style={styles.dmtgResults} key="base_dmtg">
                                                  Base Taxable :
                                                  {parseInt(output["base_dmtg"]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                                                </Text>
                                            );
                                          } else if (line === "reliquat_total" && parseInt(output["reliquat_total"]) !== 0 && output["reliquat_total"] !== undefined) {
                                            return (
                                                <Text style={styles.dmtgResults} key="reliquat_total">
                                                  Déduction faite du reliquat total des parts de tranches non utilisées lors des donations antérieures à 2012 :
                                                  {parseInt(output["reliquat_total"]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                                                </Text>
                                            );
                                          } else if (line === "immo" && output["immo"] !== undefined) {
                                            return (
                                                <Text style={styles.dmtgResults} key="immo">
                                                  Montant Immobilier :
                                                  {parseInt(output["immo"]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                                                </Text>
                                            );
                                          } else if (line === "especes" && output["especes"] !== undefined) {
                                            return (
                                                <Text style={styles.dmtgResults} key="especes">
                                                  Montant Espèces :
                                                  {parseInt(output["especes"]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                                                </Text>
                                            );
                                          }  else if (line === "abattement_total") {
                                            return (
                                                <Text style={styles.dmtgResults}>
                                                  Abattement appliqué :
                                                  {parseInt(output["abattement_total"])
                                                      .toLocaleString("en-EN")
                                                      .replace(/,/g, " ") + " €"}
                                                </Text>
                                            );
                                          }
                                          else if (line === "abattement_restant" && output["abattement_restant"] !== undefined) {
                                            return (
                                                <Text style={styles.dmtgResults} key="abattement_restant">
                                                  Abattement légal restant :
                                                  {parseInt(output["abattement_restant"]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                                                </Text>
                                            );
                                          }else if (line === "tranche_initiale" && output["tranche_initiale"] !== undefined) {
                                            return (
                                                <Text style={styles.dmtgResults}>
                                                  Taux tranche initiale :
                                                  {(output["tranche_initiale"] * 100).toLocaleString("en-EN") + " %"}
                                                </Text>
                                            );
                                          }
                                          else if (line === "reliquat_tranche_donation" && output["reliquat_tranche_donation"] !== undefined) {
                                            return (
                                                <Text style={styles.dmtgResults}>
                                                  Reliquat de tranche initiale non utilisé lors des donations antérieures :
                                                  {parseInt(output["reliquat_tranche_donation"]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                                                </Text>
                                            );
                                          }
                                          else if (line === "tranche_finale" && output["tranche_finale"] !== undefined) {
                                            return (
                                                <Text style={styles.dmtgResults}>
                                                  Taux tranche finale :
                                                  {(output["tranche_finale"] * 100).toLocaleString("en-EN") + " %"}
                                                </Text>
                                            );
                                          }

                                          return null; // Si aucune condition n'est remplie
                                        })}
                                      </View>
                                    </View>
                                  </View>
                                </>
                            );
                          })}
                        </>
                    );
                  } else {
                    return (
                        <>
                          <View style={styles.globalBlock}>
                            <View style={styles.donataireTitle}>
                              <Text>Donateur {donataire + 1}</Text>
                            </View>
                            <View style={styles.donataireBlock}>
                              {array?.donataires.map((object, donateur) => {
                                let output = object.output;
                                return (
                                    <>
                                      <View style={styles.donateurBlock} wrap={false}>
                                        <Text style={styles.donateurTitle}>Donataire {donateur + 1}</Text>
                                        <View style={styles.donateurResults}>
                                          {Object.keys(output).map((line, index) => {
                                            if (line === "DMTG") {
                                              return (
                                                  <Text style={styles.dmtgResults}>
                                                    Droits DMTG à payer :
                                                    {parseInt(output["DMTG"]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                                                  </Text>
                                              );
                                            } else if (line === "base_dmtg") {
                                              return (
                                                  <Text style={styles.dmtgResults}>
                                                    Base Taxable :
                                                    {parseInt(output["base_dmtg"]).toLocaleString("en-EN").replace(/,/g, " ") +
                                                    " €"}
                                                  </Text>
                                              );
                                            } else if (line === "reliquat_total" && parseInt(output["reliquat_total"]) !== 0) {
                                              return (
                                                  <Text style={styles.dmtgResults}>
                                                    Déduction faite du reliquat total des parts de tranches non utilisées lors des donations antérieures à 2012 :
                                                    {parseInt(output["reliquat_total"])
                                                        .toLocaleString("en-EN")
                                                        .replace(/,/g, " ") + " €"}
                                                  </Text>
                                              );
                                            } else if (line === "immo") {
                                              return (
                                                  <Text style={styles.dmtgResults}>
                                                    Montant Immobilier :
                                                    {parseInt(output["immo"]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                                                  </Text>
                                              );
                                            } else if (line === "especes") {
                                              return (
                                                  <Text style={styles.dmtgResults}>
                                                    Montant Espèces :
                                                    {parseInt(output["especes"]).toLocaleString("en-EN").replace(/,/g, " ") +
                                                    " €"}
                                                  </Text>
                                              );
                                            } else if (line === "abattement_total") {
                                              return (
                                                  <Text style={styles.dmtgResults}>
                                                    Abattement appliqué :
                                                    {parseInt(output["abattement_total"])
                                                        .toLocaleString("en-EN")
                                                        .replace(/,/g, " ") + " €"}
                                                  </Text>
                                              );
                                            } else if (line === "abattement_restant") {
                                              return (
                                                  <Text style={styles.dmtgResults}>
                                                    Abattement légal restant :
                                                    {parseInt(output["abattement_restant"])
                                                        .toLocaleString("en-EN")
                                                        .replace(/,/g, " ") + " €"}
                                                  </Text>
                                              );
                                            }
                                            else if (line === "reliquat_tranche_donation" && output["reliquat_tranche_donation"] !== undefined) {
                                              return (
                                                  <Text style={styles.dmtgResults}>
                                                    Reliquat de tranche initiale non utilisé lors des donations antérieures :
                                                    {parseInt(output["reliquat_tranche_donation"]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                                                  </Text>
                                              );
                                            } else if (line === "tranche_finale") {
                                              return (
                                                  <>
                                                    <Text style={styles.dmtgResults}>
                                                      Taux tranche initiale :
                                                      {(output["tranche_initiale"] * 100).toLocaleString("en-EN") + " %"}
                                                    </Text>
                                                    <Text style={styles.dmtgResults}>
                                                      Taux tranche finale :
                                                      {(output["tranche_finale"] * 100).toLocaleString("en-EN") + " %"}
                                                    </Text>
                                                  </>
                                              );
                                            }
                                            return <></>;
                                          })}
                                        </View>
                                      </View>
                                    </>
                                );
                              })}
                            </View>
                          </View>
                        </>
                    );
                  }
                })}
              </View>

              <View style={styles.rappelDMTG}>
                <Text style={styles.bodyFooterMappedTitle}>Rappel de votre calcul de DMTG :</Text>

                {dmtgInputs?.data?.map((array, donateur) => {
                  if (simuSuccession.includes(dmtgInputs.data[0].donataires[0].simulateurid)) {
                    return (
                        <>
                          {dmtgInputs?.data[donateur]?.donataires?.map((inputs, donataire) => {
                            return (
                                // DMTG successions
                                <>
                                  <View style={styles.rappelBlockDonataire} wrap={false}>
                                    <Text style={styles.rappelDonataireTitle}>Héritier {donataire + 1}</Text>
                                    {Object.keys(dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs)?.map((key) => {
                                      if (key === "lien_parente_donation") {
                                        if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 1) {
                                          return (
                                              <Text style={styles.bodyFooterMapped}>
                                                Lien de parenté : Entre époux ou partenaires pacsés
                                              </Text>
                                          );
                                        } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 2) {
                                          return (
                                              <Text style={styles.bodyFooterMapped}>
                                                Lien de parenté : En ligne directe - Ascendants ou enfants
                                              </Text>
                                          );
                                        } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 3) {
                                          return (
                                              <Text style={styles.bodyFooterMapped}>
                                                Lien de parenté : Entre frères et sœurs
                                              </Text>
                                          );
                                        } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 4) {
                                          return (
                                              <Text style={styles.bodyFooterMapped}>
                                                Lien de parenté : Aux neveux et nièces
                                              </Text>
                                          );
                                        } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 5) {
                                          return (
                                              <Text style={styles.bodyFooterMapped}>Lien de parenté : Aux petits-enfants</Text>
                                          );
                                        } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 6) {
                                          return (
                                              <Text style={styles.bodyFooterMapped}>
                                                Lien de parenté : Aux arrières petits-enfants
                                              </Text>
                                          );
                                        } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 7) {
                                          return (
                                              <Text style={styles.bodyFooterMapped}>
                                                Lien de parenté : Entre parents au-dela du 4ème degré et non parents
                                              </Text>
                                          );
                                        }
                                      } else if (key === "lien_parente_succession") {
                                        if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 1) {
                                          return (
                                              <Text style={styles.bodyFooterMapped}>
                                                Lien de parenté : En ligne directe - Ascendants ou enfants
                                              </Text>
                                          );
                                        } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 2) {
                                          if (
                                              dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[
                                                  "conditions_particulieres"
                                                  ] === 1
                                          ) {
                                            return (
                                                <Text style={styles.bodyFooterMapped}>
                                                  Lien de parenté : Entre frères et sœurs Conditions particulières : Oui
                                                </Text>
                                            );
                                          } else if (
                                              dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[
                                                  "conditions_particulieres"
                                                  ] === 0
                                          ) {
                                            return (
                                                <Text style={styles.bodyFooterMapped}>
                                                  Lien de parenté : Entre frères et sœurs Conditions particulières : Non
                                                </Text>
                                            );
                                          }
                                        } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 3) {
                                          if (
                                              dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs["propre_chef"] === 1
                                          ) {
                                            return (
                                                <Text style={styles.bodyFooterMapped}>
                                                  Lien de parenté : Aux neveux et nièces De leur propre-chef : Oui
                                                </Text>
                                            );
                                          } else if (
                                              dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs["propre_chef"] === 0
                                          ) {
                                            return (
                                                <Text style={styles.bodyFooterMapped}>
                                                  Lien de parenté : Aux neveux et nièces De leur propre-chef : Non
                                                </Text>
                                            );
                                          }
                                        } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 4) {
                                          return (
                                              <Text style={styles.bodyFooterMapped}>
                                                Lien de parenté : Entre collatéraux jusqu'au 4ème degré inclusivement
                                              </Text>
                                          );
                                        } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 5) {
                                          return (
                                              <Text style={styles.bodyFooterMapped}>
                                                Lien de parenté : Entre parents au-delà du 4ème degré et non parents
                                              </Text>
                                          );
                                        }
                                      } else if (key === "handicap") {
                                        if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 1) {
                                          return (
                                              <Text style={styles.bodyFooterMapped}>
                                                Bénéficiaire en situation de handicap : Oui
                                              </Text>
                                          );
                                        } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 0) {
                                          return (
                                              <Text style={styles.bodyFooterMapped}>
                                                Bénéficiaire en situation de handicap : Non
                                              </Text>
                                          );
                                        }
                                      } else if (key === "date_nouvelle_donation") {
                                        const date_nouvelle_donation =
                                            dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key];
                                        const date = moment(date_nouvelle_donation).format("DD/MM/YYYY");
                                        return (
                                            <>
                                              <Text style={styles.bodyFooterMapped} key={key}>
                                                Date du décès : {date}
                                              </Text>
                                            </>
                                        );
                                      } else if (key === "total_succession") {
                                        const total_succession =
                                            dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs["total_succession"];
                                        const montant_rapportable =
                                            dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs["montant_rapportable"];
                                        const montantSuccession =
                                            dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs["montant_succession"];
                                        const montantRevenantHeritier =
                                            dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs["montantRevenantHeritier"];
                                        return (
                                            <>
                                              <Text style={styles.bodyFooterMapped}>
                                                Masse à partager avec l'ensemble des héritiers :
                                                {total_succession} €
                                              </Text>
                                              <Text style={styles.bodyFooterMapped}>
                                                Dont revenant à l'héritier :
                                                {montantRevenantHeritier} €
                                              </Text>
                                              <Text style={styles.bodyFooterMapped}>
                                                Sous déduction du montant de son rapport :
                                                {montant_rapportable} €
                                              </Text>
                                              <Text style={styles.bodyFooterMapped} key={key}>
                                                Montant taxable revenant à l'héritier : {montantSuccession} €
                                              </Text>
                                            </>
                                        );
                                      } else if (key === "montant_utilise") {
                                        return (
                                            <Text style={styles.bodyFooterMapped}>
                                              Abattement légal déjà utilisé :
                                              {dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key].toString() + " €"}
                                            </Text>
                                        );
                                      }
                                      return <></>;
                                    })}

                                    {dmtgInputs?.data[donateur]?.donataires[donataire]?.donations_anterieures &&
                                    dmtgInputs?.data[donateur]?.donataires[donataire]?.donations_anterieures.some(
                                        (obj) => Object.keys(obj).length > 0
                                    )
                                        ? dmtgInputs?.data[donateur]?.donataires[donataire]?.donations_anterieures.map(
                                            (donation, index) => {
                                              if (Object.keys(donation).length === 0) return null;
                                              const date = moment(donation.date).format("DD/MM/YYYY");
                                              const montant = donation.montant.toString() + " €";
                                              return (
                                                  <View key={index}>
                                                    <Text style={styles.bodyFooterMapped}>
                                                      {`Donation antérieure ${index + 1} - Date : ${date}`}
                                                    </Text>
                                                    <Text style={styles.bodyFooterMapped}>
                                                      {`Donation antérieure ${index + 1} - Montant : ${montant}`}
                                                    </Text>
                                                  </View>
                                              );
                                            }
                                        )
                                        : null}
                                  </View>
                                </>
                            );
                          })}
                        </>
                    );
                  } else {
                    return (
                        // DMTG donations
                        <>
                          <Text style={styles.rappelDonateurTitle} wrap={false}>
                            Donateur {donateur + 1}
                          </Text>
                          {dmtgInputs?.data[donateur]?.donataires?.map((inputs, donataire) => {
                            return (
                                <>
                                  <View style={styles.rappelBlockDonataire} wrap={false}>
                                    <Text style={styles.rappelDonataireTitle}>Donataire {donataire + 1}</Text>
                                    {Object.keys(dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs)?.map((key) => {
                                      if (key === "lien_parente_donation") {
                                        if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 1) {
                                          return (
                                              <Text style={styles.bodyFooterMapped}>
                                                Lien de parenté : Entre époux ou partenaires pacsés
                                              </Text>
                                          );
                                        } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 2) {
                                          return (
                                              <Text style={styles.bodyFooterMapped}>
                                                Lien de parenté : En ligne directe - Ascendants ou enfants
                                              </Text>
                                          );
                                        } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 3) {
                                          return (
                                              <Text style={styles.bodyFooterMapped}>
                                                Lien de parenté : Entre frères et sœurs
                                              </Text>
                                          );
                                        } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 4) {
                                          return (
                                              <Text style={styles.bodyFooterMapped}>
                                                Lien de parenté : Aux neveux et nièces
                                              </Text>
                                          );
                                        } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 5) {
                                          return (
                                              <Text style={styles.bodyFooterMapped}>Lien de parenté : Aux petits-enfants</Text>
                                          );
                                        } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 6) {
                                          return (
                                              <Text style={styles.bodyFooterMapped}>
                                                Lien de parenté : Aux arrières petits-enfants
                                              </Text>
                                          );
                                        } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 7) {
                                          return (
                                              <Text style={styles.bodyFooterMapped}>
                                                Lien de parenté : Entre parents au-dela du 4ème degré et non parents
                                              </Text>
                                          );
                                        }
                                      } else if (key === "lien_parente_succession") {
                                        if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 1) {
                                          return (
                                              <Text style={styles.bodyFooterMapped}>
                                                Lien de parenté : En ligne directe - Ascendants ou enfants
                                              </Text>
                                          );
                                        } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 2) {
                                          if (
                                              dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[
                                                  "conditions_particulieres"
                                                  ] === 1
                                          ) {
                                            return (
                                                <Text style={styles.bodyFooterMapped}>
                                                  Lien de parenté : Entre frères et sœurs Conditions particulières : Oui
                                                </Text>
                                            );
                                          } else if (
                                              dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[
                                                  "conditions_particulieres"
                                                  ] === 0
                                          ) {
                                            return (
                                                <Text style={styles.bodyFooterMapped}>
                                                  Lien de parenté : Entre frères et sœurs Conditions particulières : Non
                                                </Text>
                                            );
                                          }
                                        } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 3) {
                                          if (
                                              dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs["propre_chef"] === 1
                                          ) {
                                            return (
                                                <Text style={styles.bodyFooterMapped}>
                                                  Lien de parenté : Aux neveux et nièces De leur propre-chef : Oui
                                                </Text>
                                            );
                                          } else if (
                                              dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs["propre_chef"] === 0
                                          ) {
                                            return (
                                                <Text style={styles.bodyFooterMapped}>
                                                  Lien de parenté : Aux neveux et nièces De leur propre-chef : Non
                                                </Text>
                                            );
                                          }
                                        } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 4) {
                                          return (
                                              <Text style={styles.bodyFooterMapped}>
                                                Lien de parenté : Entre collatéraux jusqu'au 4ème degré inclusivement
                                              </Text>
                                          );
                                        } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 5) {
                                          return (
                                              <Text style={styles.bodyFooterMapped}>
                                                Lien de parenté : Entre parents au-delà du 4ème degré et non parents
                                              </Text>
                                          );
                                        }
                                      } else if (key === "tepa") {
                                        if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 1) {
                                          return (
                                              <>
                                                <Text style={styles.bodyFooterMapped}>
                                                  Abattement spécifique loi TEPA : Oui
                                                </Text>
                                                <Text style={styles.bodyFooterMapped}>
                                                  Abattement TEPA déjà utilisé :
                                                  {dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[
                                                      "montant_tepa_utilise"
                                                      ].toString() + " €"}
                                                </Text>
                                              </>
                                          );
                                        } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 0) {
                                          return (
                                              <Text style={styles.bodyFooterMapped}>Abattement spécifique loi TEPA : Non</Text>
                                          );
                                        }
                                      } else if (key === "montant_utilise") {
                                        return (
                                            <Text style={styles.bodyFooterMapped}>
                                              Abattement légal déjà utilisé :
                                              {dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key].toString() + " €"}
                                            </Text>
                                        );
                                      } else if (key === "handicap") {
                                        if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 1) {
                                          return (
                                              <Text style={styles.bodyFooterMapped}>
                                                Bénéficiaire en situation de handicap : Oui
                                              </Text>
                                          );
                                        } else if (dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key] === 0) {
                                          return (
                                              <Text style={styles.bodyFooterMapped}>
                                                Bénéficiaire en situation de handicap : Non
                                              </Text>
                                          );
                                        }
                                      } else if (key === "montant_especes") {
                                        return (
                                            <Text style={styles.bodyFooterMapped}>
                                              Montant espèces :
                                              {dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key].toString() + " €"}
                                            </Text>
                                        );
                                      } else if (key === "montant_immo") {
                                        return (
                                            <Text style={styles.bodyFooterMapped}>
                                              Montant immobilier :
                                              {dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key].toString() + " €"}
                                            </Text>
                                        );
                                      } else if (key === "date_nouvelle_donation") {
                                        const date_nouvelle_donation =
                                            dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs[key];
                                        const date = moment(date_nouvelle_donation).format("DD/MM/YYYY");
                                        const montantDonation =
                                            dmtgInputs?.data[donateur]?.donataires[donataire]?.inputs["montant_donation"];

                                        return (
                                            <>
                                              <Text style={styles.bodyFooterMapped} key={key}>
                                                Nouvelle donation - Date : {date}
                                              </Text>
                                              <Text style={styles.bodyFooterMapped} key={key}>
                                                Nouvelle donation - Montant : {montantDonation} €
                                              </Text>
                                            </>
                                        );
                                      }

                                      return <></>;
                                    })}

                                    {dmtgInputs?.data[donateur]?.donataires[donataire]?.donations_anterieures &&
                                    dmtgInputs?.data[donateur]?.donataires[donataire]?.donations_anterieures.some(
                                        (obj) => Object.keys(obj).length > 0
                                    )
                                        ? dmtgInputs?.data[donateur]?.donataires[donataire]?.donations_anterieures.map(
                                            (donation, index) => {
                                              if (Object.keys(donation).length === 0) return null;
                                              const date = moment(donation.date).format("DD/MM/YYYY");
                                              const montant = donation.montant.toString() + " €";
                                              return (
                                                  <View key={index}>
                                                    <Text style={styles.bodyFooterMapped}>
                                                      {`Donation antérieure ${index + 1} - Date : ${date}`}
                                                    </Text>
                                                    <Text style={styles.bodyFooterMapped}>
                                                      {`Donation antérieure ${index + 1} - Montant : ${montant}`}
                                                    </Text>
                                                  </View>
                                              );
                                            }
                                        )
                                        : null}
                                  </View>
                                </>
                            );
                          })}
                        </>
                    );
                  }
                })}
              </View>
            </View>

            <View style={styles.bodyFooterCtn} fixed>
              <Text style={styles.bodyFooterMedium}>
                IMPORTANT : Les informations et les résultats présentés ci-dessus sont donnés à titre indicatif et ne
                peuvent en aucun cas constituer un document à caractère contractuel. Les frais indiqués doivent être
                adressés par virement au minimum 48h-72h à l'avance.
              </Text>
              <Text style={styles.bodyFooterLight}>Sources : Éditions Langloÿs - Le Barème Rapide® {year}</Text>
            </View>
          </Page>
      ) : (
          <></>
      )}
      {/* END restitution des DMTG */}
    </Document>
  );
};
export default GeneratePdfFile;
