import React, {useEffect, useState} from "react";
import { setSimulateurObject } from "../../../actions/simulateur.action";
import { useDispatch } from 'react-redux';
import { styles } from "../../../stylesheets/simulateurs.css";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import SelectDepartement from "../../../components/selects/select.departement";
import { ToolTipPerso } from "../../../components/selects/tooltip_perso";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";

// Partage de communauté (pour le régime de la communauté ou de la participation aux acquêts) - Droit de partage à 1,10 %
// ID 58

function Simulateur_A1_20(props, simulateur, isParent, departements) {
    const dispatch = useDispatch();

    let currUser = JSON.parse(localStorage.getItem("efluser"));
    let currUserDep = 0
    if (currUser.societe_zip.startsWith("97")) {
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 3);
        currUserDep = parseInt(trimedZip)
    } else {
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 2);
        currUserDep = parseInt(trimedZip)
    }

    const [departement, setDepartement] = useState(0)
    const [masse_brute, setMasseBrute] = useState(0)
    const [actif_net, setActifNet] = useState(0)
    const [valeur_cumulee, setValeurCumulee] = useState(0)

    const handleChange = async (setter, value) => {
        if (setter === setDepartement) {
            setDepartement(parseInt(value))
        } else setter(value);
        setInRedux(setter, value)
    };

    const handleChangeRemarque = async (value) => {
        localStorage.setItem("remarque", value)
    }

    function setInRedux(setter, value) {
        if (props.isParent) {

            let object = {
                "simulateurid": props.simulateur.id,
                "departementid": 0,
                "bureauid": parseInt(currUser.id),
                "inputs": {}
            };

            if (setter === setMasseBrute) {
                Object.assign(object['inputs'], { 'masse_brute': parseInt(value) })
                Object.assign(object['inputs'], { 'actif_net': parseInt(actif_net) })
                Object.assign(object['inputs'], { 'valeur_cumulee': parseInt(valeur_cumulee) })
                object.departementid = parseInt(departement)
            }

            if (setter === setActifNet) {
                Object.assign(object['inputs'], { 'masse_brute': parseInt(masse_brute) })
                Object.assign(object['inputs'], { 'actif_net': parseInt(value) })
                Object.assign(object['inputs'], { 'valeur_cumulee': parseInt(valeur_cumulee) })
                object.departementid = parseInt(departement)
            }

            if (setter === setValeurCumulee) {
                Object.assign(object['inputs'], { 'masse_brute': parseInt(masse_brute) })
                Object.assign(object['inputs'], { 'actif_net': parseInt(actif_net) })
                Object.assign(object['inputs'], { 'valeur_cumulee': parseInt(value) })
                object.departementid = parseInt(departement)
            }

            if (setter === setDepartement) {
                Object.assign(object['inputs'], { 'masse_brute': parseInt(masse_brute) })
                Object.assign(object['inputs'], { 'actif_net': parseInt(actif_net) })
                Object.assign(object['inputs'], { 'valeur_cumulee': parseInt(valeur_cumulee) })
                object.departementid = parseInt(value)
            }

            dispatch(setSimulateurObject(object));

        } else {

            // process child simu

        }
    }

    return (
        <div style={styles.container}>
            <div style={styles.card}>
                <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id} />

                <TextfieldRemarques handleChange={handleChangeRemarque}/>

                {props?.isParent === true && props.departements !== "" ?
                    <div style={styles.cardRow}>
                        <div style={styles.cardElemVerticalyCenter}>
                            Département :
                        </div>

                        <SelectDepartement items={props.departements} nameField={"name"} defaultValue={currUserDep} optionValue={"id"}
                            handleChange={handleChange} setter={setDepartement} />
                    </div>
                    :
                    <></>
                }

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Quelle est la masse brute partagée (immeubles et meubles) ou Actif Brut ?
                        <ToolTipPerso title={"Les récompenses font partie des éléments d'actifs et ne doivent pas être retirées."} />
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Actif brut"}
                        setter={setMasseBrute}
                    />
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Quel est l’Actif Net partagé ?
                        <ToolTipPerso title={"L’art.108 de la loi de finance n°2019-1479 instaure une baisse du taux pour les partages des intérêts patrimoniaux consécutifs à une séparation de corps, à un divorce, ou à une rupture d'un pacte civil de solidarité (PACS) à 1,10 % à compter du 1er janvier 2022. Ici, l'assiette du droit de partage est composée de l'actif net à partager + les récompenses figurant au passif + les meubles partagés dès avant les présentes (donc ne figurant pas comme élément d'actif à partager dans l'acte)."} />
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Actif net"}
                        setter={setActifNet}
                    />
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Quelle est la valeur cumulée des biens immobiliers partagés ?
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Valeur"}
                        setter={setValeurCumulee}
                    />
                </div>
            </div>
        </div>
    )
}

export default Simulateur_A1_20;
