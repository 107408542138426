import React, {useEffect, useState} from 'react';
import Colors from "../../useclass/colors";
import {API_BASE_URL, PARTNER_JWT} from "../../config";
import axios from "axios";
import useIsMountedRef from "../../utils/mountedRef";

const Categoriesblock = (props) => {
    const dmtg = [55, 56, 109, 110];
    const others = [42, 103, 104, 105, 106];
    const deactivated = [107, 108];
    const styles = {
        container: {
            width: "100%",
            marginBottom: 50,
        },
        containerTitle: {
            color: Colors.primaryBlue,
            width: "90%",
            fontSize: 22,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 10,
            marginBottom: 10
        },
        containerBody: {
            width: "88%",
            marginLeft: "auto",
            marginRight: "auto",
        },
        containerItem: {
            display: "grid",
            gridTemplateColumns: "5px 12px 1fr",
            marginTop: 15,
            marginBottom: 15,
            lineHeight: 1.5,
        },
        bar: {
            backgroundColor: Colors.primaryBlue,
            width: 2,
            height: 30,
            marginTop: "auto",
            marginBottom: "auto"
        },

        bodyText: {
            marginTop: "auto",
            marginBottom: "auto",
            cursor: "pointer"
        }
    }

    const isMountedRef = useIsMountedRef();
    const [allFavoris, setAllFavoris] = useState(localStorage.getItem("favoris"))

    useEffect(() => {
        if (allFavoris !== null && allFavoris.length > 0) {
            try {
                setAllFavoris(JSON.parse(allFavoris));
            } catch (e) {

            }

        }

    }, [isMountedRef]);

    function onClickFavoris(id) {
        var config = {
            method: 'get',
            url: `${API_BASE_URL}/favoris/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${PARTNER_JWT}`,
            },
        };
        axios(config)
            .then((response) => {
                if (response.status === 200 && response.data != null) {
                    localStorage.setItem("favoris", response.data)
                    if (response.data !== null)
                        setAllFavoris(JSON.parse(response.data))
                    else
                        setAllFavoris(response.data)
                }
            });
    }
    // permet de supprimer l'affichage des simulateurs que l'on souhaite
    for ( var i = 0 ; i < props.body.length ; i++) {
        if (props.body[i].id == 111 || props.body[i].id == 112) {
            props.body.splice(i, 1);
        }
    }

    return (
        <div style={styles.container}>
            <div style={styles.containerTitle}>
                {props.title}
            </div>

            <div style={styles.containerBody}>
                {props.body.map((item) => (

                    <div style={styles.containerItem}>
                        <div>
                        </div>
                        <div style={styles.bar}>

                        </div>
                        <div style={styles.bodyText}
                             onClick={(e) => {
                                 if (dmtg.includes(item.id)) {
                                     window.location = window.location + "/dmtg/" + item.id
                                 } else if (others.includes(item.id)) {
                                     window.location = window.location + "/others/" + item.id
                                 } else if (!deactivated.includes(item.id)){
                                     window.location = window.location + "/simulateur/" + item.id
                                 }
                             }}>
                            {item.name}
                        </div>
                    </div>

                ))}
            </div>
        </div>
    );
};

export default Categoriesblock;
