import React, {useEffect, useState} from 'react';
import Colors from "../../useclass/colors";

function Faq(props) {
    const styles = {
        container: {
            padding: 0,
            margin: 0,
            width: "100vw",
            minHeight: "auto"
        },
        card: {
            backgroundColor: Colors.white,
            boxShadow: "2px 2px 1px rgba(0,0,0, 0.2)",
            borderRadius: 5,
            width: "90%",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 50,
            paddingBottom: 50,
            minHeight: "30vh",
        },
        cardTitle: {
            fontWeight: "normal",
            color: "#7CA8DF",
            textAlign: "center",
            lineHeight: "1.4em",
            paddingTop: 20,
            marginTop: 5,
            marginBottom: 60,
        },
    };


    return (
        <div style={styles.container}>
            <div style={styles.card}>
                <h1 style={styles.cardTitle}>
                    Foire Aux Questions
                </h1>
            </div>
        </div>
    );
};

export default Faq;