import React, { useEffect, useState } from "react";
import Colors from "../../useclass/colors";
import Pdf5 from "../../medias/fiches/Ce_qu_il_ne_faut_pas_oublier_lors_d_une_succession_(check-list).pdf";
import Pdf7 from "../../medias/fiches/Rappel_des_droits_applicables_aux_successions_et_aux_donations.pdf";
import Pdf8 from "../../medias/fiches/Synthese_des_exonerations_et_abattements_sur_les_successions.pdf";
import Pdf9 from "../../medias/fiches/Synthese_des_exonerations_et_abattements_sur_les_donations.pdf";
import Pdf10 from "../../medias/fiches/Fiscalite_de_l_assurance_vie_droits_de_succession.pdf";
import Pdf13 from "../../medias/fiches/Rappel_sur_les_regles_de_repartition_d_usufruit_et_nue-propriete.pdf";
import ReactGA from "react-ga4";

function Fiches_Famille(props) {
  const styles = {
    container: {
      padding: 0,
      margin: 0,
      width: "100vw",
      minHeight: "auto",
    },
    card: {
      backgroundColor: Colors.white,
      boxShadow: "2px 2px 1px rgba(0,0,0, 0.2)",
      borderRadius: 5,
      width: "90%",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: 150,
      paddingBottom: 50,
      paddingLeft: 50,
      minHeight: "30vh",
    },
    cardTitle: {
      fontWeight: "normal",
      color: "#5E91B6",
      textAlign: "center",
      lineHeight: "1.4em",
      paddingTop: 20,
      marginTop: 5,
      marginBottom: 30,
    },
    cardSubTitle: {
      fontSize: 18,
      marginLeft: 35,
    },
    footer: {
      position: "fixed",
      left: 0,
      bottom: 0,
    },
    listElem: {
      lineHeight: 2.3,
      cursor: "pointer",
    },
    picto: {
      marginRight: 20,
    },
  };

  const pictoFiche = (
    <svg
      id="Calque_1"
      data-name="Calque 1"
      xmlns="http://www.w3.org/2000/svg"
      width="11.69"
      height="16.14"
      viewBox="0 0 11.69 16.14"
      style={styles.picto}
    >
      <path
        d="m11.13,14.51c0,.59-.48,1.07-1.07,1.07H1.63c-.59,0-1.07-.48-1.07-1.07V1.63c0-.59.48-1.07,1.07-1.07h6.65c.15,0,.3.06.4.17l2.29,2.3c.11.11.17.25.17.4v11.09Zm-.79-11.41h-1.75v-1.84c0-.15-.13-.28-.28-.28s-.28.12-.28.28v2.12c0,.15.12.28.28.28h2.03c.15,0,.28-.12.28-.28s-.12-.28-.28-.28h0Zm1.02-.48L9.07.33c-.21-.21-.5-.33-.8-.33H1.63C.73,0,0,.73,0,1.63v12.89c0,.89.73,1.62,1.63,1.62h8.44c.9,0,1.62-.73,1.62-1.62V3.42c0-.3-.12-.58-.33-.79Z"
        fill="#5a8aac"
      />
    </svg>
  );

  const handleClick = (pdfName) => {
    ReactGA.event({
      category: "Fiches Famille",
      action: "Clic sur " + pdfName,
      label: pdfName,
    });

    window.open(pdfName, "_blank");
  };

  return (
    <>
      <div style={styles.container}>
        <div style={styles.card}>
          <h1 style={styles.cardTitle}>Fiches Pratiques Famille</h1>
          <ul>
            <div
              style={styles.listElem}
              onClick={() => handleClick(Pdf5)}
            >
              {pictoFiche}
              Check-list lors d'une succession
            </div>
            <div
              style={styles.listElem}
              onClick={() => handleClick(Pdf7)}
            >
              {pictoFiche}
              Droits applicables aux donations et successions - Rappel
            </div>
            <div
              style={styles.listElem}
              onClick={() => handleClick(Pdf8)}
            >
              {pictoFiche}
              Exonérations et abattements sur les successions
            </div>
            <div
              style={styles.listElem}
              onClick={() => handleClick(Pdf9)}
            >
              {pictoFiche}
              Exonérations et abattements sur les donations
            </div>
            <div
              style={styles.listElem}
              onClick={() => handleClick(Pdf10)}
            >
              {pictoFiche}
              La fiscalité de l'assurance vie
            </div>
            <div
              style={styles.listElem}
              onClick={() => handleClick(Pdf13)}
            >
              {pictoFiche}
              Règles de répartition usufruit nue propriété - Rappel
            </div>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Fiches_Famille;
