import React, {useEffect, useState} from "react";
import {setSimulateurObject} from "../../../actions/simulateur.action";
import {useDispatch} from 'react-redux';
import {styles} from "../../../stylesheets/simulateurs.css";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import ButtonText from "../../../components/buttons/button.text";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import SelectDepartement from "../../../components/selects/select.departement";
import {ToolTipPerso} from "../../../components/selects/tooltip_perso";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";

// Prêts aux professionnels garantis par une hypothèque légale de prêteur de deniers et une hypothèque conventionnelle dans l'acte de vente
// id simu 92

function Simulateur_A1_12PRO(props, simulateur, isParent, departements) {
    const dispatch = useDispatch();

    let currUser = JSON.parse(localStorage.getItem("efluser"));
    let currUserDep = 0
    if (currUser.societe_zip.startsWith("97")){
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 3);
        currUserDep = parseInt(trimedZip)
    } else {
        let trimedZip;
        trimedZip = currUser.societe_zip.slice(0, 2);
        currUserDep = parseInt(trimedZip)
    }

    const currentIdSimu = 92
    const [departement, setDepartement] = useState(0)
    const [montant_pret, setMontant] = useState(0)
    const [montant_pret_hp, setMontant_pret_hp] = useState(0)
    const [montant_pret_hc, setMontant_pret_hc] = useState(0)
    const [pret_realise, setPret] = useState(0)

    const handleChange = async (setter, value) => {
        setter(value);
        setInRedux(setter, value)
    };

    const handleChangeRemarque = async (value) => {
        localStorage.setItem("remarque", value)
    }

    function setInRedux(setter, value) {
        let object = {
            "simulateurid": currentIdSimu,
            "departementid": 0,
            "bureauid": parseInt(currUser.id),
            "inputs": {}
        };

            if (setter === setMontant_pret_hp) {
                Object.assign(object['inputs'], {'montant_pret_hp': parseInt(value)})
                Object.assign(object['inputs'], {'montant_pret_hc': parseInt(montant_pret_hc)})
                Object.assign(object['inputs'], {'montant_pret': parseInt(montant_pret)})
                Object.assign(object['inputs'], {'pret_realise': parseInt(pret_realise)})
                object.departementid = parseInt(departement)
            }

            if (setter === setMontant_pret_hc) {
                Object.assign(object['inputs'], {'montant_pret_hp': parseInt(montant_pret_hp)})
                Object.assign(object['inputs'], {'montant_pret_hc': parseInt(value)})
                Object.assign(object['inputs'], {'montant_pret': parseInt(montant_pret)})
                Object.assign(object['inputs'], {'pret_realise': parseInt(pret_realise)})
                object.departementid = parseInt(departement)
            }

            if (setter === setMontant) {
                Object.assign(object['inputs'], {'montant_pret_hp': parseInt(montant_pret_hp)})
                Object.assign(object['inputs'], {'montant_pret_hc': parseInt(montant_pret_hc)})
                Object.assign(object['inputs'], {'montant_pret': parseInt(value)})
                Object.assign(object['inputs'], {'pret_realise': parseInt(pret_realise)})
                object.departementid = parseInt(departement)
            }

            if (setter === setPret) {
                Object.assign(object['inputs'], {'montant_pret_hp': parseInt(montant_pret_hp)})
                Object.assign(object['inputs'], {'montant_pret_hc': parseInt(montant_pret_hc)})
                Object.assign(object['inputs'], {'montant_pret': parseInt(montant_pret)})
                Object.assign(object['inputs'], {'pret_realise': parseInt(value)})
                object.departementid = parseInt(departement)
            }

            if (setter === setDepartement) {
                Object.assign(object['inputs'], {'montant_pret_hp': parseInt(montant_pret_hp)})
                Object.assign(object['inputs'], {'montant_pret_hc': parseInt(montant_pret_hc)})
                Object.assign(object['inputs'], {'montant_pret': parseInt(montant_pret)})
                Object.assign(object['inputs'], {'pret_realise': parseInt(pret_realise)})
                object.departementid = parseInt(value)
            }

        //props.contentDep(object);
        if (props.isParent) {
            dispatch(setSimulateurObject(object));
        } else {
            object.departementid = parseInt(props.departementId)
            props.dependances(object)
        }
    }

    return (
        <>
            {props?.isParent === true && props.departements !== "" ?
                <div style={styles.container}>
                    <div style={styles.card}>

                        <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id}/>

                        <TextfieldRemarques handleChange={handleChangeRemarque}/>

                        {props?.isParent === true && props.departements !== "" ?
                            <div style={styles.cardRow}>
                                <div style={styles.cardElemVerticalyCenter}>
                                    Département :
                                </div>

                                <SelectDepartement items={props.departements} nameField={"name"} defaultValue={currUserDep} optionValue={"id"}
                                      handleChange={handleChange} setter={setDepartement}/>
                            </div>
                         :
                            <></>
                        }

                        <div style={styles.cardRow}>
                            <div style={styles.cardElemVerticalyCenter}>
                                L’acte de prêt est réalisé :
                            </div>
                            <ButtonText handleChange={handleChange} setter={setPret} defaultValue={0}/>
                        </div>

                        <div style={styles.cardRow}>
                            <div style={styles.cardElemVerticalyCenter}>
                                Indiquer le montant total du prêt ou capitaux empruntés :
                                <ToolTipPerso title={"Les accessoires sont compris et évalués au bordereau à concurrence de 20% du capital emprunté."}/>
                            </div>
                            <TextfieldNumber
                                handleChange={handleChange}
                                type={"number"}
                                placeholder={"Indiquer le montant"}
                                setter={setMontant}
                            />
                        </div>

                        <div style={styles.cardRow}>
                            <div style={styles.cardElemVerticalyCenter}>
                                Indiquer le montant du prêt garanti par une hypothèque légale de prêteur de deniers :
                            </div>
                            <TextfieldNumber
                                handleChange={handleChange}
                                type={"number"}
                                placeholder={"Indiquer le montant"}
                                setter={setMontant_pret_hp}
                            />
                        </div>

                        <div style={styles.cardRow}>
                            <div style={styles.cardElemVerticalyCenter}>
                                Indiquer le montant du prêt hypothécaire :
                            </div>
                            <TextfieldNumber
                                handleChange={handleChange}
                                type={"number"}
                                placeholder={"Indiquer le montant"}
                                setter={setMontant_pret_hc}
                            />
                        </div>


                    </div>
                </div>
                :
                <div style={styles.cardRowChild}>
                    <div style={styles.cardRow}>
                        <div style={styles.cardElemVerticalyCenter}>
                            L’acte de prêt est réalisé :
                        </div>
                        <ButtonText handleChange={handleChange} setter={setPret} defaultValue={0}/>
                    </div>

                    <div style={styles.cardRow}>
                        <div style={styles.cardElemVerticalyCenter}>
                            Indiquer le montant total du prêt ou capitaux empruntés :
                            <ToolTipPerso title={"Les accessoires sont compris et évalués au bordereau à concurrence de 20% du capital emprunté."}/>
                        </div>
                        <TextfieldNumber
                            handleChange={handleChange}
                            type={"number"}
                            placeholder={"Indiquer le montant"}
                            setter={setMontant}
                        />
                    </div>

                    <div style={styles.cardRow}>
                        <div style={styles.cardElemVerticalyCenter}>
                            Indiquer le montant du prêt garanti par une hypothèque légale de prêteur de deniers :
                        </div>
                        <TextfieldNumber
                            handleChange={handleChange}
                            type={"number"}
                            placeholder={"Indiquer le montant"}
                            setter={setMontant_pret_hp}
                        />
                    </div>

                    <div style={styles.cardRow}>
                        <div style={styles.cardElemVerticalyCenter}>
                            Indiquer le montant du prêt hypothécaire :
                        </div>
                        <TextfieldNumber
                            handleChange={handleChange}
                            type={"number"}
                            placeholder={"Indiquer le montant"}
                            setter={setMontant_pret_hc}
                        />
                    </div>
                </div>

            }

        </>
    )
}

export default Simulateur_A1_12PRO;
