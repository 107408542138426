import {useCallback, useState} from "react";

export const server = {
    Prod: "https://api.notaire-langloys.com",
    Test: "https://cors-anywhere.herokuapp.com/http://localhost:8080",
    Dev: "https://langloyscalc.azurewebsites.net"
};
const currentServer = server.Prod;
export const API_BASE_URL = currentServer;
export const PARTNER_JWT = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdXRob3JpemVkIjp0cnVlLCJ1c2VyX2lkIjoxNzg2OX0.p7EJugBY9jjqN49bu0xd4_I3uCbtu58LCO6UgxFSz-Q"
export const ENABLE_REDUX_LOGGER = false;

export function useForceUpdate() {
    const [, setTick] = useState(0);
    const update = useCallback(() => {
        setTick((tick) => tick + 1);
    }, []);
    return update;
}

export default {};

